import { Popin, PopinCloseButton } from '@components/navigation/Popin/Popin';
import { GanttStripDto, ScheduleSlotUpdateDto } from '@models';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModifySlotFormActions } from './ModifySlotFormActions';
import { ModifySlotForm } from './ModifySlotForm';
import { ModifySlotFormContainer } from './ModifySlotFormContainer';

export const ModifySlotPopin = memo(function ModifySlotPopin({
    slot,
    onSubmit,
    onClose,
}: {
    slot?: GanttStripDto;
    onSubmit?: (values: ScheduleSlotUpdateDto, isConfirm?: boolean) => void;
    onClose?: () => void;
}) {
    return (
        <ModifySlotFormContainer onSubmit={onSubmit} slot={slot}>
            <Popin
                width={1280}
                onClose={onClose}
                title={
                    <>
                        <AddIcon />
                        <Box display="flex" flex={1}>
                            <FormattedMessage id={slot ? 'requestForm.edit.title' : 'requestForm.new.title'} />
                        </Box>
                        <PopinCloseButton onClose={onClose} />
                    </>
                }
                content={<ModifySlotForm />}
                actions={
                    <ModifySlotFormActions
                        onSubmit={onSubmit}
                        confirmText={
                            <FormattedMessage id={slot ? 'requestForm.confirmChanges' : 'requestForm.create'} />
                        }
                        onCancel={slot ? onClose : undefined}
                    />
                }
            />
        </ModifySlotFormContainer>
    );
});
