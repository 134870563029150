import * as React from 'react';
import { ComponentProps, memo } from 'react';
import { FastField as Field, FieldConfig, FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@components/form/TextField/TextField';

export const FormikTextField = memo(function FormikTextField({
    label,
    name,
    onChange,
    ...rest
}: { label?: string | React.ReactNode; name: string } & ComponentProps<typeof TextField> & FieldConfig) {
    return (
        <Field name={name} validate={rest.validate}>
            {({ field: { value, ...field }, meta: { error } }: FieldProps<string>) => {
                return (
                    <TextField
                        label={label}
                        value={value || ''}
                        {...rest}
                        {...field}
                        onChange={e => {
                            field.onChange(e);
                            onChange?.(e);
                        }}
                        error={!!error}
                        helperText={typeof error === 'string' && <FormattedMessage id={error} defaultMessage={error} />}
                    />
                );
            }}
        </Field>
    );
});
