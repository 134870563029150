import { styled, Typography, TypographyProps } from '@mui/material';
import React, { memo } from 'react';
import { ImpactStatus } from '@types';
import { FormattedMessage } from 'react-intl';
import { STRIP_TITLE_HEIGHT } from '@components/Strip/strip.constant';

export const StyledStripNameTypography = styled(Typography)`
    display: flex;
    align-items: center;
    height: ${STRIP_TITLE_HEIGHT}px;
    color: #b6d2e5;
    font-size: 12px;
    text-transform: uppercase;
    flex-shrink: 0;
`;

export const StripName = memo(function StripName({
    impactStatus,
    ...rest
}: { impactStatus?: ImpactStatus | null } & TypographyProps) {
    return (
        <StyledStripNameTypography {...rest}>
            {impactStatus && impactStatus !== ImpactStatus.CONFIRMED && (
                <FormattedMessage id={`ImpactStatus.${impactStatus}`} />
            )}
        </StyledStripNameTypography>
    );
});
