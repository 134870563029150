import { Drawer, paperClasses, styled } from '@mui/material';
import { useBoolean } from 'hooks/useBoolean';
import * as React from 'react';
import { memo } from 'react';
import { ReactComponent as BurgerIcon } from '../../../assets/icons/icon-menu-burger.svg';
import { SquareIconButton } from '../../Button/SquareIconButton/SquareIconButton';
import { Menu } from './Menu';

const LeftDrawer = styled(Drawer)`
    & .${paperClasses.root} {
        width: 344px;
        color: white;
        background: #126dbb;
    }
`;

export const MenuButton = memo(function MenuButton() {
    const { value: opened, setTrue: open, setFalse: close } = useBoolean(false);

    return (
        <>
            <SquareIconButton color="primary" size="medium" onClick={open} sx={{ width: '50px', marginRight: '43px' }}>
                <BurgerIcon color="#FFFFFF" width="30" height="30" />
            </SquareIconButton>

            <LeftDrawer anchor="left" open={opened} onClose={close}>
                <Menu closeMenu={close} />
            </LeftDrawer>
        </>
    );
});
