/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import {
    DaySlotResourcesAllocationResponse,
    KeyGetGetDayGanttTurnarounds,
    KeyGetGetSlotStrip,
    useMutationPostUpdateTurnaroundsResources,
} from '@models';
import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScheduleControlsBox } from '@components/Schedule/ScheduleControls';
import { toast } from 'react-toastify';
import { errorToastConfig, successToastConfig } from '../../config/constants';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { ScheduleModes, useSetSchedule } from '../../views/Schedule/SchedulePage/ScheduleContext';
import { useQueryClient } from 'react-query';

export const ScheduleAllocationControls = React.memo(function ScheduleAllocationControls({
    allocationUpdateData,
}: {
    allocationUpdateData: DaySlotResourcesAllocationResponse;
}) {
    const setScheduleMode = useSetSchedule('mode');
    const onCancel = useCallback(() => {
        setScheduleMode(ScheduleModes.NOMINAL);
    }, [setScheduleMode]);

    const queryClient = useQueryClient();
    const { mutateAsync: validate, isLoading } = useMutationPostUpdateTurnaroundsResources({
        onSuccess: () => {
            toast(<FormattedMessage id={'schedule.allocate.successToast'} />, successToastConfig);
            setScheduleMode(ScheduleModes.NOMINAL);
            queryClient.invalidateQueries(KeyGetGetDayGanttTurnarounds);
            queryClient.invalidateQueries(KeyGetGetSlotStrip);
        },
        onError: () => {
            toast(<FormattedMessage id={'schedule.allocate.errorToast'} />, errorToastConfig);
        },
    });
    const onConfirm = useCallback(() => {
        validate({
            turnaroundResourcesUpdateDto: Object.keys(allocationUpdateData.allocationProposal).map(id => ({
                id,
                propagate: false,
                ...allocationUpdateData.allocationProposal[id],
            })),
        });
    }, [allocationUpdateData?.allocationProposal, validate]);

    return (
        <ScheduleControlsBox>
            <Box display="flex" justifyContent="center" alignItems="space-between">
                <RoundButton color="primary" size="medium" variant="outlined" onClick={onCancel}>
                    <FormattedMessage id="schedule.allocate.cancelControlButton" />
                </RoundButton>
                &emsp;
                <RoundButton
                    color="primary"
                    size="medium"
                    variant="contained"
                    onClick={onConfirm}
                    loading={isLoading}
                    disabled={
                        isLoading ||
                        allocationUpdateData?.flightTurnarounds?.length === 0 ||
                        Object.keys(allocationUpdateData?.allocationProposal).length === 0
                    }
                >
                    <FormattedMessage
                        id="schedule.allocate.confirmControlButton"
                        values={{
                            count:
                                (allocationUpdateData.report.newAllocation || 0) +
                                (allocationUpdateData.report.updatedAllocation || 0),
                        }}
                    />
                </RoundButton>
            </Box>
        </ScheduleControlsBox>
    );
});
