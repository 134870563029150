import { RequestsHeader } from './RequestsHeader';
import { Requests } from '@components/Requests/Requests';
import { DemandStatus, useQueryGetGetAllSlotRequests, useQueryGetGetSlotFilters } from '@models';
import { Box, CircularProgress, Pagination, PaginationItem, styled, Zoom } from '@mui/material';
import { SeasonFilter, useUiState, useUiValue } from 'contexts/UiContext';
import React, { memo, useEffect, useState } from 'react';

// Poll every 30 seconds
export const ganttPollingInterval = 30 * 1000;

type ValuesForQuery = {
    requestFilter: DemandStatus | undefined;
    seasonFilter: SeasonFilter;
    currentPage: number | undefined;
    search: string | undefined;
    highlightedSlotId: string | null;
};

export const RequestPage = memo(function RequestPage() {
    const [highlightedSlotId, setHighlightedSlotId] = useUiState('highlightedSlotId');
    const [currentPage, setCurrentPage] = useState<number | undefined>(highlightedSlotId ? undefined : 1);
    const requestFilterInUiContext = useUiValue('requestFilter');
    const seasonFilterInUiContext = useUiValue('seasonFilter');
    const searchValueInUiContext = useUiValue('search');

    const { data: filters } = useQueryGetGetSlotFilters();

    const currentSeasonLabel = filters?.currentSeason?.label;
    const nextSeasonLabel = filters?.nextSeason?.label;

    /**
     * These valuesForQuery are used as query parameters for the GetAllSlotsRequests query.
     * They are debounced to avoid making unnecessary requests.
     * They exist in a common object to avoid simultaneous asynchronous state updates causing
     * unnecessary concurrent requests when they are updated.
     * */
    const [valuesForQuery, setValuesForQuery] = useState<ValuesForQuery>({
        requestFilter: requestFilterInUiContext,
        seasonFilter: seasonFilterInUiContext,
        currentPage: currentPage,
        search: searchValueInUiContext,
        highlightedSlotId: highlightedSlotId,
    });

    const {
        data: requestsResponse,
        isFetching,
        isLoading,
    } = useQueryGetGetAllSlotRequests(
        {
            page: valuesForQuery.currentPage,
            status: valuesForQuery.requestFilter,
            season:
                valuesForQuery.seasonFilter === 'ALL'
                    ? undefined
                    : valuesForQuery.seasonFilter === 'CURRENT'
                    ? currentSeasonLabel
                    : nextSeasonLabel,
            pattern: valuesForQuery.search || undefined,
            slotId: valuesForQuery.highlightedSlotId || undefined,
        },
        {
            keepPreviousData: true,
            cacheTime: 0,
            refetchInterval: ganttPollingInterval,
            onSuccess: data => {
                setCurrentPage(data.data.page);
            },
        },
    );

    /**
     * Update the page considered "Active" by the Pagination component.
     * This will also cause a debounced update of the currentPage value in valuesForQuery used for the query.
     * Additionally, if a slot is highlighted, it will be un-highlighted in UiContext to allow for
     * clean subsequent requests. See related useEffect.
     * */
    const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
        setHighlightedSlotId(null);
    };

    // TODO:
    // Improve this monstrosity of a comment. The idea is to avoid requesting a new page when the component mounts
    // with a highlighted slot and thus triggers a 'currentPage' change upon initial query onSuccess.
    /**
     * NOTE: The highlightedSlotId is only relevant on initial page load to highlight the slot & open pagination
     * to the correct page. After that, the highlightedSlotId is no longer relevant and should be reset to null
     * following any manual user action (not polling).
     *
     * Debounce the changes to the `valuesForQuery` object to avoid making unnecessary requests.
     * Notably, this will allow the user to type in the search bar without making a request for each keystroke as
     * well as avoid making requests for every page as the user clicks through the pagination.
     *
     * Additionally, the currentPage and highlightedSlotId values are updated in accordance with the following logic:
     *  1. If the requestFilter or search values in UiContext change,
     *     the currentPage will be reset to 1 and the highlightedSlotId (no longer relevant) will be reset to null.
     *     This is to ensure that the user is not left on a page that no longer exists.
     *  2. Else if the values have not changed:
     *     2.a. If a slot is highlighted, currentPage will be set to the previous currentPage value.
     *          Else, currentPage will be set to the new (current) currentPage value.
     *     2.b. If a slot is highlighted and the previous currentPage was undefined,
     *          the highlightedSlotId will be set to its previous value.
     *          Else, the highlightedSlotId will be set to null.
     *     NOTE: If a slot is highlighted at this stage, it means we are on the initial page load and the user
     *     has not yet interacted with the pagination.
     *
     * The above logic is to ensure that the currentPage and highlightedSlotId values are properly
     * updated while avoiding unwanted changes (and requests) following the initial render with a highlighted slot.
     */
    useEffect(() => {
        const timeout = setTimeout(() => {
            setValuesForQuery(prevState => ({
                search: searchValueInUiContext,
                requestFilter: requestFilterInUiContext,
                seasonFilter: seasonFilterInUiContext,
                currentPage:
                    prevState.seasonFilter !== seasonFilterInUiContext ||
                    prevState.requestFilter !== requestFilterInUiContext ||
                    prevState.search !== searchValueInUiContext
                        ? 1
                        : highlightedSlotId
                        ? prevState.currentPage
                        : currentPage,
                highlightedSlotId:
                    prevState.seasonFilter !== seasonFilterInUiContext ||
                    prevState.requestFilter !== requestFilterInUiContext ||
                    prevState.search !== searchValueInUiContext
                        ? null
                        : highlightedSlotId
                        ? prevState.currentPage === undefined
                            ? prevState.highlightedSlotId
                            : null
                        : null,
            }));
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [currentPage, highlightedSlotId, requestFilterInUiContext, searchValueInUiContext, seasonFilterInUiContext]);

    /**
     * Reset highlighted slot id when component unmounts.
     * */
    useEffect(() => {
        return () => {
            setHighlightedSlotId(null);
        };
    }, [setHighlightedSlotId]);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%" height="100%" overflow="hidden">
            <RequestsHeader />
            {isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    <CircularProgress size={25} color="info" />
                </Box>
            ) : (
                <>
                    <StyledPaginationContainer>
                        <Pagination
                            count={requestsResponse?.nbOfPages}
                            page={currentPage}
                            onChange={handleChangePage}
                            renderItem={item => (
                                <Zoom in={true} timeout={500}>
                                    <PaginationItem {...item} />
                                </Zoom>
                            )}
                        />
                        {isFetching && <CircularProgress size={25} color="info" />}
                    </StyledPaginationContainer>
                    <Requests
                        requests={requestsResponse?.requests}
                        scrollToSlotId={highlightedSlotId}
                        page={currentPage || 1}
                    />
                </>
            )}
        </Box>
    );
});

const StyledPaginationContainer = styled(Box)`
    padding: 10px 0;
    position: relative;

    .MuiPagination-root {
        .MuiPaginationItem-root {
            color: white;
        }
    }
    .MuiCircularProgress-root {
        position: absolute;
        left: calc(100% + 15px);
        top: 13px;
    }
`;
