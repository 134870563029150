/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */
import { Box, styled } from '@mui/material';
import { memo } from 'react';
import { ganttPxPerMs } from '@components/Schedule/Gantt/gantt.constants';
import { ONE_HOUR } from '../../../config/constants';

export const GanttGridBox = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 1,
    display: 'flex',
    width: '100%',
    flex: 1,
    pointerEvents: 'none',
});
export const GanttGridStepBox = styled(Box)({
    display: 'flex',
    width: ONE_HOUR * ganttPxPerMs,
    height: '100%',
    flexShrink: 0,
    borderLeft: '1px solid #B6D2E580',
    '&:last-of-type': {
        borderRight: '1px solid #B6D2E580',
    },
});
export const GanttGridSubStepBox = styled(Box)({
    display: 'flex',
    width: '100%',
    height: '100%',
    flex: 1,
    '&:not(:first-of-type)': {
        borderLeft: '1px solid #B6D2E520',
    },
});

export const GanttGrid = memo(({ from, to }: { from: number; to: number }) => {
    const hours = Array(Math.ceil((to - from) / ONE_HOUR))
        .fill(null)
        .map((_, i) => from + i * ONE_HOUR);

    return (
        <GanttGridBox>
            {hours.map(hour => (
                <GanttGridStepBox key={hour}>
                    <GanttGridSubStepBox />
                    <GanttGridSubStepBox />
                    <GanttGridSubStepBox />
                    <GanttGridSubStepBox />
                    <GanttGridSubStepBox />
                    <GanttGridSubStepBox />
                </GanttGridStepBox>
            ))}
        </GanttGridBox>
    );
});
