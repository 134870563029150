import React, { memo } from 'react';
import { Popin, PopinCloseButton } from '@components/navigation/Popin/Popin';
import { Box, styled } from '@mui/material';

const StyledBox = styled(Box)`
    position: absolute;
    top: 20px;
    right: 20px;
`;

export const SlotHistoryPopin = memo(function SlotHistoryPopin({
    children,
    onClose,
}: {
    children: React.ReactNode;
    onClose: () => void;
}) {
    return (
        <Popin
            width={1440}
            onClose={onClose}
            title={
                <StyledBox>
                    <PopinCloseButton onClose={onClose} />
                </StyledBox>
            }
            content={children}
            scroll={'paper'}
        />
    );
});
