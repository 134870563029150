import { SquareButton } from '@components/Button/SquareButton/SquareButton';
import { PopoverMenu } from '@components/navigation/PopoverMenu/PopoverMenu';
import { MenuItem } from '@mui/material';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconPlus } from '../../../assets/icons/icon-plus.svg';
import { useBoolean } from '../../../hooks/useBoolean';
import { ImportTableForm } from './ImportTableForm';
import { SSIMFormPopin } from '@components/Requests/RequestAdd/SSIMFormPopin';
import { RequestAddModifySlotPopin } from '@components/Requests/RequestAdd/RequestAddModifySlotPopin';

const renderButton = ({ onClick }: any) => (
    <SquareButton
        color="secondary"
        size="small"
        variant="contained"
        onClick={onClick}
        startIcon={<IconPlus />}
        loadingPosition="start"
    >
        <FormattedMessage id="requests.actions.add" />
    </SquareButton>
);

export const RequestAdd = memo(function RequestAddButton() {
    const { value: openedImportTableForm, setTrue: openImportTableForm, setFalse: closeImportTableForm } = useBoolean();
    const { value: openedModifySlotPopin, setTrue: openModifySlotPopin, setFalse: closeModifySlotPopin } = useBoolean();
    const { value: openedSSIMPopin, setTrue: openSSIMPopin, setFalse: closeSSIMPopin } = useBoolean();

    return (
        <>
            {openedModifySlotPopin && <RequestAddModifySlotPopin onClose={closeModifySlotPopin} />}
            {openedSSIMPopin && <SSIMFormPopin onClose={closeSSIMPopin} />}

            <PopoverMenu renderButton={renderButton} onClose={closeImportTableForm}>
                {({ close: closeMenu }) =>
                    openedImportTableForm ? (
                        <ImportTableForm onClose={closeMenu} />
                    ) : (
                        // The Menu component doesn't accept a Fragment as a child.
                        [
                            <MenuItem key={'importTable'} onClick={openImportTableForm}>
                                <FormattedMessage id="addRequest.importTable" />
                            </MenuItem>,
                            <MenuItem
                                key={'manualRequest'}
                                onClick={() => {
                                    openModifySlotPopin();
                                    closeMenu();
                                }}
                            >
                                <FormattedMessage id="addRequest.manualRequest" />
                            </MenuItem>,
                            <MenuItem
                                key={'ssim'}
                                onClick={() => {
                                    openSSIMPopin();
                                    closeMenu();
                                }}
                            >
                                <FormattedMessage id="addRequest.ssim" />
                            </MenuItem>,
                        ]
                    )
                }
            </PopoverMenu>
        </>
    );
});
