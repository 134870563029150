import * as React from 'react';
import { ComponentProps, memo, ReactNode } from 'react';
import { Field, FieldProps } from 'formik';
import { Select } from '@components/form/Select/Select';

export const FormikSelect = memo(function FormikSelect({
    name,
    children,
    ...rest
}: { label?: string | React.ReactNode; children: ReactNode; name: string } & ComponentProps<typeof Select>) {
    return (
        <Field name={name}>
            {({ field }: FieldProps) => (
                <Select {...rest} {...field} value={field.value || ''}>
                    {children}
                </Select>
            )}
        </Field>
    );
});
