import React, { useCallback } from 'react';
import { FlightTurnaroundDto, ImpactStatus, TurnaroundResourcesUpdateDto } from '@types';
import { useBoolean } from '../../../hooks/useBoolean';
import { useQueryClient } from 'react-query';
import { KeyGetGetDayGanttTurnarounds, KeyGetGetSlotStrip, useMutationPostUpdateTurnaroundResources } from '@models';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ResourcesPopin } from '@components/Resources/Update/ResourcesPopin';

export const ResourcesSlotOccurenceButton = React.memo(function ResourcesSlotOccurenceButton({
    flight,
}: {
    flight?: FlightTurnaroundDto;
}) {
    const { value: opened, setTrue: open, setFalse: close } = useBoolean(false);

    const queryClient = useQueryClient();
    const { mutateAsync: updateResources } = useMutationPostUpdateTurnaroundResources({
        onSuccess: ({ data }) => {
            close();
            queryClient.invalidateQueries(KeyGetGetDayGanttTurnarounds);
            queryClient.invalidateQueries(KeyGetGetSlotStrip);
        },
    });

    const onSubmitUpdateResources = useCallback(
        (newResources: { turnaroundResourcesUpdate: TurnaroundResourcesUpdateDto; propagate: boolean }) =>
            updateResources({
                turnaroundResourcesUpdateDto: newResources.turnaroundResourcesUpdate,
                propagate: newResources.propagate,
            }),
        [updateResources],
    );

    return (
        <>
            <Button
                variant="outlined"
                size="large"
                color="secondary"
                sx={{ width: '130px' }}
                onClick={open}
                disabled={flight?.impactStatus === ImpactStatus.CANCELLED}
            >
                <FormattedMessage id="schedule.resources" />
            </Button>
            {opened && flight && <ResourcesPopin flight={flight} onClose={close} onSubmit={onSubmitUpdateResources} />}
        </>
    );
});
