import React, { memo, useEffect, useMemo } from 'react';
import { Box, MenuItem, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FormikDatePicker } from '@components/form/DatePicker/FormikDatePicker';
import { Form, useFormikContext } from 'formik';
import { useCurrentUserClient } from '../../../contexts/AuthContext.selectors';
import { FormikSelect } from '@components/form/Select/FormikSelect';
import { EMPTY_ARRAY } from '../../../config/constants';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { Radio, RadioGroupField } from '@components/form/RadioGroup/RadioGroup';
import { subDays } from 'date-fns';
import { ExportPopinFormValues } from '@components/Requests/RequestAdd/ExportPopin';

const StyledContainer = styled(Box)`
    z-index: 100;
    padding: 40px;
    min-width: 355px;
`;

const StyledExportTitle = styled(Box)`
    font-size: 18px;
    color: #0c456c;
`;

const StyledExportSubtitle = styled(Box)`
    font-size: 15px;
    color: #0c456c;
    margin-top: ${props => props.theme.spacing(2)};
`;

const StyledLine = styled(Box)`
    color: #0c456c !important;
    display: flex;
    margin-top: ${props => props.theme.spacing(2)};
    label {
        color: #0c456c !important;
    }
`;

const StyledSelectField = styled(FormikSelect)`
    label {
        color: #0c456c !important;
    }
    min-width: 200px;
    & .MuiSelect-select {
        color: #0c456c !important;
    }
`;

const StyledRadio = styled(Radio)`
    width: 100%;
`;

const StyledDatePickerBox = styled(Box)`
    max-width: 240px;
    margin-left: 20px;
`;

const StyledButtonContainer = styled(Box)`
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
`;

const StyledCompareWithBox = styled(Box)`
    display: flex;
    max-height: 60px;
    margin-left: 24px;
    font-size: 16px;
    font-weight: normal;
    align-items: baseline;
`;

export const ExportForm = memo(({ onClose }: { onClose: () => void }) => {
    const clientConfiguration = useCurrentUserClient();
    const { values, setFieldValue } = useFormikContext<ExportPopinFormValues>();
    const yesterday = subDays(new Date(), 1);
    const compareMaxDate = useMemo(
        () =>
            values.exportVersion === 'current' ? yesterday : values.on ? subDays(new Date(values.on), 1) : yesterday,
        [values.exportVersion, values.on, yesterday],
    );

    useEffect(() => {
        if (values.format !== 'Delta') {
            setFieldValue('compareWith', false);
        } else if (values.format === 'Delta') {
            setFieldValue('compareWith', true);
        }
    }, [setFieldValue, values.format]);

    return (
        <Form>
            <StyledContainer>
                <StyledExportTitle>
                    <FormattedMessage id="export.popin.title" />
                </StyledExportTitle>
                <StyledLine justifyContent="center" alignItems="center">
                    <Box maxWidth={250}>
                        <FormikDatePicker
                            label={<FormattedMessage id="export.popin.from" />}
                            required={true}
                            name={'from'}
                        />
                    </Box>
                    <Box ml={10} maxWidth={250}>
                        <FormikDatePicker
                            label={<FormattedMessage id="export.popin.to" />}
                            required={true}
                            name={'to'}
                        />
                    </Box>
                </StyledLine>
                <StyledLine>
                    <StyledSelectField
                        name={'format'}
                        label={<FormattedMessage id="export.popin.format" />}
                        displayEmpty={true}
                    >
                        <MenuItem value="">
                            <em>-</em>
                        </MenuItem>
                        {Array.from(clientConfiguration?.exportModes || EMPTY_ARRAY).map((em: string) => (
                            <MenuItem key={em} value={em}>
                                {em}
                            </MenuItem>
                        ))}
                    </StyledSelectField>
                </StyledLine>
                <StyledExportSubtitle>
                    <FormattedMessage id="export.popin.subtitle" />
                </StyledExportSubtitle>
                <StyledLine>
                    <RadioGroupField name={'exportVersion'}>
                        <Radio value={'current'}>
                            <FormattedMessage id={'export.popin.versionCurrent'} />
                        </Radio>
                        <Box display={'flex'} maxHeight={60}>
                            <Box>
                                <StyledRadio value={'past'}>
                                    <FormattedMessage id={'export.popin.versionPast'} />
                                </StyledRadio>
                            </Box>
                            <StyledDatePickerBox>
                                <FormikDatePicker
                                    name={'on'}
                                    required
                                    disabled={values.exportVersion !== 'past'}
                                    maxDate={yesterday}
                                />
                            </StyledDatePickerBox>
                        </Box>
                        {values.format === 'Delta' && (
                            <StyledCompareWithBox>
                                <FormattedMessage id={'export.popin.compare'} />
                                <StyledDatePickerBox>
                                    <FormikDatePicker
                                        // label={<FormattedMessage id={'export.popin.compare'} />}
                                        name={'since'}
                                        required
                                        maxDate={compareMaxDate}
                                    />
                                </StyledDatePickerBox>
                            </StyledCompareWithBox>
                        )}
                    </RadioGroupField>
                </StyledLine>
                <StyledButtonContainer>
                    <RoundButton color="primary" size="medium" variant="outlined" onClick={onClose}>
                        <FormattedMessage id="export.popin.cancel" />
                    </RoundButton>
                    <RoundButton size="medium" variant="contained" type="submit">
                        <FormattedMessage id="export.popin.export" />
                    </RoundButton>
                </StyledButtonContainer>
            </StyledContainer>
        </Form>
    );
});
