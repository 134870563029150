import { StripMenu } from '@components/Strip/StripMenu/StripMenu';
import { ActiveVersionDto, DemandStatus, ModificationDemandDto } from '@models';
import React, { memo } from 'react';
import { StripMenuItemConfirmAllOccurrences } from '@components/Strip/StripMenu/StripMenuItemConfirmAllOccurrences';
import { StripMenuItemCheckOnGanttView } from '@components/Strip/StripMenu/StripMenuItemCheckOnGanttView';
import { StripMenuListItemTextUpdatedVersionOccurrence } from '@components/Strip/StripMenu/StripMenuListItemTextUpdatedVersionOccurrence';
import { StripMenuItemSeeUpdateHistory } from '@components/Strip/StripMenu/StripMenuItemSeeUpdateHistory';
import { useBoolean } from '../../hooks/useBoolean';
import { SlotHistory } from '@components/SlotHistory/SlotHistory';
import { Divider } from '@mui/material';

export const StripMenuRequestPage = memo(
    ({
        slot,
        disabled,
        hasPending,
        status,
        updatedVersions,
    }: {
        slot: ModificationDemandDto;
        disabled?: boolean;
        hasPending?: boolean;
        status: DemandStatus;
        updatedVersions?: Array<ActiveVersionDto>;
    }) => {
        // const { value: isModifySlotPopinOpened, setFalse: onClose } = useBoolean();

        const {
            value: isSlotHistoryPopinOpened,
            setFalse: closeSlotHistoryPopin,
            setTrue: openSlotHistoryPopin,
        } = useBoolean();

        if (!slot) {
            return null;
        }

        return (
            <>
                <StripMenu disabled={disabled}>
                    {updatedVersions?.length ? (
                        updatedVersions.map(updatedVersion => (
                            <StripMenuItemCheckOnGanttView key={updatedVersion.id} slot={updatedVersion}>
                                {updatedVersions.length > 1 ? (
                                    <StripMenuListItemTextUpdatedVersionOccurrence
                                        slot={slot}
                                        activeVersion={updatedVersion}
                                    />
                                ) : null}
                            </StripMenuItemCheckOnGanttView>
                        ))
                    ) : (
                        <StripMenuItemCheckOnGanttView slot={slot} />
                    )}
                    <StripMenuItemSeeUpdateHistory onClick={openSlotHistoryPopin} />

                    {/*The Menu component doesn't accept a Fragment as a child.*/}
                    {status === DemandStatus.NEW && hasPending && <Divider />}
                    {status === DemandStatus.NEW && hasPending && <StripMenuItemConfirmAllOccurrences slot={slot} />}

                    {/*{status === DemandStatus.NEW && (*/}
                    {/*    <StripMenuItemModifyAllOccurrences slot={slot} onClick={openModifySlotPopin} />*/}
                    {/*    <StripMenuItemCancelAllOccurrences slot={slot} hasPending={hasPending} />*/}
                    {/*    <StripMenuItemSplitArrDep slot={slot} />*/}
                    {/*)}  */}
                </StripMenu>

                {/*{isModifySlotPopinOpened && <ModifySlot slot={slot} onClose={onClose} />}*/}
                {isSlotHistoryPopinOpened && <SlotHistory slotId={slot.id!} onClose={closeSlotHistoryPopin} />}
            </>
        );
    },
);
