/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import React from 'react';
import { IcaoIataDto } from '@models';

export const IcaoIata = React.memo(function IcaoIata({ iata, icao }: IcaoIataDto) {
    // TODO get iata/icao state

    return <>{iata ?? <i>{icao}</i>}</>;
});
