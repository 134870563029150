/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { IcaoIata } from '@components/IcaoIata';
import { IcaoIataDto } from '@models';
import { Box } from '@mui/material';
import React, { memo } from 'react';

export const FlightTurnaroundAirport = memo(function FlightTurnaroundAirport({
    airport,
    flightNumber,
}: {
    airport?: IcaoIataDto | null;
    flightNumber?: string | null;
}) {
    if (!airport || !flightNumber) {
        return null;
    }

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} margin={'0 5px'}>
            <Box
                sx={{
                    fontSize: '16px',
                    fontWeight: '900',
                    lineHeight: '19px',
                }}
            >
                <IcaoIata {...airport} />
            </Box>
            <Box
                sx={{
                    fontSize: '12px',
                    lineHeight: '14px',
                }}
            >
                {flightNumber}
            </Box>
        </Box>
    );
});
