import React, { memo, useMemo } from 'react';
import { ActionStatus, RequestType, SlotVersionDto } from '@types';
import { Box, Divider, styled, Typography } from '@mui/material';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { StripHistoryInstance } from '@components/Strip/StripHistoryInstance';
import { format } from 'date-fns';

const StyledContainerBox = styled(Box)`
    margin-bottom: 30px;
`;

const StyledStripContainerBox = styled(Box)`
    margin-top: 2px;
    & * {
        margin-top: 0;
    }
`;

const StyledDivider = styled(Divider)`
    margin-bottom: 20px;
    ::before {
        border-top: 2px solid #14517b;
    }
    ::after {
        border-top: 2px solid #14517b;
    }
`;

const StyledDateChipBox = styled(Box)`
    background-color: #14517b;
    border-radius: 5px;
    color: #b6d2e5;
    font-size: 12px;
    padding: 2px 4px;
`;

export const SlotHistoryContentSlotVersion = memo(function SlotHistoryContentSlotVersion({
    slotVersion,
}: {
    slotVersion: SlotVersionDto;
}) {
    const userComplementaryInfo = useMemo(() => {
        if (slotVersion.requestType === RequestType.MANUAL) {
            return slotVersion.userName;
        } else if (slotVersion.requestType === RequestType.IMPORT) {
            return `${slotVersion.userName}: ${slotVersion.fileName}`;
        } else if (slotVersion.requestType === RequestType.SSIM) {
            return slotVersion.userEmail;
        } else {
            return null;
        }
    }, [slotVersion.fileName, slotVersion.requestType, slotVersion.userEmail, slotVersion.userName]);

    const actionStatusComplementaryInfo = useMemo(() => {
        if (slotVersion.actionStatus === ActionStatus.UPDATED) {
            return ' - ' + slotVersion.updateTypes?.join(', ');
        } else {
            return null;
        }
    }, [slotVersion.actionStatus, slotVersion.updateTypes]);

    return (
        <StyledContainerBox>
            <StyledDivider>
                <StyledDateChipBox>
                    <FormattedDate value={slotVersion.date} /> {format(slotVersion.date, 'HH:mm')}
                    {' - '}
                    <FormattedMessage id={`slotUpdateHistory.requestType.${slotVersion.requestType}`} />
                    {userComplementaryInfo}
                </StyledDateChipBox>
            </StyledDivider>
            <Typography variant="subtitle2" color="#B6D2E5">
                {slotVersion.actionStatus && (
                    <>
                        <FormattedMessage id={`slotUpdateHistory.actionStatus.${slotVersion.actionStatus}`} />
                        {actionStatusComplementaryInfo}
                    </>
                )}
            </Typography>
            <StyledStripContainerBox>
                <StripHistoryInstance slotStripDto={slotVersion} />
            </StyledStripContainerBox>
        </StyledContainerBox>
    );
});
