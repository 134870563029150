import React, { memo } from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import { ReactComponent as AllocDeleteIcon } from '../../../assets/icons/icon-alloc-delete.svg';

const StyledBox = styled(Box)`
    background-color: #fafafa;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
`;

export const FlightTurnaroundRejectResourceProposal = memo(function FlightTurnaroundRejectResourceProposal(
    props: BoxProps,
) {
    return (
        <Box {...props}>
            <StyledBox>
                <AllocDeleteIcon />
            </StyledBox>
        </Box>
    );
});
