import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { Popin, PopinCloseButton } from '@components/navigation/Popin/Popin';
import { GanttStripDto, UpdatePeriodDto, WeekDay } from '@models';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import { Form, Formik, useFormikContext } from 'formik';
import React, { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { CancelForm } from '@components/Occurences/CancelPopin/CancelForm';
import { array, boolean, number, object, ref } from 'yup';
import { eachDayOfInterval } from 'date-fns';
import { getISODay } from 'date-fns/fp';

const SubmitButton = memo(function SubmitButton({ loading }: { loading?: boolean }) {
    const { submitForm, isValid } = useFormikContext<UpdatePeriodDto>();

    return (
        <RoundButton
            autoFocus
            onClick={() => submitForm()}
            variant="contained"
            size="medium"
            disabled={!isValid || loading}
            loading={loading}
        >
            <FormattedMessage id="cancelForm.confirm" />
        </RoundButton>
    );
});

const ValidationSchema = object().shape({
    punctual: boolean(),
    begin: number().when('punctual', {
        is: true,
        then: number().when('arrivalFlight', { is: true, then: number().required('form.required') }),
        otherwise: number().required('form.required'),
    }),
    end: number().when('punctual', {
        is: true,
        then: number().when('departureFlight', { is: true, then: number().required('form.required') }),
        otherwise: number().required('form.required'),
    }),
    pattern: array().when(['begin', 'end', 'punctual'], {
        is: (begin, end, punctual) => begin && end && !punctual,
        then: array().test(
            'pattern',
            'requestForm.error.PERIOD_INCOHERENT',
            (pattern: WeekDay[] | undefined, { resolve }) => {
                // Selected pattern days must be among the date range days
                // i.e. cannot select Monday for example if the date range is from TOuesday to Friday
                const start = resolve(ref<number | undefined>('begin'));
                const end = resolve(ref<number | undefined>('end'));

                if (!start || !end) {
                    return false;
                }

                const daysInDateRange = eachDayOfInterval({ start, end });
                if (daysInDateRange.length >= 7 && pattern?.length) {
                    // 7 days = a week = all 7 weekdays can be selected in pattern
                    return true;
                }

                if (!pattern?.length || !daysInDateRange.length) {
                    // No pattern selected or no date range
                    return false;
                }

                // ISO days start at 1=monday
                const weekdaysInRange = daysInDateRange.map(day => getISODay(day) - 1);
                // pattern days enum keys starts at 0=monday
                const weekdaysInPattern = pattern.map(day => Object.keys(WeekDay).indexOf(day));

                return weekdaysInPattern.every(day => weekdaysInRange.includes(day));
            },
        ),
    }),
});

export const CancelPopin = memo(function CancelPopin({
    slot,
    isLoading,
    onClose,
    onSubmit,
}: {
    slot: GanttStripDto;
    isLoading?: boolean;
    onClose?: () => void;
    onSubmit?: (v: UpdatePeriodDto) => void;
}) {
    const handleSubmit = useCallback(
        (values: UpdatePeriodDto) => {
            onSubmit?.(values);
        },
        [onSubmit],
    );

    const initialValues: UpdatePeriodDto = useMemo(
        () => ({
            punctual: slot.punctual,
            begin: slot.begin,
            end: slot.end,
            pattern: slot.pattern,
        }),
        [slot.begin, slot.end, slot.pattern, slot.punctual],
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={ValidationSchema}>
            <Form>
                <Popin
                    onClose={onClose}
                    title={
                        <>
                            <EditIcon />
                            <Box display="flex" flex={1}>
                                <FormattedMessage id="cancelForm.title.single" />
                            </Box>
                            <PopinCloseButton onClose={onClose} />
                        </>
                    }
                    content={<CancelForm />}
                    actions={
                        <>
                            <RoundButton onClick={onClose} variant="outlined" size="medium">
                                <FormattedMessage id="occurrenceForm.cancel" />
                            </RoundButton>
                            &emsp;
                            <SubmitButton loading={isLoading} />
                        </>
                    }
                />
            </Form>
        </Formik>
    );
});
