/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';
import { ganttFlightRowHeight } from '../Gantt/gantt.constants';

export const FlightTurnaroundOuterContentArrival = styled<
    React.FC<
        {
            selected?: boolean;
        } & Partial<BoxProps>
    >
>(Box)`
    display: flex;
    height: ${ganttFlightRowHeight}px;
    align-items: center;
    font-family: Roboto, serif;
    letter-spacing: 0;

    position: absolute;
    top: 0;
    width: 0;
    color: ${({ selected, theme }) => (selected ? 'white' : theme.palette.blue.lightest)};
    justify-content: flex-end;
    left: -10px;
    cursor: pointer;
    pointer-events: auto;
`;

export const FlightTurnaroundOuterContentDeparture = styled<
    React.FC<
        {
            selected?: boolean;
        } & Partial<BoxProps>
    >
>(Box)`
    display: flex;
    height: ${ganttFlightRowHeight}px;
    align-items: center;
    font-family: Roboto, serif;
    letter-spacing: 0;

    position: absolute;
    top: 0;
    width: 0;
    color: ${({ selected, theme }) => (selected ? 'white' : theme.palette.blue.lightest)};
    justify-content: flex-start;
    right: -10px;
    cursor: pointer;
    pointer-events: auto;
`;
