import React, { memo, useCallback, useMemo } from 'react';
import { ScheduleHeader } from '@components/Schedule/Header/ScheduleHeader';
import { Box, styled } from '@mui/material';
import { Strip } from '@components/Strip/Strip';
import { StripMenuSchedulePage } from '@components/Schedule/StripMenuSchedulePage';
import { ScheduleControls } from '@components/Schedule/ScheduleControls';
import { FlightTurnaroundDto, WeekDay } from '@types';
import { KeyGetGetDayGanttTurnarounds, KeyGetGetSlotStrip, useMutationPostUpdateSlotOccurrences } from '@models';
import { useQueryClient } from 'react-query';
import { useUiState, useUiValue } from '../../../contexts/UiContext';
import { ScheduleCloseStrip } from '@components/Schedule/ScheduleCloseStrip';
import { filterScheduleFlights } from '../../../utils/search.utils';
import { useSchedulePageData } from '@components/Schedule/useSchedulePageData';
import { GanttFlightRow } from '@components/Schedule/Gantt/GanttFlightRow';
import { GanttOverCapacityAlerts } from '@components/Schedule/Gantt/GanttOverCapacityAlert';
import { getLastFlightTime, getSliceOrigin } from '@components/Schedule/Gantt/gantt.utils';
import { Gantt, GanttFlightsBox } from '@components/Schedule/Gantt/Gantt';
import { GanttSelection } from '@components/Schedule/Gantt/GanttSelection';
import { GanttFlights } from '@components/Schedule/Gantt/GanttFlights';
import { format } from 'date-fns';

const StyledGanttContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const SchedulePageNominal = memo(function SchedulePageNominal({
    selectedDate,
    onClickStartAssociating,
    onClickStartAssociatingDayOnly,
}: {
    selectedDate: Date;
    onClickStartAssociating: () => void;
    onClickStartAssociatingDayOnly: () => void;
}) {
    const queryClient = useQueryClient();

    const { ganttResponse, slotResponse, selectedFlights, selection } = useSchedulePageData(selectedDate, true);

    const [selectedFlightId, setSelectedFlightId] = useUiState('selectedFlightId');
    const [selectedSlotId, setSelectedSlotId] = useUiState('selectedSlotId');
    const sortByResource = useUiValue('sortByResource');
    const search = useUiValue('search');
    const showCancelledRequests = useUiValue('showCancelledRequests');

    const selectedMode = useUiValue('selectedMode');

    const filteredFlights: FlightTurnaroundDto[] = useMemo(
        () =>
            filterScheduleFlights(ganttResponse?.flights, {
                search,
                showCancelledRequests,
                selectedMode,
            }),
        [ganttResponse?.flights, search, selectedMode, showCancelledRequests],
    );

    const onClickSelectFlight = useCallback(
        (flight: FlightTurnaroundDto) => {
            setSelectedSlotId(null);
            setSelectedFlightId(flight?.id || null);
        },
        [setSelectedFlightId, setSelectedSlotId],
    );

    const { mutateAsync: updateOccurences } = useMutationPostUpdateSlotOccurrences({
        onSuccess: () => {
            queryClient.invalidateQueries(KeyGetGetDayGanttTurnarounds);
            queryClient.invalidateQueries(KeyGetGetSlotStrip);
        },
    });
    const onDragEnd = useCallback(
        (flightTurnaroundUpdateDto: FlightTurnaroundDto) => {
            updateOccurences({
                id: flightTurnaroundUpdateDto.id,
                confirm: true,
                scheduleSlotUpdateDto: {
                    ...flightTurnaroundUpdateDto,
                    pattern: [format(selectedDate, 'EEEE').toUpperCase() as WeekDay],
                    begin: flightTurnaroundUpdateDto.sibt,
                    end: flightTurnaroundUpdateDto.sobt,
                },
            }).then(r => {
                if (r.data.updatedSlotId) {
                    setSelectedSlotId(r.data.updatedSlotId);
                }
            });
        },
        [selectedDate, setSelectedSlotId, updateOccurences],
    );

    return (
        <>
            <ScheduleHeader />
            <StyledGanttContainer>
                {(selectedSlotId || selectedFlightId) && slotResponse && (
                    <Box display="flex" justifyContent="center" alignItems="center" my={2} flexShrink={0}>
                        <Strip
                            slotStripDto={slotResponse}
                            renderStripMenu={props => (
                                <StripMenuSchedulePage
                                    {...props}
                                    slot={slotResponse}
                                    onClickAssociate={onClickStartAssociating}
                                    onClickAssociateDayOnly={onClickStartAssociatingDayOnly}
                                    hasPending={slotResponse.nbPending > 0}
                                />
                            )}
                        />
                        <ScheduleCloseStrip />
                    </Box>
                )}

                <Gantt day={selectedDate} lastFlightTime={getLastFlightTime(filteredFlights)}>
                    {!sortByResource && <GanttOverCapacityAlerts alerts={ganttResponse?.overcapacityAlerts} />}
                    <GanttSelection {...selection} />
                    <GanttFlights sortByResource={sortByResource} flights={filteredFlights}>
                        {(flights, resourceName) => (
                            <>
                                {sortByResource && (
                                    <GanttOverCapacityAlerts
                                        alerts={ganttResponse?.overcapacityAlerts?.filter(
                                            alert =>
                                                alert.resourceType === sortByResource &&
                                                alert.resourceName === resourceName,
                                        )}
                                    />
                                )}
                                <GanttFlightsBox>
                                    {flights?.map(flight => (
                                        <GanttFlightRow
                                            key={flight.id}
                                            flight={flight}
                                            resourceDiscrepancyAlerts={ganttResponse?.discrepancyAlerts}
                                            isFlightSelected={
                                                selection?.flightId === flight.id ||
                                                selection?.slotId === slotResponse?.id
                                            }
                                            sliceOrigin={getSliceOrigin(flight, sortByResource, resourceName)}
                                            onClick={onClickSelectFlight}
                                            onDragEnd={onDragEnd}
                                        />
                                    ))}
                                </GanttFlightsBox>
                            </>
                        )}
                    </GanttFlights>
                </Gantt>

                {selectedFlightId && (
                    <ScheduleControls
                        flight={selectedFlights?.[0]}
                        flights={slotResponse?.flights}
                        total={slotResponse?.nbTotal}
                        index={selectedFlightId ? slotResponse?.flights?.findIndex(f => f.id === selectedFlightId) : 0}
                    />
                )}
            </StyledGanttContainer>
        </>
    );
});
