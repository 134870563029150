import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { Popin, PopinCloseButton } from '@components/navigation/Popin/Popin';
import { FlightTurnaroundDto, ScheduleSlotUpdateDto } from '@models';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import { Form, Formik, useFormikContext } from 'formik';
import React, { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { OccurrenceForm } from './OccurrenceForm';
import { boolean, number, object } from 'yup';

const SubmitButton = memo(function SubmitButton({ loading }: { loading?: boolean }) {
    const { submitForm, isValid } = useFormikContext<ScheduleSlotUpdateDto>();

    return (
        <RoundButton
            autoFocus
            onClick={() => submitForm()}
            variant="contained"
            size="medium"
            disabled={!isValid || loading}
            loading={loading}
        >
            <FormattedMessage id="occurrenceForm.confirm" />
        </RoundButton>
    );
});

const OccurrenceValidationSchema = object().shape({
    sibtRequired: boolean(),
    sobtRequired: boolean(),
    sibt: number().when('sibtRequired', {
        is: true,
        then: number().nullable().required('form.required'),
        otherwise: number().nullable(),
    }),
    sobt: number().when('sobtRequired', {
        is: true,
        then: number().nullable().required('form.required'),
        otherwise: number().nullable(),
    }),
});

export const OccurrencePopin = memo(function OccurrencePopin({
    flight,
    isLoading,
    onClose,
    onSubmit,
}: {
    flight: FlightTurnaroundDto;
    isLoading?: boolean;
    onClose?: () => void;
    onSubmit?: (v: ScheduleSlotUpdateDto) => void;
}) {
    const handleSubmit = useCallback(
        (values: ScheduleSlotUpdateDto) => {
            onSubmit?.(values);
        },
        [onSubmit],
    );

    const initialValues: ScheduleSlotUpdateDto = useMemo(
        () => ({
            ...flight,
            sibtRequired: !!flight.sibt,
            sobtRequired: !!flight.sobt,
        }),
        [flight],
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={OccurrenceValidationSchema}>
            <Form>
                <Popin
                    onClose={onClose}
                    title={
                        <>
                            <EditIcon />
                            <Box display="flex" flex={1}>
                                <FormattedMessage id="occurrenceForm.title.single" />
                            </Box>
                            <PopinCloseButton onClose={onClose} />
                        </>
                    }
                    content={<OccurrenceForm />}
                    actions={
                        <>
                            <RoundButton onClick={onClose} variant="outlined" size="medium">
                                <FormattedMessage id="occurrenceForm.cancel" />
                            </RoundButton>
                            &emsp;
                            <SubmitButton loading={isLoading} />
                        </>
                    }
                />
            </Form>
        </Formik>
    );
});
