/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { IcaoIata } from '@components/IcaoIata';
import { IcaoIataDto } from '@models';
import { Box, BoxProps, styled } from '@mui/material';
import {
    ganttFlightRowHeight,
    ganttTurnaroundChildrenBreakpoint,
    ganttTurnaroundChildrenBreakpointWithoutImpact,
} from '@components/Schedule/Gantt/gantt.constants';
import React, { forwardRef, memo } from 'react';
import { containerSizeHoc } from 'utils/containerSize.hoc';

export const FlightTurnaroundInnerContentBox = styled(Box)`
    display: flex;
    width: fit-content;
    flex-direction: column;
    height: ${ganttFlightRowHeight}px;
    justify-content: center;
    align-items: flex-start;
    font-family: Roboto, serif;
    letter-spacing: 0;
`;

const FlightTurnaroundInnerContentBase = memo(
    forwardRef(function FlightTurnaroundInnerContentBase(
        {
            airline,
            acType,
            ...rest
        }: {
            airline?: IcaoIataDto | null;
            acType?: IcaoIataDto | null;
            hasImpact?: boolean;
        } & Partial<BoxProps>,
        ref, // used by forwardRef to allow containerSizeHoc to get the parent size
    ) {
        return (
            <FlightTurnaroundInnerContentBox {...rest} ref={ref}>
                <Box
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '16px',
                        lineHeight: '19px',
                    }}
                >
                    <IcaoIata {...airline} />
                </Box>
                <Box
                    sx={{
                        fontSize: '13px',
                        lineHeight: '15px',
                    }}
                >
                    <IcaoIata {...acType} />
                </Box>
            </FlightTurnaroundInnerContentBox>
        );
    }),
);

// Don't display the content if the width of the parent is too small
export const FlightTurnaroundInnerContent = containerSizeHoc(FlightTurnaroundInnerContentBase, {
    minWidth: ({ hasImpact }) =>
        hasImpact ? ganttTurnaroundChildrenBreakpoint : ganttTurnaroundChildrenBreakpointWithoutImpact,
});
