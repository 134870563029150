import React, { memo } from 'react';
import { SlotHistoryPopin } from '@components/SlotHistory/SlotHistoryPopin';
import { useQueryGetGetSlotHistory } from '@models';
import { SlotHistoryContent } from '@components/SlotHistory/SlotHistoryContent';

export const SlotHistory = memo(function SlotHistory({ slotId, onClose }: { slotId: string; onClose: () => void }) {
    const { data: slotHistory } = useQueryGetGetSlotHistory({ id: slotId }, { cacheTime: 0 });

    if (!slotHistory) {
        return null;
    }

    return (
        <SlotHistoryPopin onClose={onClose}>
            <SlotHistoryContent slotHistory={slotHistory} />
        </SlotHistoryPopin>
    );
});
