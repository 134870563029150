import React, { memo, useCallback, useMemo } from 'react';
import { DaySlotResourcesAllocationDto, useMutationPostAutoAllocateDaySlotResources } from '@models';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { errorToastConfig } from '../../../config/constants';
import { ResourcesAllocateForm } from '@components/Resources/Allocate/ResourcesAllocateForm';
import { ResourcesAllocateResults } from '@components/Resources/Allocate/ResourcesAllocateResults';
import { format as originalFormat } from 'date-fns';
import { ganttDayFormat } from '@components/Schedule/Gantt/gantt.constants';
import { useUiValue } from '../../../contexts/UiContext';
import { useCurrentUserResources } from '../../../contexts/AuthContext.selectors';

export const ResourcesAllocatePopin = memo(function ResourcesAllocatePopin({ onClose }: { onClose: () => void }) {
    const selectedDate = useUiValue('selectedDate');
    const clientResources = useCurrentUserResources();

    const {
        mutateAsync: autoAllocate,
        data: allocationResponse,
        isLoading,
    } = useMutationPostAutoAllocateDaySlotResources({
        onError: () => {
            toast(<FormattedMessage id={'schedule.allocate.errorToast'} />, errorToastConfig);
        },
    });

    const onSubmit = useCallback(
        (values: DaySlotResourcesAllocationDto) => {
            autoAllocate({
                daySlotResourcesAllocationDto: values,
            });
        },
        [autoAllocate],
    );

    const resourcesAllocateFormInitialValues = useMemo(
        () => ({
            day: originalFormat(selectedDate, ganttDayFormat),
            resourceType: clientResources?.[0]?.type || '',
            allocateFromScratch: false,
        }),
        [clientResources, selectedDate],
    );

    return allocationResponse ? (
        <ResourcesAllocateResults results={allocationResponse} onClose={onClose} />
    ) : (
        <ResourcesAllocateForm
            onSubmit={onSubmit}
            onClose={onClose}
            isLoading={isLoading}
            initialValues={resourcesAllocateFormInitialValues}
        />
    );
});
