import { Box, styled, Tooltip, Typography } from '@mui/material';
import React, { memo } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useCurrentUserClientTrafficTypes } from '../../contexts/AuthContext.selectors';
import { GanttStripDto, ModificationDemandDto, SlotUpdateDto, SlotVersionDto } from '@types';
import { StripElement } from '@components/Strip/StripElement';

const StyledTypesBox = styled(StripElement)`
    min-width: 79px;
    border-right: none;
    justify-content: center;
    border-radius: 0 ${({ theme }) => theme.shape.borderRadiusLarge}px ${({ theme }) => theme.shape.borderRadiusLarge}px
        0;
`;

const StyledTypesUpdateBox = styled(StyledTypesBox)`
    min-width: 81px;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.primary.light};
    top: 61px;
    margin-left: -1px;
    border-left: 1px dashed ${({ theme }) => theme.palette.blue.lightBg};
`;

const StripTrafficTypesInfosLabel = memo(
    ({ arrivalType, departureType }: { departureType?: string | null; arrivalType?: string | null }) => {
        const trafficTypes = useCurrentUserClientTrafficTypes();
        const trafficTypeDescriptions = trafficTypes?.filter(
            type => type.code === arrivalType || type.code === departureType,
        );

        return (
            <Box>
                <Typography fontSize="16px">
                    {arrivalType} - {departureType}
                </Typography>
                {(arrivalType || departureType) && (
                    <Tooltip
                        title={
                            <>
                                {trafficTypeDescriptions?.map((type, index) => (
                                    <Box key={index}>
                                        {type.code} - {type.descriptionEng}
                                    </Box>
                                ))}
                            </>
                        }
                        placement={'right-end'}
                    >
                        <InfoOutlinedIcon fontSize={'small'} sx={{ position: 'absolute', bottom: 2, right: 2 }} />
                    </Tooltip>
                )}
            </Box>
        );
    },
);

export const StripTrafficTypesInfos = memo(
    ({
        slot,
        slotUpdate,
    }: {
        slot?: GanttStripDto | ModificationDemandDto | SlotVersionDto | null;
        slotUpdate?: SlotUpdateDto | null;
    }) => {
        return (
            <Box position="relative">
                <StyledTypesBox>
                    <StripTrafficTypesInfosLabel arrivalType={slot?.arrivalType} departureType={slot?.departureType} />
                </StyledTypesBox>
                {slotUpdate?.types && (
                    <StyledTypesUpdateBox>
                        <StripTrafficTypesInfosLabel
                            arrivalType={slotUpdate?.types.arrivalType}
                            departureType={slotUpdate?.types.departureType}
                        />
                    </StyledTypesUpdateBox>
                )}
            </Box>
        );
    },
);
