import { SettingsHeader } from './SettingsHeader';
import { Settings } from '@components/Settings/Settings';
import { Box } from '@mui/material';
import React, { memo } from 'react';

export const SettingsPage = memo(() => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%" height="100%" overflow="hidden">
            <SettingsHeader />
            <Settings />
        </Box>
    );
});
