/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { Grid, styled, Switch, switchClasses, SwitchProps, Typography } from '@mui/material';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

const DualSwitch = styled(Switch)(({ theme }) => ({
    width: 30,
    height: 16,
    padding: 2,
    display: 'flex',
    marginLeft: '10px !important',
    marginRight: '10px !important',
    [`& .${switchClasses.switchBase}`]: {
        padding: 2,
        [`&.Mui-checked`]: {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
        },
        [`& + .${switchClasses.thumb}`]: {
            color: '#106ebb',
            border: '6px solid #fff',
        },
    },
    [`& .${switchClasses.thumb}`]: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    [`& .${switchClasses.track}`]: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        background: 'transparent',
    },
    [`& .Mui-checked + .${switchClasses.track}`]: {
        backgroundColor: theme => theme.palette.common.white,
    },
}));

export const IcaoIataSwitch = memo(({ onClick, ...props }: { onClick: () => void } & SwitchProps) => {
    return (
        <Typography component="div" variant="subtitle2">
            <Grid
                component="label"
                container
                alignItems="center"
                style={{ margin: '-2px 0 0 0', height: 20, cursor: 'pointer' }}
                onClick={() => onClick?.()}
            >
                <Grid item height={16} alignItems="center">
                    <FormattedMessage id="global.icao" />
                </Grid>
                <Grid item>
                    <DualSwitch disableRipple {...props} />
                </Grid>
                <Grid item height={16} alignItems="center">
                    <FormattedMessage id="global.iata" />
                </Grid>
            </Grid>
        </Typography>
    );
});
