import { Box, styled } from '@mui/material';

export const LeftComponentsHeader = styled(Box)`
    display: flex;
    justify-content: flex-start;
    flex: 40%;
`;

export const MiddleComponentsHeader = styled(Box)`
    display: flex;
    justify-content: center;
    flex: 20%;
`;

export const RightComponentsHeader = styled(Box)`
    display: flex;
    justify-content: flex-end;
    flex: 40%;
`;

export const Header = styled('header')`
    background-color: ${({ theme }) => theme.palette.blue.dark};
    padding: 0 70px;
    height: 155px;
    width: 100%;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
`;
