import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const STRIP_RECURRING_DATE_FORMAT = 'dd LLL';

export const STRIP_PUNCTUAL_DATE_FORMAT = 'iiii dd MMMM';

export const STRIP_HOURS = 'HH:mm';

export const STRIP_HEIGHT = 60;
export const STRIP_TITLE_HEIGHT = 30;
export const STRIP_WITH_UPDATE_HEIGHT = STRIP_HEIGHT * 2 + 5;

export const StripDateStyle: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 16,
    textTransform: 'uppercase',
};
