import React, { useCallback, useEffect } from 'react';
import { FilterRequestsButton } from '@components/Requests/RequestAdd/FilterRequestsButton';
import { FormattedMessage } from 'react-intl';
import { useUiState } from '../../../contexts/UiContext';
import { PopoverMenu } from '@components/navigation/PopoverMenu/PopoverMenu';
import { CircularProgress, MenuItem } from '@mui/material';
import { useQueryGetGetSlotFilters } from '@models';

export const FilterRequestsBySource = () => {
    const [seasonFilter, setSeasonFilter] = useUiState('seasonFilter');

    const {
        data: filters,
        isLoading: isLoadingFilters,
        error: errorFilters,
    } = useQueryGetGetSlotFilters({
        refetchOnMount: true,
        refetchInterval: 2 * 60 * 60 * 1000, // 2 hours in milliseconds
    });

    const currentSeasonLabel = filters?.currentSeason?.label;
    const nextSeasonLabel = filters?.nextSeason?.label;

    const renderButton = useCallback(
        ({ onClick, opened }) => (
            <FilterRequestsButton onClick={onClick} opened={opened}>
                {isLoadingFilters ? (
                    <CircularProgress size={25} color="info" />
                ) : (
                    <FormattedMessage
                        id={`requests.actions.filter.season.${seasonFilter ?? 'ALL'}`}
                        values={{
                            season:
                                seasonFilter === 'CURRENT'
                                    ? currentSeasonLabel
                                    : seasonFilter === 'NEXT'
                                    ? nextSeasonLabel
                                    : undefined,
                        }}
                    />
                )}
            </FilterRequestsButton>
        ),
        [currentSeasonLabel, isLoadingFilters, nextSeasonLabel, seasonFilter],
    );

    // If filters query fails, set seasonFilter to ALL
    // This is because by default, the seasonFilter is set to 'CURRENT' in the UIContext
    useEffect(() => {
        if (errorFilters) {
            setSeasonFilter('ALL');
        }
    }, [errorFilters, setSeasonFilter]);

    return (
        <PopoverMenu renderButton={renderButton} autoClose={true}>
            <MenuItem onClick={() => setSeasonFilter('ALL')}>
                <FormattedMessage id={'requests.actions.filter.season.ALL'} />
            </MenuItem>
            <MenuItem onClick={() => setSeasonFilter('CURRENT')}>
                <FormattedMessage
                    id={'requests.actions.filter.season.CURRENT'}
                    values={{
                        season: currentSeasonLabel,
                    }}
                />
            </MenuItem>
            <MenuItem onClick={() => setSeasonFilter('NEXT')}>
                <FormattedMessage
                    id={'requests.actions.filter.season.NEXT'}
                    values={{
                        season: nextSeasonLabel,
                    }}
                />
            </MenuItem>
        </PopoverMenu>
    );
};
