/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */
import React, { useCallback, useState } from 'react';
import { stopPropagation } from 'utils/dom.utils';
import { useMirroredRef } from './useMirroredRef';

// @workaround: avoids opening the document in the same browser tab on drop (default behaviour)
window.addEventListener('dragover', (e: any) => e.preventDefault());
window.addEventListener('drop', (e: any) => e.preventDefault());

export interface FileImportOptions {
    onSelectFile?: ((files: File[], options?: any) => void) | null;
    dragLabel?: string;
    disabled?: boolean;
}

export const useFileImport = ({ onSelectFile, dragLabel, disabled }: FileImportOptions) => {
    const [isDraggingFile, setIsDraggingFile] = useState(false);
    const onSelectFileRef = useMirroredRef(onSelectFile);

    const onDragEnter = useCallback(
        (e: any) => {
            e.preventDefault();
            if (e.dataTransfer && e.dataTransfer.types.includes('Files')) {
                setIsDraggingFile(true);
            }
        },
        [setIsDraggingFile],
    );

    const onDragExit = useCallback(() => {
        // e.preventDefault();
        setIsDraggingFile(false);
    }, [setIsDraggingFile]);

    const onDropEvent = useCallback(
        (e: React.DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDraggingFile(false);
            const files = e.dataTransfer && e.dataTransfer.files;
            if (files) {
                onSelectFileRef.current?.([].slice.call(files));
            }
            return false;
        },
        [onSelectFileRef],
    );

    const openImportPrompt = useCallback(
        (options: any) => {
            const input = document.createElement('input');
            input.type = 'file';
            input.addEventListener('change', (e: any) => {
                const files = e.target.files;
                if (files) {
                    onSelectFileRef.current?.([].slice.call(files), options);
                }
            });
            input.click();
        },
        [onSelectFileRef],
    );

    return {
        hitboxProps: disabled
            ? {}
            : {
                  onDragEnter,
              },
        openImportPrompt,
        draggingLabel: !disabled && isDraggingFile && (
            <div
                className="dragging-zone"
                onDragEnter={onDragEnter}
                onDragLeave={onDragExit}
                onDrop={onDropEvent}
                onDragEnd={stopPropagation}
            >
                {dragLabel || 'Glissez-déposer un fichier ici pour importer un fichier .KML ou .KMZ'}
            </div>
        ),
    };
};
