import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconExport } from '../../../assets/icons/export.svg';
import { PopoverMenu } from '@components/navigation/PopoverMenu/PopoverMenu';
import { SquareButton } from '@components/Button/SquareButton/SquareButton';
import { ExportPopin } from '@components/Requests/RequestAdd/ExportPopin';

const renderButton = ({ onClick }) => (
    <SquareButton onClick={onClick} size="small" variant="contained" color={'secondary'} startIcon={<IconExport />}>
        <FormattedMessage id="requests.actions.export" />
    </SquareButton>
);

export const ExportRequests = memo(function ExportRequests() {
    return <PopoverMenu renderButton={renderButton}>{({ close }) => <ExportPopin onClose={close} />}</PopoverMenu>;
});
