import React, { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { errorToastConfig, successToastConfig } from '../../config/constants';
import { ROUTE_FORGOTPASSWORD, ROUTE_LOGIN } from '../../routes';
import { LoginPageLink, UnlockAccountPage } from '@innovatm/js-iam';
import { AuthContext } from '../../contexts/AuthContext.selectors';
import { Box, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';

const logoImg = require('../../assets/images/InnovATM_AirportKeeper-Web-dark.png');

export const StyledRoot = styled(Box)`
    display: flex;
    overflow: hidden;
    height: 100%;
`;
export const StyledLeftSide = styled(Box)`
    width: 50%;
    background: #106ebb;
`;
export const StyledRightSide = styled(Box)`
    display: flex;
    justify-content: left;
    align-items: center;
    width: 50%;
    padding-left: 4%;
    background: #0c456c;
`;

const StyledLogo = styled(Box)`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    img {
        margin: auto 12% auto auto;
        width: 370px;
    }
`;

export const UnlockAccountRoute = memo(function UnlockAccountRoute() {
    const intl = useIntl();

    const { search } = useLocation();
    const token = React.useMemo(() => {
        const params = new URLSearchParams(search);
        return params.get('token');
    }, [search]);

    const onSuccess = useCallback(() => {
        toast.error(<FormattedMessage id={'pages.unlock-account.success'} />, {
            ...successToastConfig,
        });
    }, []);
    const onError = useCallback(() => {
        toast.error(<FormattedMessage id={'pages.unlock-account.error'} />, {
            ...errorToastConfig,
        });
    }, []);

    return (
        <StyledRoot>
            <StyledLeftSide>
                <StyledLogo>
                    <img alt={'Logo'} src={logoImg} />
                </StyledLogo>
            </StyledLeftSide>
            <StyledRightSide>
                <UnlockAccountPage
                    authContext={AuthContext as any}
                    token={token}
                    onSuccess={onSuccess}
                    onError={onError}
                    links={
                        <>
                            <LoginPageLink to={ROUTE_LOGIN}>
                                {intl.formatMessage({ id: 'pages.unlock-account.link-login' })}
                            </LoginPageLink>
                            <LoginPageLink to={ROUTE_FORGOTPASSWORD}>
                                {intl.formatMessage({ id: 'pages.unlock-account.link-reset-password' })}
                            </LoginPageLink>
                        </>
                    }
                />
            </StyledRightSide>
        </StyledRoot>
    );
});
