import React, { memo, useCallback } from 'react';
import { useSetUi } from '../../contexts/UiContext';
import { Box, styled } from '@mui/material';
import { ReactComponent as ClosePopup } from '../../assets/icons/close-popup.svg';

const CloseStrip = styled(Box)`
    position: absolute;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    cursor: pointer;
`;

export const ScheduleCloseStrip = memo(function ScheduleCloseStrip({ onClick }: { onClick?: () => void }) {
    const setSelectedFlightId = useSetUi('selectedFlightId');
    const setSelectedSlotId = useSetUi('selectedSlotId');

    const onClose = useCallback(() => {
        onClick?.();
        setSelectedSlotId(null);
        setSelectedFlightId(null);
    }, [onClick, setSelectedFlightId, setSelectedSlotId]);

    return (
        <CloseStrip>
            <ClosePopup onClick={onClose} />
        </CloseStrip>
    );
});
