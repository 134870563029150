import {
    FormControl,
    FormControlProps,
    FormHelperText,
    inputBaseClasses,
    inputClasses,
    InputLabel,
    inputLabelClasses,
    menuItemClasses,
    outlinedInputClasses,
    Select as MuiSelect,
    selectClasses,
    SelectProps,
    styled,
} from '@mui/material';
import * as React from 'react';
import { memo, ReactNode } from 'react';
import { ReactComponent as DownIcon } from '../../../assets/icons/Icon_ArrowDown.svg';

const StyledFormControl = styled(FormControl)`
    min-height: 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & > .${inputLabelClasses.root} {
        transform-origin: top left;
        transform: initial;
        position: relative;
        color: ${({ theme }) => theme.palette.primary.contrastText};
        margin-bottom: 5px;
        flex-shrink: 0;

        &.${inputLabelClasses.focused} {
            color: ${({ theme }) => theme.palette.primary.contrastText};
            font-weight: normal;
        }
    }
    & .${inputBaseClasses.root} {
        margin-top: 0;
        flex-shrink: 0;
        height: 50px;
        width: 100%;
        border-radius: 25px;
        color: ${({ theme }) => theme.palette.primary.contrastText};

        & > .${selectClasses.select} {
            padding: 10px 40px 10px 15px !important;
            color: currentColor;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 18px;
            box-sizing: border-box;
        }

        & > .${selectClasses.icon} {
            color: inherit;
            margin-right: 10px;
            pointer-events: none;
        }
        &.${outlinedInputClasses.root} > .${outlinedInputClasses.notchedOutline} {
            border-color: ${({ theme }) => theme.palette.blue.lightest};
            border-width: 1px;
        }
    }

    & .${inputLabelClasses.root}.${inputLabelClasses.disabled}, & .${inputClasses.root}.${inputClasses.disabled} {
        opacity: 0.5;
    }
`;

export const Select = memo(function Select({
    label,
    helperText,
    children,
    FormControlProps,
    ...rest
}: {
    label?: React.ReactNode;
    helperText?: React.ReactNode;
    children: ReactNode;
    FormControlProps?: Partial<FormControlProps>;
} & SelectProps) {
    return (
        <StyledFormControl disabled={rest.disabled} {...FormControlProps}>
            {label && (
                <InputLabel shrink htmlFor={`${rest.name}`}>
                    {label}
                </InputLabel>
            )}
            <MuiSelect
                {...rest}
                IconComponent={DownIcon}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            [`& .${menuItemClasses.root}`]: {
                                color: theme => theme.palette.primary.contrastText,
                            },
                        },
                    },
                }}
            >
                {children}
            </MuiSelect>
            {helperText && (
                <FormHelperText sx={{ marginLeft: 0 }} error={rest.error}>
                    {helperText}
                </FormHelperText>
            )}
        </StyledFormControl>
    );
});
