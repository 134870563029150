import React, { memo } from 'react';
import { FormikCheckbox } from '@components/form/Checkbox/FormikCheckbox';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { styled } from '@mui/material';
import { useUiValue } from '../../../../contexts/UiContext';

const StyledSchedulePropagateBox = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
`;

export const ResourcesFormPropagateCheckbox = memo(() => {
    const selectedDate = useUiValue('selectedDate');

    return (
        <StyledSchedulePropagateBox>
            <FormikCheckbox
                name="propagate"
                label={
                    <FormattedMessage
                        id="resourcesForm.propagateResources"
                        values={{ propagationDay: <FormattedDate value={selectedDate} weekday="long" /> }}
                    />
                }
            />
        </StyledSchedulePropagateBox>
    );
});
