import { DaySlotResourcesAllocationDto } from '@types';
import { Formik } from 'formik';
import { Popin, PopinCloseButton } from '@components/navigation/Popin/Popin';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import { FormikSelect } from '@components/form/Select/FormikSelect';
import { Radio, RadioGroupField } from '@components/form/RadioGroup/RadioGroup';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import React, { memo, useCallback } from 'react';
import { MenuItem } from '@mui/material';
import { ReactComponent as AllocateIcon } from '../../../assets/icons/icon-allocate.svg';
import { useCurrentUserResources } from '../../../contexts/AuthContext.selectors';
import { FormikProps } from 'formik/dist/types';

export const ResourcesAllocateForm = memo(function ResourcesAllocationForm({
    onClose,
    onSubmit,
    isLoading,
    initialValues,
}: {
    onClose: () => void;
    onSubmit: (values: DaySlotResourcesAllocationDto) => void;
    isLoading: boolean;
    initialValues: { day: string; resourceType: string; allocateFromScratch: boolean };
}) {
    const clientResources = useCurrentUserResources();

    const renderForm = useCallback(
        ({ handleSubmit }: FormikProps<DaySlotResourcesAllocationDto>) => (
            <Popin
                width={700}
                title={
                    <>
                        <AllocateIcon />
                        &ensp;
                        <Box flex={1}>
                            <FormattedMessage id="schedule.allocate.title" />
                        </Box>
                        <PopinCloseButton onClose={onClose} />
                    </>
                }
                content={
                    <>
                        <div>
                            <FormikSelect
                                name={'resourceType'}
                                label={<FormattedMessage id={'schedule.allocate.resourceType'} />}
                            >
                                {clientResources?.map(({ type }) => (
                                    <MenuItem key={type} value={type}>
                                        <FormattedMessage id={`ResourceType.${type}`} />
                                    </MenuItem>
                                ))}
                            </FormikSelect>
                        </div>
                        <div>
                            <RadioGroupField name={'allocateFromScratch'}>
                                <Radio value={false}>
                                    <FormattedMessage id="schedule.allocate.partial" />
                                </Radio>
                                <Radio value={true}>
                                    <FormattedMessage id="schedule.allocate.global" />
                                </Radio>
                            </RadioGroupField>
                        </div>
                    </>
                }
                actions={
                    <>
                        <RoundButton color="primary" size="medium" variant="outlined" onClick={onClose}>
                            <FormattedMessage id="schedule.allocate.cancel" />
                        </RoundButton>
                        &emsp;
                        <RoundButton
                            color="primary"
                            size="medium"
                            variant="contained"
                            onClick={() => handleSubmit()}
                            loading={isLoading}
                            disabled={isLoading}
                        >
                            <FormattedMessage id="schedule.allocate.confirm" />
                        </RoundButton>
                    </>
                }
            />
        ),
        [clientResources, isLoading, onClose],
    );

    return (
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
            {renderForm}
        </Formik>
    );
});
