import { ImpactStatus } from '@models';
import { styled } from '@mui/material';

export const StripImpactStatusBadge = styled('div', {
    shouldForwardProp: () => false,
})<{ impactStatus: ImpactStatus }>(({ impactStatus, theme }) => ({
    width: 16,
    height: 16,
    overflow: 'hidden',
    borderRadius: '100%',
    flexShrink: 0,
    backgroundColor: theme.palette.impactStatus[impactStatus].main,
}));
