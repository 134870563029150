import { Strip } from '@components/Strip/Strip';
import { ModificationDemandDto } from '@models';
import { Box } from '@mui/material';
import React, { memo, useLayoutEffect } from 'react';
import { StripMenuRequestPage } from '@components/Requests/StripMenuRequestPage';

export const RequestSlot = memo(function RequestSlot({
    requestSlot,
    scrollOnLoad,
}: {
    requestSlot: ModificationDemandDto;
    scrollOnLoad?: boolean;
}) {
    const ref = React.useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        if (scrollOnLoad && ref.current) {
            ref.current.scrollIntoView({ block: 'center', inline: 'center' });
        }
    }, [scrollOnLoad]);

    return (
        <Box ref={ref} display="flex" alignItems={'flex-start'} marginBottom={4}>
            <Strip
                forceDisplayStatus
                dimCompleted
                highlighted={scrollOnLoad}
                slotStripDto={requestSlot}
                demandStatus={requestSlot.status}
                renderStripMenu={props => (
                    <StripMenuRequestPage
                        {...props}
                        slot={requestSlot}
                        hasPending={requestSlot.nbPending > 0}
                        status={requestSlot.status}
                        updatedVersions={requestSlot.updatedVersions}
                    />
                )}
            />
        </Box>
    );
});
