import { StripMenu } from '@components/Strip/StripMenu/StripMenu';
import React, { memo } from 'react';
import { StripMenuItemConfirmAllOccurrences } from '@components/Strip/StripMenu/StripMenuItemConfirmAllOccurrences';
import { StripMenuItemSeeRequestView } from '@components/Strip/StripMenu/StripMenuItemSeeRequestView';
import { StripMenuItemModifyAllOccurrences } from '@components/Strip/StripMenu/StripMenuItemModifyAllOccurrences';
import { StripMenuItemSplitArrDep } from '@components/Strip/StripMenu/StripMenuItemSplitArrDep';
import { StripMenuItemCancelOccurrences } from '@components/Strip/StripMenu/StripMenuItemCancelOccurrences';
import { StripMenuItemAssociation } from '@components/Strip/StripMenu/StripMenuItemAssociation';
import { useBoolean } from '../../hooks/useBoolean';
import { ModifySlot } from '@components/ModifySlot/ModifySlot';
import { StripMenuItemSeeUpdateHistory } from '@components/Strip/StripMenu/StripMenuItemSeeUpdateHistory';
import { SlotHistory } from '@components/SlotHistory/SlotHistory';
import { Divider } from '@mui/material';
import { StripMenuItemAssociationDayOnly } from '@components/Strip/StripMenu/StripMenuItemAssociationDayOnly';
import { GanttStripDto } from '@types';
import { CancelSlot } from '@components/Occurences/CancelPopin/CancelSlot';

export const StripMenuSchedulePage = memo(
    ({
        slot,
        disabled,
        hasPending,
        onClickAssociate,
        onClickAssociateDayOnly,
    }: {
        slot: GanttStripDto;
        disabled?: boolean;
        hasPending?: boolean;
        onClickAssociate?: () => void;
        onClickAssociateDayOnly?: () => void;
    }) => {
        const {
            value: isModifySlotPopinOpened,
            setFalse: closeModifySlotPopin,
            setTrue: openModifySlotPopin,
        } = useBoolean();

        const {
            value: isCancelSlotPopinOpened,
            setFalse: closeCancelSlotPopin,
            setTrue: openCancelSlotPopin,
        } = useBoolean();

        const {
            value: isSlotHistoryPopinOpened,
            setFalse: closeSlotHistoryPopin,
            setTrue: openSlotHistoryPopin,
        } = useBoolean();

        if (!slot) {
            return null;
        }

        return (
            <>
                <StripMenu disabled={disabled}>
                    <StripMenuItemSeeRequestView slot={slot} />
                    <StripMenuItemSeeUpdateHistory onClick={openSlotHistoryPopin} />
                    <Divider />
                    {hasPending && <StripMenuItemConfirmAllOccurrences slot={slot} />}
                    <StripMenuItemModifyAllOccurrences onClick={openModifySlotPopin} />
                    <StripMenuItemCancelOccurrences onClick={openCancelSlotPopin} />
                    <StripMenuItemSplitArrDep slot={slot} />
                    <StripMenuItemAssociation slot={slot} onClick={onClickAssociate} />
                    <StripMenuItemAssociationDayOnly slot={slot} onClick={onClickAssociateDayOnly} />
                </StripMenu>

                {isModifySlotPopinOpened && <ModifySlot slot={slot} onClose={closeModifySlotPopin} />}
                {isCancelSlotPopinOpened && <CancelSlot slot={slot} onClose={closeCancelSlotPopin} />}
                {isSlotHistoryPopinOpened && <SlotHistory slotId={slot.id!} onClose={closeSlotHistoryPopin} />}
            </>
        );
    },
);
