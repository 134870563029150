import React, { memo } from 'react';
import { ListItemText, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { usePopoverValue } from '@components/navigation/PopoverMenu/PopoverMenu';

export const StripMenuItemModifyAllOccurrences = memo(function StripMenuItemModifyAllOccurrences({
    onClick,
}: {
    onClick: () => void;
}) {
    const close = usePopoverValue('close');

    return (
        <>
            <MenuItem
                onClick={() => {
                    close();
                    onClick();
                }}
            >
                <ListItemText>
                    <FormattedMessage id="strip.actions.modifyAllOccurrences" />
                </ListItemText>
            </MenuItem>
        </>
    );
});
