import { Box, styled } from '@mui/material';
import React, { memo, useEffect, useMemo } from 'react';
import { SchedulePageNominal } from './SchedulePageNominal';
import { SchedulePageAssociation } from './SchedulePageAssociation';
import { SchedulePageAllocation } from './SchedulePageAllocation';
import { ScheduleContext, scheduleInitialState, ScheduleModes, ScheduleState } from './ScheduleContext';
import { useOptimizedState } from '../../../hooks/useOptimizedState';
import { useParams } from 'react-router-dom';
import { parse } from 'date-fns';
import { ganttDayPathFormat } from '@components/Schedule/Gantt/gantt.constants';
import { useSetUi } from '../../../contexts/UiContext';

const StyledBox = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const SchedulePage = memo(function SchedulePage() {
    const dateParam = useParams<{ date?: string }>()?.date;
    const selectedDate = useMemo(
        () => (dateParam ? parse(dateParam, ganttDayPathFormat, new Date()) : new Date()),
        [dateParam],
    );
    const setSelectedDate = useSetUi('selectedDate');
    useEffect(() => {
        setSelectedDate(selectedDate);
    }, [setSelectedDate, selectedDate]);

    const [scheduleState, setScheduleState] = useOptimizedState<ScheduleState>(scheduleInitialState);

    return (
        <ScheduleContext.Provider
            value={{
                state: scheduleState,
                setState: setScheduleState,
            }}
        >
            <StyledBox>
                {scheduleState.mode === ScheduleModes.NOMINAL && (
                    <SchedulePageNominal
                        selectedDate={selectedDate}
                        onClickStartAssociating={() =>
                            setScheduleState(s => ({ ...s, mode: ScheduleModes.ASSOCIATION, dayOnly: false }))
                        }
                        onClickStartAssociatingDayOnly={() =>
                            setScheduleState(s => ({ ...s, mode: ScheduleModes.ASSOCIATION, dayOnly: true }))
                        }
                    />
                )}

                {scheduleState.mode === ScheduleModes.ASSOCIATION && (
                    <SchedulePageAssociation
                        selectedDate={selectedDate}
                        onClickStopAssociating={() => setScheduleState(s => ({ ...s, mode: ScheduleModes.NOMINAL }))}
                        dayOnly={scheduleState.dayOnly}
                    />
                )}

                {scheduleState.mode === ScheduleModes.ALLOCATION && (
                    <SchedulePageAllocation selectedDate={selectedDate} allocationUpdateData={scheduleState.modeData} />
                )}
            </StyledBox>
        </ScheduleContext.Provider>
    );
});
