/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GanttDto } from '../models';
import { GanttStripDto } from '../models';
import { ScheduleSlotUpdateDto } from '../models';
import { SlotUpdateResultDto } from '../models';
import { UpdatePeriodDto } from '../models';
import { Configuration, ConfigurationParameters } from '../configuration';
import { BASE_PATH, BaseAPI, RequestArgs } from '../base';
import { AxiosInstance, AxiosPromise, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, UseMutationOptions, useMutation, UseQueryOptions } from 'react-query';
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setSearchParams,
    toPathString,
    globalAxios,
    setBearerAuthToObject,
} from '../common';
import { useAppAuthValue } from '../../utils';
export const SlotWebServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        associateSlotOccurrences: async (
            day: string,
            arr: string,
            dep: string,
            onCurrentDayOnly?: boolean,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'day' is not null or undefined
            assertParamExists('associateSlotOccurrences', 'day', day);
            // verify required parameter 'arr' is not null or undefined
            assertParamExists('associateSlotOccurrences', 'arr', arr);
            // verify required parameter 'dep' is not null or undefined
            assertParamExists('associateSlotOccurrences', 'dep', dep);
            const localVarPath = `/slot/associate/{day}`.replace(`{${'day'}}`, encodeURIComponent(String(day)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (arr !== undefined) {
                localVarQueryParameter['arr'] = arr;
            }
            if (dep !== undefined) {
                localVarQueryParameter['dep'] = dep;
            }
            if (onCurrentDayOnly !== undefined) {
                localVarQueryParameter['onCurrentDayOnly'] = onCurrentDayOnly;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        cancelSlotOccurrences: async (
            id: string,
            updatePeriodDto: UpdatePeriodDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelSlotOccurrences', 'id', id);
            // verify required parameter 'updatePeriodDto' is not null or undefined
            assertParamExists('cancelSlotOccurrences', 'updatePeriodDto', updatePeriodDto);
            const localVarPath = `/slot/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(updatePeriodDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        confirmSlotOccurrences: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('confirmSlotOccurrences', 'id', id);
            const localVarPath = `/slot/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        createSlot: async (
            scheduleSlotUpdateDto: ScheduleSlotUpdateDto,
            confirm?: boolean,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'scheduleSlotUpdateDto' is not null or undefined
            assertParamExists('createSlot', 'scheduleSlotUpdateDto', scheduleSlotUpdateDto);
            const localVarPath = `/slot/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (confirm !== undefined) {
                localVarQueryParameter['confirm'] = confirm;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                scheduleSlotUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getDayGanttTurnarounds: async (day: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'day' is not null or undefined
            assertParamExists('getDayGanttTurnarounds', 'day', day);
            const localVarPath = `/gantt/day/{day}`.replace(`{${'day'}}`, encodeURIComponent(String(day)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getGanttTurnaroundsToAssociate: async (day: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'day' is not null or undefined
            assertParamExists('getGanttTurnaroundsToAssociate', 'day', day);
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGanttTurnaroundsToAssociate', 'id', id);
            const localVarPath = `/gantt/day/{day}/association`.replace(`{${'day'}}`, encodeURIComponent(String(day)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getSlotStrip: async (slotId?: string, turnaroundId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/slot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (slotId !== undefined) {
                localVarQueryParameter['slotId'] = slotId;
            }
            if (turnaroundId !== undefined) {
                localVarQueryParameter['turnaroundId'] = turnaroundId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        splitSlotOccurrences: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('splitSlotOccurrences', 'id', id);
            const localVarPath = `/slot/split`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        updateSlotOccurrences: async (
            id: string,
            scheduleSlotUpdateDto: ScheduleSlotUpdateDto,
            confirm?: boolean,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSlotOccurrences', 'id', id);
            // verify required parameter 'scheduleSlotUpdateDto' is not null or undefined
            assertParamExists('updateSlotOccurrences', 'scheduleSlotUpdateDto', scheduleSlotUpdateDto);
            const localVarPath = `/slot/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }
            if (confirm !== undefined) {
                localVarQueryParameter['confirm'] = confirm;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                scheduleSlotUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
export const SlotWebServicesApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = SlotWebServicesApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} day
         * @param {string} arr
         * @param {string} dep
         * @param {boolean} [onCurrentDayOnly]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async associateSlotOccurrences(
            day: string,
            arr: string,
            dep: string,
            onCurrentDayOnly?: boolean,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotUpdateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.associateSlotOccurrences(
                day,
                arr,
                dep,
                onCurrentDayOnly,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {UpdatePeriodDto} updatePeriodDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSlotOccurrences(
            id: string,
            updatePeriodDto: UpdatePeriodDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotUpdateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSlotOccurrences(
                id,
                updatePeriodDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmSlotOccurrences(
            id: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotUpdateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmSlotOccurrences(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {ScheduleSlotUpdateDto} scheduleSlotUpdateDto
         * @param {boolean} [confirm]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSlot(
            scheduleSlotUpdateDto: ScheduleSlotUpdateDto,
            confirm?: boolean,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotUpdateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSlot(
                scheduleSlotUpdateDto,
                confirm,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} day
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayGanttTurnarounds(
            day: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayGanttTurnarounds(day, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} day
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGanttTurnaroundsToAssociate(
            day: string,
            id: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GanttDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGanttTurnaroundsToAssociate(day, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [slotId]
         * @param {string} [turnaroundId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlotStrip(
            slotId?: string,
            turnaroundId?: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GanttStripDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlotStrip(slotId, turnaroundId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async splitSlotOccurrences(
            id: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotUpdateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.splitSlotOccurrences(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {ScheduleSlotUpdateDto} scheduleSlotUpdateDto
         * @param {boolean} [confirm]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSlotOccurrences(
            id: string,
            scheduleSlotUpdateDto: ScheduleSlotUpdateDto,
            confirm?: boolean,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotUpdateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSlotOccurrences(
                id,
                scheduleSlotUpdateDto,
                confirm,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

export const SlotWebServicesApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = SlotWebServicesApiFp(configuration);
    return {
        associateSlotOccurrences(
            day: string,
            arr: string,
            dep: string,
            onCurrentDayOnly?: boolean,
            options?: any,
        ): AxiosPromise<SlotUpdateResultDto> {
            return localVarFp
                .associateSlotOccurrences(day, arr, dep, onCurrentDayOnly, options)
                .then(request => request(axios, basePath));
        },
        cancelSlotOccurrences(
            id: string,
            updatePeriodDto: UpdatePeriodDto,
            options?: any,
        ): AxiosPromise<SlotUpdateResultDto> {
            return localVarFp
                .cancelSlotOccurrences(id, updatePeriodDto, options)
                .then(request => request(axios, basePath));
        },
        confirmSlotOccurrences(id: string, options?: any): AxiosPromise<SlotUpdateResultDto> {
            return localVarFp.confirmSlotOccurrences(id, options).then(request => request(axios, basePath));
        },
        createSlot(
            scheduleSlotUpdateDto: ScheduleSlotUpdateDto,
            confirm?: boolean,
            options?: any,
        ): AxiosPromise<SlotUpdateResultDto> {
            return localVarFp
                .createSlot(scheduleSlotUpdateDto, confirm, options)
                .then(request => request(axios, basePath));
        },
        getDayGanttTurnarounds(day: string, options?: any): AxiosPromise<GanttDto> {
            return localVarFp.getDayGanttTurnarounds(day, options).then(request => request(axios, basePath));
        },
        getGanttTurnaroundsToAssociate(day: string, id: string, options?: any): AxiosPromise<GanttDto> {
            return localVarFp
                .getGanttTurnaroundsToAssociate(day, id, options)
                .then(request => request(axios, basePath));
        },
        getSlotStrip(slotId?: string, turnaroundId?: string, options?: any): AxiosPromise<GanttStripDto> {
            return localVarFp.getSlotStrip(slotId, turnaroundId, options).then(request => request(axios, basePath));
        },
        splitSlotOccurrences(id: string, options?: any): AxiosPromise<SlotUpdateResultDto> {
            return localVarFp.splitSlotOccurrences(id, options).then(request => request(axios, basePath));
        },
        updateSlotOccurrences(
            id: string,
            scheduleSlotUpdateDto: ScheduleSlotUpdateDto,
            confirm?: boolean,
            options?: any,
        ): AxiosPromise<SlotUpdateResultDto> {
            return localVarFp
                .updateSlotOccurrences(id, scheduleSlotUpdateDto, confirm, options)
                .then(request => request(axios, basePath));
        },
    };
};
export interface SlotWebServicesApiAssociateSlotOccurrencesRequest {
    readonly day: string;

    readonly arr: string;

    readonly dep: string;

    readonly onCurrentDayOnly?: boolean;
}

export interface SlotWebServicesApiCancelSlotOccurrencesRequest {
    readonly id: string;

    readonly updatePeriodDto: UpdatePeriodDto;
}

export interface SlotWebServicesApiConfirmSlotOccurrencesRequest {
    readonly id: string;
}

export interface SlotWebServicesApiCreateSlotRequest {
    readonly scheduleSlotUpdateDto: ScheduleSlotUpdateDto;

    readonly confirm?: boolean;
}

export interface SlotWebServicesApiGetDayGanttTurnaroundsRequest {
    readonly day: string;
}

export interface SlotWebServicesApiGetGanttTurnaroundsToAssociateRequest {
    readonly day: string;

    readonly id: string;
}

export interface SlotWebServicesApiGetSlotStripRequest {
    readonly slotId?: string;

    readonly turnaroundId?: string;
}

export interface SlotWebServicesApiSplitSlotOccurrencesRequest {
    readonly id: string;
}

export interface SlotWebServicesApiUpdateSlotOccurrencesRequest {
    readonly id: string;

    readonly scheduleSlotUpdateDto: ScheduleSlotUpdateDto;

    readonly confirm?: boolean;
}

export class SlotWebServicesApi extends BaseAPI {
    public associateSlotOccurrences(
        requestParameters: SlotWebServicesApiAssociateSlotOccurrencesRequest,
        options?: any,
    ) {
        return SlotWebServicesApiFp(this.configuration)
            .associateSlotOccurrences(
                requestParameters.day,
                requestParameters.arr,
                requestParameters.dep,
                requestParameters.onCurrentDayOnly,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }
    public cancelSlotOccurrences(requestParameters: SlotWebServicesApiCancelSlotOccurrencesRequest, options?: any) {
        return SlotWebServicesApiFp(this.configuration)
            .cancelSlotOccurrences(requestParameters.id, requestParameters.updatePeriodDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public confirmSlotOccurrences(requestParameters: SlotWebServicesApiConfirmSlotOccurrencesRequest, options?: any) {
        return SlotWebServicesApiFp(this.configuration)
            .confirmSlotOccurrences(requestParameters.id, options)
            .then(request => request(this.axios, this.basePath));
    }
    public createSlot(requestParameters: SlotWebServicesApiCreateSlotRequest, options?: any) {
        return SlotWebServicesApiFp(this.configuration)
            .createSlot(requestParameters.scheduleSlotUpdateDto, requestParameters.confirm, options)
            .then(request => request(this.axios, this.basePath));
    }
    public getDayGanttTurnarounds(requestParameters: SlotWebServicesApiGetDayGanttTurnaroundsRequest, options?: any) {
        return SlotWebServicesApiFp(this.configuration)
            .getDayGanttTurnarounds(requestParameters.day, options)
            .then(request => request(this.axios, this.basePath));
    }
    public getGanttTurnaroundsToAssociate(
        requestParameters: SlotWebServicesApiGetGanttTurnaroundsToAssociateRequest,
        options?: any,
    ) {
        return SlotWebServicesApiFp(this.configuration)
            .getGanttTurnaroundsToAssociate(requestParameters.day, requestParameters.id, options)
            .then(request => request(this.axios, this.basePath));
    }
    public getSlotStrip(requestParameters: SlotWebServicesApiGetSlotStripRequest = {}, options?: any) {
        return SlotWebServicesApiFp(this.configuration)
            .getSlotStrip(requestParameters.slotId, requestParameters.turnaroundId, options)
            .then(request => request(this.axios, this.basePath));
    }
    public splitSlotOccurrences(requestParameters: SlotWebServicesApiSplitSlotOccurrencesRequest, options?: any) {
        return SlotWebServicesApiFp(this.configuration)
            .splitSlotOccurrences(requestParameters.id, options)
            .then(request => request(this.axios, this.basePath));
    }
    public updateSlotOccurrences(requestParameters: SlotWebServicesApiUpdateSlotOccurrencesRequest, options?: any) {
        return SlotWebServicesApiFp(this.configuration)
            .updateSlotOccurrences(
                requestParameters.id,
                requestParameters.scheduleSlotUpdateDto,
                requestParameters.confirm,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }
}

// custom hook generation here
export type PostAssociateSlotOccurrencesResponse = AxiosResponse<SlotUpdateResultDto>;
export const KeyPostAssociateSlotOccurrences = 'PostAssociateSlotOccurrences';
export const useMutationPostAssociateSlotOccurrences = (
    options?: UseMutationOptions<
        PostAssociateSlotOccurrencesResponse,
        AxiosError,
        SlotWebServicesApiAssociateSlotOccurrencesRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostAssociateSlotOccurrencesResponse,
        AxiosError,
        SlotWebServicesApiAssociateSlotOccurrencesRequest
    >(
        (args: SlotWebServicesApiAssociateSlotOccurrencesRequest) =>
            new SlotWebServicesApi(new Configuration({ accessToken })).associateSlotOccurrences(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostAssociateSlotOccurrences = (
    args: SlotWebServicesApiAssociateSlotOccurrencesRequest,
    options?: UseQueryOptions<PostAssociateSlotOccurrencesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostAssociateSlotOccurrencesResponse, AxiosError>(
        [KeyPostAssociateSlotOccurrences, args, options && options.queryKey],
        () =>
            new SlotWebServicesApi(new Configuration({ accessToken, ...conf })).associateSlotOccurrences(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostCancelSlotOccurrencesResponse = AxiosResponse<SlotUpdateResultDto>;
export const KeyPostCancelSlotOccurrences = 'PostCancelSlotOccurrences';
export const useMutationPostCancelSlotOccurrences = (
    options?: UseMutationOptions<
        PostCancelSlotOccurrencesResponse,
        AxiosError,
        SlotWebServicesApiCancelSlotOccurrencesRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostCancelSlotOccurrencesResponse,
        AxiosError,
        SlotWebServicesApiCancelSlotOccurrencesRequest
    >(
        (args: SlotWebServicesApiCancelSlotOccurrencesRequest) =>
            new SlotWebServicesApi(new Configuration({ accessToken })).cancelSlotOccurrences(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostCancelSlotOccurrences = (
    args: SlotWebServicesApiCancelSlotOccurrencesRequest,
    options?: UseQueryOptions<PostCancelSlotOccurrencesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostCancelSlotOccurrencesResponse, AxiosError>(
        [KeyPostCancelSlotOccurrences, args, options && options.queryKey],
        () =>
            new SlotWebServicesApi(new Configuration({ accessToken, ...conf })).cancelSlotOccurrences(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostConfirmSlotOccurrencesResponse = AxiosResponse<SlotUpdateResultDto>;
export const KeyPostConfirmSlotOccurrences = 'PostConfirmSlotOccurrences';
export const useMutationPostConfirmSlotOccurrences = (
    options?: UseMutationOptions<
        PostConfirmSlotOccurrencesResponse,
        AxiosError,
        SlotWebServicesApiConfirmSlotOccurrencesRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostConfirmSlotOccurrencesResponse,
        AxiosError,
        SlotWebServicesApiConfirmSlotOccurrencesRequest
    >(
        (args: SlotWebServicesApiConfirmSlotOccurrencesRequest) =>
            new SlotWebServicesApi(new Configuration({ accessToken })).confirmSlotOccurrences(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostConfirmSlotOccurrences = (
    args: SlotWebServicesApiConfirmSlotOccurrencesRequest,
    options?: UseQueryOptions<PostConfirmSlotOccurrencesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostConfirmSlotOccurrencesResponse, AxiosError>(
        [KeyPostConfirmSlotOccurrences, args, options && options.queryKey],
        () =>
            new SlotWebServicesApi(new Configuration({ accessToken, ...conf })).confirmSlotOccurrences(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostCreateSlotResponse = AxiosResponse<SlotUpdateResultDto>;
export const KeyPostCreateSlot = 'PostCreateSlot';
export const useMutationPostCreateSlot = (
    options?: UseMutationOptions<PostCreateSlotResponse, AxiosError, SlotWebServicesApiCreateSlotRequest>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<PostCreateSlotResponse, AxiosError, SlotWebServicesApiCreateSlotRequest>(
        (args: SlotWebServicesApiCreateSlotRequest) =>
            new SlotWebServicesApi(new Configuration({ accessToken })).createSlot(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostCreateSlot = (
    args: SlotWebServicesApiCreateSlotRequest,
    options?: UseQueryOptions<PostCreateSlotResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostCreateSlotResponse, AxiosError>(
        [KeyPostCreateSlot, args, options && options.queryKey],
        () => new SlotWebServicesApi(new Configuration({ accessToken, ...conf })).createSlot(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetDayGanttTurnaroundsResponse = AxiosResponse<GanttDto>;
export const KeyGetGetDayGanttTurnarounds = 'GetGetDayGanttTurnarounds';
export const useMutationGetGetDayGanttTurnarounds = (
    options?: UseMutationOptions<
        GetGetDayGanttTurnaroundsResponse,
        AxiosError,
        SlotWebServicesApiGetDayGanttTurnaroundsRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        GetGetDayGanttTurnaroundsResponse,
        AxiosError,
        SlotWebServicesApiGetDayGanttTurnaroundsRequest
    >(
        (args: SlotWebServicesApiGetDayGanttTurnaroundsRequest) =>
            new SlotWebServicesApi(new Configuration({ accessToken })).getDayGanttTurnarounds(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetDayGanttTurnarounds = (
    args: SlotWebServicesApiGetDayGanttTurnaroundsRequest,
    options?: UseQueryOptions<GetGetDayGanttTurnaroundsResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetDayGanttTurnaroundsResponse, AxiosError>(
        [KeyGetGetDayGanttTurnarounds, args, options && options.queryKey],
        () =>
            new SlotWebServicesApi(new Configuration({ accessToken, ...conf })).getDayGanttTurnarounds(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetGanttTurnaroundsToAssociateResponse = AxiosResponse<GanttDto>;
export const KeyGetGetGanttTurnaroundsToAssociate = 'GetGetGanttTurnaroundsToAssociate';
export const useMutationGetGetGanttTurnaroundsToAssociate = (
    options?: UseMutationOptions<
        GetGetGanttTurnaroundsToAssociateResponse,
        AxiosError,
        SlotWebServicesApiGetGanttTurnaroundsToAssociateRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        GetGetGanttTurnaroundsToAssociateResponse,
        AxiosError,
        SlotWebServicesApiGetGanttTurnaroundsToAssociateRequest
    >(
        (args: SlotWebServicesApiGetGanttTurnaroundsToAssociateRequest) =>
            new SlotWebServicesApi(new Configuration({ accessToken })).getGanttTurnaroundsToAssociate(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetGanttTurnaroundsToAssociate = (
    args: SlotWebServicesApiGetGanttTurnaroundsToAssociateRequest,
    options?: UseQueryOptions<GetGetGanttTurnaroundsToAssociateResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetGanttTurnaroundsToAssociateResponse, AxiosError>(
        [KeyGetGetGanttTurnaroundsToAssociate, args, options && options.queryKey],
        () =>
            new SlotWebServicesApi(new Configuration({ accessToken, ...conf })).getGanttTurnaroundsToAssociate(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetSlotStripResponse = AxiosResponse<GanttStripDto>;
export const KeyGetGetSlotStrip = 'GetGetSlotStrip';
export const useMutationGetGetSlotStrip = (
    options?: UseMutationOptions<GetGetSlotStripResponse, AxiosError, SlotWebServicesApiGetSlotStripRequest>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetGetSlotStripResponse, AxiosError, SlotWebServicesApiGetSlotStripRequest>(
        (args: SlotWebServicesApiGetSlotStripRequest) =>
            new SlotWebServicesApi(new Configuration({ accessToken })).getSlotStrip(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetSlotStrip = (
    args: SlotWebServicesApiGetSlotStripRequest,
    options?: UseQueryOptions<GetGetSlotStripResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetSlotStripResponse, AxiosError>(
        [KeyGetGetSlotStrip, args, options && options.queryKey],
        () => new SlotWebServicesApi(new Configuration({ accessToken, ...conf })).getSlotStrip(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostSplitSlotOccurrencesResponse = AxiosResponse<SlotUpdateResultDto>;
export const KeyPostSplitSlotOccurrences = 'PostSplitSlotOccurrences';
export const useMutationPostSplitSlotOccurrences = (
    options?: UseMutationOptions<
        PostSplitSlotOccurrencesResponse,
        AxiosError,
        SlotWebServicesApiSplitSlotOccurrencesRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostSplitSlotOccurrencesResponse,
        AxiosError,
        SlotWebServicesApiSplitSlotOccurrencesRequest
    >(
        (args: SlotWebServicesApiSplitSlotOccurrencesRequest) =>
            new SlotWebServicesApi(new Configuration({ accessToken })).splitSlotOccurrences(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostSplitSlotOccurrences = (
    args: SlotWebServicesApiSplitSlotOccurrencesRequest,
    options?: UseQueryOptions<PostSplitSlotOccurrencesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostSplitSlotOccurrencesResponse, AxiosError>(
        [KeyPostSplitSlotOccurrences, args, options && options.queryKey],
        () =>
            new SlotWebServicesApi(new Configuration({ accessToken, ...conf })).splitSlotOccurrences(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostUpdateSlotOccurrencesResponse = AxiosResponse<SlotUpdateResultDto>;
export const KeyPostUpdateSlotOccurrences = 'PostUpdateSlotOccurrences';
export const useMutationPostUpdateSlotOccurrences = (
    options?: UseMutationOptions<
        PostUpdateSlotOccurrencesResponse,
        AxiosError,
        SlotWebServicesApiUpdateSlotOccurrencesRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostUpdateSlotOccurrencesResponse,
        AxiosError,
        SlotWebServicesApiUpdateSlotOccurrencesRequest
    >(
        (args: SlotWebServicesApiUpdateSlotOccurrencesRequest) =>
            new SlotWebServicesApi(new Configuration({ accessToken })).updateSlotOccurrences(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostUpdateSlotOccurrences = (
    args: SlotWebServicesApiUpdateSlotOccurrencesRequest,
    options?: UseQueryOptions<PostUpdateSlotOccurrencesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostUpdateSlotOccurrencesResponse, AxiosError>(
        [KeyPostUpdateSlotOccurrences, args, options && options.queryKey],
        () =>
            new SlotWebServicesApi(new Configuration({ accessToken, ...conf })).updateSlotOccurrences(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
