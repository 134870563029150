/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { Box, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { ganttPxPerMs } from '@components/Schedule/Gantt/gantt.constants';
import React, { memo } from 'react';
import { lightTheme } from '../../../theme';
import { OverCapacityAlertDto } from '@models/over-capacity-alert-dto';
import { FormattedMessage, useIntl } from 'react-intl';
import { bounded } from '@components/Schedule/Gantt/gantt.utils';
import { useContext } from 'use-context-selector';
import { GanttContext } from '@components/Schedule/Gantt/GanttContext';

export const GanttOverCapacityAlert = styled<React.FC<{ from: number; to: number; left: number }>>(Box)`
    position: absolute;
    display: flex;
    width: ${({ from, to }) => (to - from) * ganttPxPerMs}px;
    height: 100%;
    background-image: repeating-linear-gradient(
        -45deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 2.9px,
        rgba(219, 67, 67, 0.4) 3px,
        rgba(219, 67, 67, 0.4) 6px
    );
    background-size: 8px 8px;
    background-position: top left;
    pointer-events: auto; // allow cursor hover
`;

export const StyledAlertTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))`
    & .${tooltipClasses.tooltip} {
        opacity: 1;
        padding: 20px;
        border-radius: ${({ theme }) => theme.shape.borderRadiusExtraLarge}px;
        background: ${lightTheme.palette.background.default};
        color: ${lightTheme.palette.primary.contrastText};
        font-family: Roboto, serif;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        box-shadow: ${({ theme }) => theme.shadows[24]};
    }
`;

export const GanttOverCapacityAlerts = memo(function GanttOverCapacityAlerts({
    alerts,
}: {
    alerts: OverCapacityAlertDto[] | undefined;
}) {
    const { from, to } = useContext(GanttContext);

    const { formatMessage } = useIntl();

    if (!alerts?.length) {
        return null;
    }

    return (
        <Box position={'absolute'} zIndex={0} height={'100%'} top={0}>
            {alerts.map(({ resourceType, resourceName, from: begin, to: end, capacity, allocated }) => (
                <StyledAlertTooltip
                    key={`${resourceType}-${resourceName}-${begin}-${end}`}
                    followCursor
                    title={
                        <FormattedMessage
                            id={'overcapacity.alertOnResource'}
                            values={{
                                resourceType: formatMessage({
                                    id: `ResourceType.${resourceType}`,
                                    defaultMessage: resourceType,
                                }),
                                resourceName,
                                capacity,
                                allocated,
                            }}
                        />
                    }
                >
                    <GanttOverCapacityAlert
                        from={bounded(begin, from, to)}
                        to={bounded(end, from, to)}
                        left={(bounded(begin, from, to) - from) * ganttPxPerMs}
                    />
                </StyledAlertTooltip>
            ))}
        </Box>
    );
});
