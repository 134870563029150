import React, { memo, useCallback } from 'react';
import { Formik } from 'formik';
import { ExportForm } from '@components/Requests/RequestAdd/ExportForm';
import {
    AirlineFlightScheduleWebServicesApiGetTokenForScheduleExportRequest,
    useMutationGetGetTokenForScheduleExport,
} from '@models';
import { format } from 'date-fns';
import { ganttDayFormat } from '@components/Schedule/Gantt/gantt.constants';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../config/constants';
import { FormattedMessage } from 'react-intl';
import { getDevRootUrl } from '../../../backend/utils';

export type ExportPopinFormValues = AirlineFlightScheduleWebServicesApiGetTokenForScheduleExportRequest & {
    exportVersion: 'current' | 'past';
    compareWith: boolean;
};

const initialValues: ExportPopinFormValues = {
    from: format(new Date(), ganttDayFormat),
    to: format(new Date(), ganttDayFormat),
    format: '',
    on: undefined,
    since: undefined,
    exportVersion: 'current',
    compareWith: false,
};

export const ExportPopin = memo(({ onClose }: { onClose: () => void }) => {
    const { mutateAsync: getTokenExport } = useMutationGetGetTokenForScheduleExport();

    const handleSubmit = useCallback(
        v => {
            const tokenExportVariables = {
                from: typeof v.from !== 'string' ? format(v.from, ganttDayFormat) : v.from,
                to: typeof v.to !== 'string' ? format(v.to, ganttDayFormat) : v.to,
                format: v.format,
                on: v.exportVersion === 'past' ? format(v.on, ganttDayFormat) : undefined,
                since: v.compareWith ? format(v.since, ganttDayFormat) : undefined,
            };
            getTokenExport(tokenExportVariables).then(
                response => {
                    // Success
                    const token = response.data;
                    const root = getDevRootUrl();
                    window.open(
                        `${root}/export/download/?token=${token}&from=${tokenExportVariables.from}&to=${
                            tokenExportVariables.to
                        }&format=${tokenExportVariables.format}${
                            tokenExportVariables.on ? '&on=' + tokenExportVariables.on : ''
                        }${tokenExportVariables.since ? '&since=' + tokenExportVariables.since : ''}`,
                        '_blank',
                    );
                    onClose();
                },
                () => {
                    // Failure
                    toast(<FormattedMessage id={'export.popin.failToast'} />, errorToastConfig);
                },
            );
        },
        [getTokenExport, onClose],
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <ExportForm onClose={onClose} />
        </Formik>
    );
});
