import React, { memo } from 'react';
import { styled, Typography } from '@mui/material';
import { StripElement } from '@components/Strip/StripElement';
import { ArrowRight } from '@components/icons/ArrowRight';

const StyledFlightNumbers = styled(StripElement)`
    min-width: 163px;
    padding-right: 15px;
    border-radius: ${({ theme }) => theme.shape.borderRadiusLarge}px 0 0
        ${({ theme }) => theme.shape.borderRadiusLarge}px;
`;

export const StripFlightNumbers = memo(function StripFlightNumbers({
    arrival,
    departure,
}: {
    arrival?: string | null;
    departure?: string | null;
}) {
    return (
        <StyledFlightNumbers>
            <Typography fontSize="16px" width="60px" textAlign="center">
                {arrival}
            </Typography>
            <Typography fontFamily="Roboto" fontWeight="bold">
                <ArrowRight />
            </Typography>
            <Typography fontSize="16px" width="60px" textAlign="center">
                {departure}
            </Typography>
        </StyledFlightNumbers>
    );
});
