import React, { memo, useCallback, useMemo } from 'react';
import { useUiValue } from '../../../contexts/UiContext';
import { DateRange } from '@mui/lab/DateRangePicker';
import { useMutationPostPropagateDayResources } from '@models';
import { toast } from 'react-toastify';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { errorToastConfig, successToastConfig } from '../../../config/constants';
import { eachDayOfInterval, format } from 'date-fns';
import { Popin, PopinCloseButton } from '@components/navigation/Popin/Popin';
import Box from '@mui/material/Box';
import { DateRangePicker } from '@components/form/DateRangePicker/DateRangePicker';
import { List, ListItem, styled, Typography } from '@mui/material';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { ganttDayFormat } from '@components/Schedule/Gantt/gantt.constants';

const StyledList = styled(List)`
    max-height: 200px;
    overflow-y: auto;
`;
const StyledRoundButton = styled(RoundButton)`
    margin-top: ${({ theme }) => theme.spacing(2)};
    justify-content: flex-end;
`;

// Generate list of weekdays in date range that are the same weekday as date of selectedDate
const processDatesRange = (dates: DateRange<Date>, selectedDate: Date) => {
    if (!dates[0] || !dates[1]) {
        return;
    }

    const allDaysInRange = eachDayOfInterval({
        start: dates[0],
        end: dates[1],
    });

    return allDaysInRange.filter(day => day.getDay() === selectedDate.getDay());
};

export const ResourcesPropagatePopin = memo(function ResourcesPropagatePopin({ onClose }: { onClose: () => void }) {
    const selectedDate = useUiValue('selectedDate');
    const [dates, setDates] = React.useState<DateRange<Date>>([null, null]);

    const currentWeekdaysInDates = useMemo(
        () => (!dates.includes(null) && processDatesRange(dates, selectedDate)) || [],
        [dates, selectedDate],
    );

    const { mutateAsync: propagateDayResources } = useMutationPostPropagateDayResources({
        onSuccess: () => {
            setDates([null, null]);
            toast(<FormattedMessage id={'schedule.propagate.successToast'} />, successToastConfig);
        },
        onError: () => {
            toast(<FormattedMessage id={'schedule.propagate.errorToast'} />, errorToastConfig);
        },
    });

    const handleChange = useCallback((dates: DateRange<Date>) => {
        if (dates.includes(null)) {
            return;
        }
        setDates(dates);
    }, []);

    const onClick = useCallback(() => {
        if (dates.includes(null)) {
            return;
        }
        propagateDayResources({
            dayResourcesPropagationDto: {
                day: format(selectedDate, ganttDayFormat),
                from: format(dates[0]!, ganttDayFormat),
                to: format(dates[1]!, ganttDayFormat),
            },
        }).then(onClose);
    }, [dates, onClose, propagateDayResources, selectedDate]);

    return (
        <Popin
            width={600}
            title={
                <>
                    <Box display="flex" flex={1}>
                        <FormattedMessage id="schedule.propagate.title" />
                    </Box>
                    <PopinCloseButton onClose={onClose} />
                </>
            }
            content={
                <>
                    <Box>
                        <DateRangePicker
                            minDate={new Date()}
                            defaultCalendarMonth={selectedDate}
                            startText={<FormattedMessage id="schedule.propagate.startDayInputText" />}
                            endText={<FormattedMessage id="schedule.propagate.endDayInputText" />}
                            separatorText={<FormattedMessage id="schedule.propagate.rangePickerSeparator" />}
                            value={dates}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box>
                        {!dates.includes(null) && (
                            <Box>
                                <Typography variant="h5" gutterBottom marginTop="10px">
                                    <FormattedMessage id="schedule.propagate.propagatingToRecap" />
                                </Typography>
                                <StyledList dense={true}>
                                    {currentWeekdaysInDates.length ? (
                                        currentWeekdaysInDates.map(day => (
                                            <ListItem key={day.getTime()}>
                                                <Typography variant="caption">
                                                    <FormattedDate
                                                        value={day}
                                                        year="numeric"
                                                        month="long"
                                                        day="2-digit"
                                                    />
                                                </Typography>
                                            </ListItem>
                                        ))
                                    ) : (
                                        <ListItem>
                                            <FormattedMessage id="schedule.propagate.noPropagatableDays" />
                                        </ListItem>
                                    )}
                                </StyledList>
                            </Box>
                        )}
                    </Box>
                    {!!currentWeekdaysInDates.length && (
                        <Box mt={2}>
                            <FormattedMessage id="schedule.propagate.propagateOverwriteWarning" />
                        </Box>
                    )}
                </>
            }
            actions={
                <>
                    <StyledRoundButton color="primary" size="medium" variant="outlined" onClick={onClose}>
                        <FormattedMessage id="schedule.propagate.cancel" />
                    </StyledRoundButton>
                    &emsp;
                    <StyledRoundButton
                        color="primary"
                        size="medium"
                        variant="contained"
                        onClick={onClick}
                        disabled={dates.includes(null) || currentWeekdaysInDates.length === 0}
                    >
                        <FormattedMessage id="schedule.propagate.confirm" />
                    </StyledRoundButton>
                </>
            }
        />
    );
});
