import React, { memo } from 'react';
import { useQueryClient } from 'react-query';
import { useIntl } from 'react-intl';
import { useSetUi } from '../../contexts/UiContext';
import {
    GanttStripDto,
    KeyGetGetAllSlotRequests,
    KeyGetGetDayGanttTurnarounds,
    KeyGetGetSlotStrip,
    PostUpdateSlotOccurrencesResponse,
    ScheduleSlotUpdateDto,
    useMutationPostUpdateSlotOccurrences,
} from '@models';
import { toast } from 'react-toastify';
import { errorToastConfig, slotsOverlapErrorToastConfig, successToastConfig } from '../../config/constants';
import { ModifySlotPopin } from '@components/ModifySlot/ModifySlotPopin';
import { ModifySlotOverlapToast } from '@components/ModifySlot/ModifySlotOverlapToast';

export const ModifySlot = memo(function ModifySlot({ slot, onClose }: { slot: GanttStripDto; onClose: () => void }) {
    const queryClient = useQueryClient();
    const intl = useIntl();
    const setSelectedSlotId = useSetUi('selectedSlotId');
    const setSelectedFlightId = useSetUi('selectedFlightId');

    const { mutateAsync: updateRequest } = useMutationPostUpdateSlotOccurrences({
        onSuccess: ({ data }: PostUpdateSlotOccurrencesResponse) => {
            if (!data || !data.updatedSlotId) {
                return;
            }

            queryClient.invalidateQueries(KeyGetGetDayGanttTurnarounds);
            queryClient.invalidateQueries(KeyGetGetSlotStrip);
            queryClient.invalidateQueries(KeyGetGetAllSlotRequests);
            toast(intl.formatMessage({ id: 'requestForm.success.create' }), successToastConfig);
        },
        onError: ({ response }) => {
            if (response?.data.errorKey === 'SLOTS_OVERLAY') {
                toast(<ModifySlotOverlapToast overlappedSlotId={response.data.slotId} />, slotsOverlapErrorToastConfig);
            } else {
                toast(
                    intl.formatMessage(
                        {
                            id: `requestForm.error.${response?.data.errorKey}`,
                            defaultMessage: intl.formatMessage({ id: 'requestForm.error.default' }),
                        },
                        { message: response?.data.message || response?.data.errorKey || '' },
                    ),
                    errorToastConfig,
                );
            }
        },
    });

    return (
        <ModifySlotPopin
            slot={slot}
            onClose={onClose}
            onSubmit={(values: ScheduleSlotUpdateDto, isConfirm?: boolean) => {
                updateRequest({ scheduleSlotUpdateDto: values, id: slot.id, confirm: isConfirm }).then(({ data }) => {
                    if (data?.updatedSlotId) {
                        setSelectedSlotId(data.updatedSlotId);
                        // Flight ids are changed after a slot update, let the gantt automatically select the flight id after fetching the new slot
                        setSelectedFlightId(null);
                    }
                    onClose();
                });
            }}
        />
    );
});
