import React, { memo, useCallback } from 'react';
import { GanttStripDto } from '@types';
import { CircularProgress, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {
    KeyGetGetAllSlotRequests,
    KeyGetGetDayGanttTurnarounds,
    PostSplitSlotOccurrencesResponse,
    useMutationPostSplitSlotOccurrences,
} from '@models';
import { QueryClient, useQueryClient } from 'react-query';
import { usePopoverValue } from '@components/navigation/PopoverMenu/PopoverMenu';
import { useConfirmDialog } from '@components/confirm/useConfirmDialog';
import { useSetUi } from '../../../contexts/UiContext';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../config/constants';

const onMutateRequests =
    (queryClient: QueryClient) =>
    ({ data }: PostSplitSlotOccurrencesResponse) => {
        if (!data || !data.updatedSlotId) {
            return;
        }

        queryClient.invalidateQueries(KeyGetGetDayGanttTurnarounds);
        queryClient.invalidateQueries(KeyGetGetAllSlotRequests);
    };

export const StripMenuItemSplitArrDep = memo(function StripMenuItemSplitArrDep({ slot }: { slot: GanttStripDto }) {
    const close = usePopoverValue('close');
    const queryClient = useQueryClient();

    // confirm all slots
    const { mutateAsync: splitArrDep, isLoading } = useMutationPostSplitSlotOccurrences({
        onSuccess: onMutateRequests(queryClient),
        onError: () => {
            toast(<FormattedMessage id="strip.actions.splitArrDepError" />, errorToastConfig);
        },
    });

    const setSelectedFlightId = useSetUi('selectedFlightId');
    const setSelectedSlotId = useSetUi('selectedSlotId');
    const confirm = useConfirmDialog({ disableReject: true });
    const onClick = useCallback(() => {
        close();

        confirm({
            content: (
                <FormattedMessage
                    id="strip.actions.splitArrDep.confirmLabel"
                    values={{ arrFln: slot.arrivalFlightNumberFull, depFln: slot.departureFlightNumberFull }}
                />
            ),
            confirmationText: <FormattedMessage id="strip.actions.splitArrDep.confirmButton" />,
        }).then(() => {
            splitArrDep({
                id: slot.id,
            }).then(({ data }) => {
                setSelectedSlotId(data?.updatedSlotId || null);
                setSelectedFlightId(null);
            });
        });
    }, [
        close,
        confirm,
        slot.arrivalFlightNumberFull,
        slot.departureFlightNumberFull,
        slot.id,
        splitArrDep,
        setSelectedSlotId,
        setSelectedFlightId,
    ]);

    if (!slot.sibt || !slot.sobt) {
        return null;
    }
    return (
        <MenuItem onClick={onClick}>
            <ListItemText>
                <FormattedMessage id="strip.actions.splitArrDep" />
            </ListItemText>
            {isLoading && (
                <ListItemIcon sx={{ marginLeft: 1 }}>
                    <CircularProgress size={25} color={'secondary'} />
                </ListItemIcon>
            )}
        </MenuItem>
    );
});
