import React, { memo } from 'react';
import { resourceFields } from '@components/Schedule/Gantt/gantt.utils';
import { AirportResourcesDto, ResourceOrigin, ResourceType } from '@types';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { ResourceFormValues } from '@components/Resources/Update/ResourcesForm/ResourcesFormContainer';
import { Box, styled } from '@mui/material';
import { FormikAutocomplete } from '@components/form/Autocomplete/FormikAutocomplete';
import { FormikTextField } from '@components/form/TextField/FormikTextField';

const StyledBoxContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    width: 250px;
    min-height: 100px;
`;

export const ResourcesFormFieldsItem = memo(function ResourcesFormFieldsItem({
    origin,
    type,
    side,
    resources,
    placeholders,
    rms,
}: {
    origin: ResourceOrigin;
    type: ResourceType;
    side: 'arrival' | 'departure' | 'both';
    resources?: AirportResourcesDto;
    placeholders?: boolean;
    rms?: boolean;
}) {
    const { setFieldValue } = useFormikContext<ResourceFormValues>();

    const sideFieldName =
        (side === 'both' && resourceFields[type].arrival) || side === 'arrival' ? 'arrival' : 'departure';
    const fieldName = resourceFields[type][sideFieldName];

    if (
        (origin === ResourceOrigin.ARR && side === 'departure') ||
        (origin === ResourceOrigin.DEP && side === 'arrival') ||
        !fieldName
    ) {
        if (placeholders) {
            return <StyledBoxContainer key={type} />;
        } else {
            return null;
        }
    }

    const onChange =
        side === 'both'
            ? (newValue?: string | null) => {
                  const oppositeFieldName = resourceFields[type][sideFieldName ? 'departure' : 'arrival'];
                  if (oppositeFieldName) {
                      setFieldValue(oppositeFieldName, newValue);
                  }
              }
            : undefined;

    return (
        <StyledBoxContainer key={type}>
            {rms ? (
                <FormikAutocomplete
                    required
                    label={<FormattedMessage id={`ResourceType.${type}`} />}
                    name={fieldName}
                    id={fieldName}
                    options={resources?.resources?.[type]}
                    onChange={onChange}
                    noOptionsText={<FormattedMessage id="resourcesForm.noOptions" />}
                />
            ) : (
                <FormikTextField
                    required
                    name={fieldName}
                    label={<FormattedMessage id={`ResourceType.${type}`} />}
                    id={fieldName}
                    onChange={onChange && (e => onChange(e.target.value))}
                />
            )}
        </StyledBoxContainer>
    );
});
