/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AirportResourcesDto } from '../models';
import { DayResourcesPropagationDto } from '../models';
import { DaySlotResourcesAllocationDto } from '../models';
import { DaySlotResourcesAllocationResponse } from '../models';
import { TurnaroundResourcesUpdateDto } from '../models';
import { Configuration, ConfigurationParameters } from '../configuration';
import { BASE_PATH, BaseAPI, RequestArgs } from '../base';
import { AxiosInstance, AxiosPromise, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, UseMutationOptions, useMutation, UseQueryOptions } from 'react-query';
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setSearchParams,
    toPathString,
    globalAxios,
    setBearerAuthToObject,
} from '../common';
import { useAppAuthValue } from '../../utils';
export const ResourcesWebServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        autoAllocateDaySlotResources: async (
            daySlotResourcesAllocationDto: DaySlotResourcesAllocationDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'daySlotResourcesAllocationDto' is not null or undefined
            assertParamExists(
                'autoAllocateDaySlotResources',
                'daySlotResourcesAllocationDto',
                daySlotResourcesAllocationDto,
            );
            const localVarPath = `/resources/day/allocate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                daySlotResourcesAllocationDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getAirportResources: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        propagateDayResources: async (
            dayResourcesPropagationDto: DayResourcesPropagationDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'dayResourcesPropagationDto' is not null or undefined
            assertParamExists('propagateDayResources', 'dayResourcesPropagationDto', dayResourcesPropagationDto);
            const localVarPath = `/resources/day/propagate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                dayResourcesPropagationDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        updateTurnaroundResources: async (
            turnaroundResourcesUpdateDto: TurnaroundResourcesUpdateDto,
            propagate?: boolean,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'turnaroundResourcesUpdateDto' is not null or undefined
            assertParamExists(
                'updateTurnaroundResources',
                'turnaroundResourcesUpdateDto',
                turnaroundResourcesUpdateDto,
            );
            const localVarPath = `/resources/slot/occurrence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (propagate !== undefined) {
                localVarQueryParameter['propagate'] = propagate;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                turnaroundResourcesUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        updateTurnaroundsResources: async (
            turnaroundResourcesUpdateDto: Array<TurnaroundResourcesUpdateDto>,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'turnaroundResourcesUpdateDto' is not null or undefined
            assertParamExists(
                'updateTurnaroundsResources',
                'turnaroundResourcesUpdateDto',
                turnaroundResourcesUpdateDto,
            );
            const localVarPath = `/resources/day/occurrences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                turnaroundResourcesUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
export const ResourcesWebServicesApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ResourcesWebServicesApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {DaySlotResourcesAllocationDto} daySlotResourcesAllocationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoAllocateDaySlotResources(
            daySlotResourcesAllocationDto: DaySlotResourcesAllocationDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DaySlotResourcesAllocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoAllocateDaySlotResources(
                daySlotResourcesAllocationDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAirportResources(
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AirportResourcesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAirportResources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {DayResourcesPropagationDto} dayResourcesPropagationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propagateDayResources(
            dayResourcesPropagationDto: DayResourcesPropagationDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propagateDayResources(
                dayResourcesPropagationDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {TurnaroundResourcesUpdateDto} turnaroundResourcesUpdateDto
         * @param {boolean} [propagate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTurnaroundResources(
            turnaroundResourcesUpdateDto: TurnaroundResourcesUpdateDto,
            propagate?: boolean,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTurnaroundResources(
                turnaroundResourcesUpdateDto,
                propagate,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Array<TurnaroundResourcesUpdateDto>} turnaroundResourcesUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTurnaroundsResources(
            turnaroundResourcesUpdateDto: Array<TurnaroundResourcesUpdateDto>,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTurnaroundsResources(
                turnaroundResourcesUpdateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

export const ResourcesWebServicesApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = ResourcesWebServicesApiFp(configuration);
    return {
        autoAllocateDaySlotResources(
            daySlotResourcesAllocationDto: DaySlotResourcesAllocationDto,
            options?: any,
        ): AxiosPromise<DaySlotResourcesAllocationResponse> {
            return localVarFp
                .autoAllocateDaySlotResources(daySlotResourcesAllocationDto, options)
                .then(request => request(axios, basePath));
        },
        getAirportResources(options?: any): AxiosPromise<AirportResourcesDto> {
            return localVarFp.getAirportResources(options).then(request => request(axios, basePath));
        },
        propagateDayResources(
            dayResourcesPropagationDto: DayResourcesPropagationDto,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .propagateDayResources(dayResourcesPropagationDto, options)
                .then(request => request(axios, basePath));
        },
        updateTurnaroundResources(
            turnaroundResourcesUpdateDto: TurnaroundResourcesUpdateDto,
            propagate?: boolean,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .updateTurnaroundResources(turnaroundResourcesUpdateDto, propagate, options)
                .then(request => request(axios, basePath));
        },
        updateTurnaroundsResources(
            turnaroundResourcesUpdateDto: Array<TurnaroundResourcesUpdateDto>,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .updateTurnaroundsResources(turnaroundResourcesUpdateDto, options)
                .then(request => request(axios, basePath));
        },
    };
};
export interface ResourcesWebServicesApiAutoAllocateDaySlotResourcesRequest {
    readonly daySlotResourcesAllocationDto: DaySlotResourcesAllocationDto;
}

export interface ResourcesWebServicesApiPropagateDayResourcesRequest {
    readonly dayResourcesPropagationDto: DayResourcesPropagationDto;
}

export interface ResourcesWebServicesApiUpdateTurnaroundResourcesRequest {
    readonly turnaroundResourcesUpdateDto: TurnaroundResourcesUpdateDto;

    readonly propagate?: boolean;
}

export interface ResourcesWebServicesApiUpdateTurnaroundsResourcesRequest {
    readonly turnaroundResourcesUpdateDto: Array<TurnaroundResourcesUpdateDto>;
}

export class ResourcesWebServicesApi extends BaseAPI {
    public autoAllocateDaySlotResources(
        requestParameters: ResourcesWebServicesApiAutoAllocateDaySlotResourcesRequest,
        options?: any,
    ) {
        return ResourcesWebServicesApiFp(this.configuration)
            .autoAllocateDaySlotResources(requestParameters.daySlotResourcesAllocationDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public getAirportResources(options?: any) {
        return ResourcesWebServicesApiFp(this.configuration)
            .getAirportResources(options)
            .then(request => request(this.axios, this.basePath));
    }
    public propagateDayResources(
        requestParameters: ResourcesWebServicesApiPropagateDayResourcesRequest,
        options?: any,
    ) {
        return ResourcesWebServicesApiFp(this.configuration)
            .propagateDayResources(requestParameters.dayResourcesPropagationDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public updateTurnaroundResources(
        requestParameters: ResourcesWebServicesApiUpdateTurnaroundResourcesRequest,
        options?: any,
    ) {
        return ResourcesWebServicesApiFp(this.configuration)
            .updateTurnaroundResources(
                requestParameters.turnaroundResourcesUpdateDto,
                requestParameters.propagate,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }
    public updateTurnaroundsResources(
        requestParameters: ResourcesWebServicesApiUpdateTurnaroundsResourcesRequest,
        options?: any,
    ) {
        return ResourcesWebServicesApiFp(this.configuration)
            .updateTurnaroundsResources(requestParameters.turnaroundResourcesUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
}

// custom hook generation here
export type PostAutoAllocateDaySlotResourcesResponse = AxiosResponse<DaySlotResourcesAllocationResponse>;
export const KeyPostAutoAllocateDaySlotResources = 'PostAutoAllocateDaySlotResources';
export const useMutationPostAutoAllocateDaySlotResources = (
    options?: UseMutationOptions<
        PostAutoAllocateDaySlotResourcesResponse,
        AxiosError,
        ResourcesWebServicesApiAutoAllocateDaySlotResourcesRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostAutoAllocateDaySlotResourcesResponse,
        AxiosError,
        ResourcesWebServicesApiAutoAllocateDaySlotResourcesRequest
    >(
        (args: ResourcesWebServicesApiAutoAllocateDaySlotResourcesRequest) =>
            new ResourcesWebServicesApi(new Configuration({ accessToken })).autoAllocateDaySlotResources(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostAutoAllocateDaySlotResources = (
    args: ResourcesWebServicesApiAutoAllocateDaySlotResourcesRequest,
    options?: UseQueryOptions<PostAutoAllocateDaySlotResourcesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostAutoAllocateDaySlotResourcesResponse, AxiosError>(
        [KeyPostAutoAllocateDaySlotResources, args, options && options.queryKey],
        () =>
            new ResourcesWebServicesApi(new Configuration({ accessToken, ...conf })).autoAllocateDaySlotResources(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetAirportResourcesResponse = AxiosResponse<AirportResourcesDto>;
export const KeyGetGetAirportResources = 'GetGetAirportResources';
export const useMutationGetGetAirportResources = (
    options?: UseMutationOptions<GetGetAirportResourcesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetGetAirportResourcesResponse, AxiosError>(
        () => new ResourcesWebServicesApi(new Configuration({ accessToken })).getAirportResources(axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetAirportResources = (
    options?: UseQueryOptions<GetGetAirportResourcesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetAirportResourcesResponse, AxiosError>(
        [KeyGetGetAirportResources, options && options.queryKey],
        () => new ResourcesWebServicesApi(new Configuration({ accessToken, ...conf })).getAirportResources(axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostPropagateDayResourcesResponse = AxiosResponse<void>;
export const KeyPostPropagateDayResources = 'PostPropagateDayResources';
export const useMutationPostPropagateDayResources = (
    options?: UseMutationOptions<
        PostPropagateDayResourcesResponse,
        AxiosError,
        ResourcesWebServicesApiPropagateDayResourcesRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostPropagateDayResourcesResponse,
        AxiosError,
        ResourcesWebServicesApiPropagateDayResourcesRequest
    >(
        (args: ResourcesWebServicesApiPropagateDayResourcesRequest) =>
            new ResourcesWebServicesApi(new Configuration({ accessToken })).propagateDayResources(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostPropagateDayResources = (
    args: ResourcesWebServicesApiPropagateDayResourcesRequest,
    options?: UseQueryOptions<PostPropagateDayResourcesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostPropagateDayResourcesResponse, AxiosError>(
        [KeyPostPropagateDayResources, args, options && options.queryKey],
        () =>
            new ResourcesWebServicesApi(new Configuration({ accessToken, ...conf })).propagateDayResources(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostUpdateTurnaroundResourcesResponse = AxiosResponse<void>;
export const KeyPostUpdateTurnaroundResources = 'PostUpdateTurnaroundResources';
export const useMutationPostUpdateTurnaroundResources = (
    options?: UseMutationOptions<
        PostUpdateTurnaroundResourcesResponse,
        AxiosError,
        ResourcesWebServicesApiUpdateTurnaroundResourcesRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostUpdateTurnaroundResourcesResponse,
        AxiosError,
        ResourcesWebServicesApiUpdateTurnaroundResourcesRequest
    >(
        (args: ResourcesWebServicesApiUpdateTurnaroundResourcesRequest) =>
            new ResourcesWebServicesApi(new Configuration({ accessToken })).updateTurnaroundResources(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostUpdateTurnaroundResources = (
    args: ResourcesWebServicesApiUpdateTurnaroundResourcesRequest,
    options?: UseQueryOptions<PostUpdateTurnaroundResourcesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostUpdateTurnaroundResourcesResponse, AxiosError>(
        [KeyPostUpdateTurnaroundResources, args, options && options.queryKey],
        () =>
            new ResourcesWebServicesApi(new Configuration({ accessToken, ...conf })).updateTurnaroundResources(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostUpdateTurnaroundsResourcesResponse = AxiosResponse<void>;
export const KeyPostUpdateTurnaroundsResources = 'PostUpdateTurnaroundsResources';
export const useMutationPostUpdateTurnaroundsResources = (
    options?: UseMutationOptions<
        PostUpdateTurnaroundsResourcesResponse,
        AxiosError,
        ResourcesWebServicesApiUpdateTurnaroundsResourcesRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostUpdateTurnaroundsResourcesResponse,
        AxiosError,
        ResourcesWebServicesApiUpdateTurnaroundsResourcesRequest
    >(
        (args: ResourcesWebServicesApiUpdateTurnaroundsResourcesRequest) =>
            new ResourcesWebServicesApi(new Configuration({ accessToken })).updateTurnaroundsResources(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostUpdateTurnaroundsResources = (
    args: ResourcesWebServicesApiUpdateTurnaroundsResourcesRequest,
    options?: UseQueryOptions<PostUpdateTurnaroundsResourcesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostUpdateTurnaroundsResourcesResponse, AxiosError>(
        [KeyPostUpdateTurnaroundsResources, args, options && options.queryKey],
        () =>
            new ResourcesWebServicesApi(new Configuration({ accessToken, ...conf })).updateTurnaroundsResources(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
