/**
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

export const ganttPxPerMs = 0.00003; // how many px is a millisecond
export const ganttFlightRowHeight = 50;
export const ganttFlightRowMargin = 10;
export const ganttTurnaroundChildrenBreakpoint = 60;
export const ganttTurnaroundChildrenBreakpointWithoutImpact = 25;
export const ganttPartialDefaultDuration = 80 * 60 * 1000; // 80 hours
export const ganttPartialDefaultWidth = ganttPartialDefaultDuration * ganttPxPerMs;
export const ganttDefaultFromHour = 6;
export const ganttDayFormat = 'yyyy-MM-dd';
export const ganttDayPathFormat = 'dd-MM-yyyy';
