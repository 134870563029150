import { Box, TextFieldProps } from '@mui/material';
import { defaultIcaoIata } from 'config/constants';
import { Field } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import * as React from 'react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { stopPropagationCb } from 'utils/dom.utils';
import { IcaoIataSwitch } from '../IcaoIataSwitch/IcaoIataSwitch';
import { TextField } from '../TextField/TextField';

export const FormikIcaoIataTextField = memo(function FormikIcaoIataTextField({
    label,
    name,
    iataIcao,
    ...rest
}: {
    iataIcao?: 'iata' | 'icao';
    label: string | React.ReactNode;
    name: string;
} & TextFieldProps) {
    const { value: _isIata, toggle: toggleIsIata } = useBoolean(defaultIcaoIata === 'iata');
    const isIata = iataIcao ? iataIcao === 'iata' : _isIata;

    return (
        <Field name={name}>
            {({
                field, // { name, value, onChange, onBlur }
                form: { errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            }) => (
                <TextField
                    {...rest}
                    labelProps={{ focused: false }}
                    label={
                        <>
                            {label}
                            {iataIcao ? ` (${iataIcao.toUpperCase()})` : null}

                            {!iataIcao ? (
                                <Box
                                    component="span"
                                    sx={{
                                        marginLeft: '40px',
                                        cursor: 'pointer',
                                        height: '30px',
                                        display: 'inline-flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    onClick={!rest.disabled ? stopPropagationCb() : undefined}
                                >
                                    <IcaoIataSwitch
                                        checked={isIata}
                                        onClick={toggleIsIata}
                                        color="primary"
                                        disabled={rest.disabled}
                                    />
                                    {!rest.required && (
                                        <span>
                                            (<FormattedMessage id="form.optional" />)
                                        </span>
                                    )}
                                </Box>
                            ) : null}
                        </>
                    }
                    name={name}
                    error={!!errors[name]}
                    helperText={
                        errors[name] && (
                            <FormattedMessage
                                id={errors[name].iata || errors[name].icao || errors[name]}
                                defaultMessage={errors[name].iata || errors[name].icao || errors[name]}
                            />
                        )
                    }
                    value={(isIata ? field.value?.iata : field.value?.icao) || ''}
                    onChange={e =>
                        setFieldValue(name, {
                            [isIata ? 'iata' : 'icao']: e.target.value,
                            [isIata ? 'icao' : 'iata']: '',
                        })
                    }
                />
            )}
        </Field>
    );
});
