import { PopoverMenu } from '@components/navigation/PopoverMenu/PopoverMenu';
import { MenuItem } from '@mui/material';
import { useUiState } from 'contexts/UiContext';
import React, { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { FilterRequestsButton } from './FilterRequestsButton';
import { DemandStatus } from '@types';

const AvailableFilters: DemandStatus[] = [DemandStatus.COMPLETED, DemandStatus.NEW];

export const FilterRequestsByStatus = memo(function FilterRequests() {
    const [requestFilter, setRequestFilter] = useUiState('requestFilter');

    const renderButton = useCallback(
        ({ onClick, opened }) => (
            <FilterRequestsButton onClick={onClick} opened={opened}>
                <FormattedMessage id={`requests.actions.filter.${requestFilter ?? 'ALL'}`} />
            </FilterRequestsButton>
        ),
        [requestFilter],
    );

    return (
        <PopoverMenu renderButton={renderButton} autoClose={true}>
            <MenuItem key={'all'} onClick={() => setRequestFilter(undefined)}>
                <FormattedMessage id={`requests.actions.filter.ALL`} />
            </MenuItem>
            {AvailableFilters.map((af: DemandStatus) => (
                <MenuItem key={af} onClick={() => setRequestFilter(af)}>
                    <FormattedMessage id={`requests.actions.filter.${af}`} />
                </MenuItem>
            ))}
        </PopoverMenu>
    );
});
