import React, { memo } from 'react';
import { GanttStripDto } from '@types';
import { ListItemText, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { usePopoverValue } from '@components/navigation/PopoverMenu/PopoverMenu';

export const StripMenuItemAssociation = memo(function StripMenuItemAssociation({
    slot,
    onClick,
}: {
    slot: GanttStripDto;
    onClick?: () => void;
}) {
    const close = usePopoverValue('close');

    const hideMenuItem = slot.sibt && slot.sobt;

    if (hideMenuItem) {
        return null;
    }

    return (
        <MenuItem
            onClick={() => {
                onClick?.();
                close();
            }}
        >
            <ListItemText>
                <FormattedMessage id="strip.actions.slotAssociation" />
            </ListItemText>
        </MenuItem>
    );
});
