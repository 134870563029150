import { IcaoIata } from '@components/IcaoIata';
import { GanttStripDto, IcaoIataDto, ModificationDemandDto, SlotUpdateDto, SlotVersionDto } from '@models';
import { Box, styled, Typography } from '@mui/material';
import React, { memo } from 'react';
import { ReactComponent as AirlineSeatReclineExtraIcon } from '../../assets/icons/icon-passenger-light.svg';
import { ReactComponent as FlightIcon } from '../../assets/icons/icon-plane-light.svg';
import { StripElement } from '@components/Strip/StripElement';

const StyledAircraftInfosBox = styled(StripElement)`
    min-width: 213px;
    justify-content: space-between;
`;

const StyledAcTypeUpdate = styled(StyledAircraftInfosBox)`
    min-width: 214px;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.primary.light};
    top: 61px;
    margin-left: -1px;
    border-left: 1px dashed ${({ theme }) => theme.palette.blue.lightBg};
`;

const StripAircraftInfosLabel = memo(
    ({ paxCapacity, acType }: { acType?: IcaoIataDto | null; paxCapacity?: number | null }) => {
        return (
            <>
                <Box display="flex" alignItems="center" justifyContent="space-around" width="75px">
                    <AirlineSeatReclineExtraIcon color="#B6D2E5" />
                    <Typography fontSize="16px">{paxCapacity || '-'}</Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-around" width="75px">
                    <FlightIcon color="#B6D2E5" />
                    <Typography fontSize="16px">
                        <IcaoIata {...acType} />
                    </Typography>
                </Box>
            </>
        );
    },
);

export const StripAircraftInfos = memo(
    ({
        slot,
        slotUpdate,
    }: {
        slot?: GanttStripDto | ModificationDemandDto | SlotVersionDto | null;
        slotUpdate?: SlotUpdateDto | null;
    }) => {
        return (
            <Box position="relative">
                <StyledAircraftInfosBox>
                    <StripAircraftInfosLabel acType={slot?.acType} paxCapacity={slot?.paxCapacity} />
                </StyledAircraftInfosBox>
                {slotUpdate?.aircraft && (
                    <StyledAcTypeUpdate>
                        <StripAircraftInfosLabel
                            acType={slotUpdate.aircraft.acType}
                            paxCapacity={slotUpdate.aircraft.paxCapacity}
                        />
                    </StyledAcTypeUpdate>
                )}
            </Box>
        );
    },
);
