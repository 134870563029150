import { Nav } from '@components/navigation/Nav/Nav';
import { Search } from '@components/navigation/Search/Search';
import { SelectedDate } from '@components/navigation/SelectedDate/SelectedDate';
import { Clock } from '@components/navigation/ToggleUTCLocal/Clock';
import { Box } from '@mui/material';
import React, { memo } from 'react';
import { PageTitle } from '@components/navigation/Nav/PageTitle';
import { FormattedMessage } from 'react-intl';
import {
    Header,
    LeftComponentsHeader,
    MiddleComponentsHeader,
    RightComponentsHeader,
} from '@components/navigation/Header/Header';
import { ViewModeButton } from '@components/Schedule/Header/ViewMode/ViewModeButton';
import { ResourcesMenu } from '@components/Resources/ResourcesMenu';
import { useUiValue } from '../../../contexts/UiContext';
import { useGoToSchedule } from '../../../hooks/useGoToSchedule';

export const ScheduleHeader = memo(function ScheduleHeader({ disabled }: { disabled?: boolean }) {
    const selectedDate = useUiValue('selectedDate');
    const setSelectedDate = useGoToSchedule();

    return (
        <Header>
            <LeftComponentsHeader>
                <Nav>
                    <PageTitle>
                        <FormattedMessage id="nav.title.schedule" />
                    </PageTitle>
                </Nav>
                <ResourcesMenu disabled={disabled} />
            </LeftComponentsHeader>
            <MiddleComponentsHeader>
                <SelectedDate disabled={disabled} value={selectedDate} onChange={setSelectedDate} />
            </MiddleComponentsHeader>
            <RightComponentsHeader>
                <Box margin="0 50px 0 0">
                    <Search />
                </Box>
                <Box margin="0 20px 0 0">
                    <ViewModeButton />
                </Box>
                <Clock />
            </RightComponentsHeader>
        </Header>
    );
});
