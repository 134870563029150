/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { ImpactStatus } from '@models';
import { Box, BoxProps, styled } from '@mui/material';
import {
    ganttFlightRowHeight,
    ganttPartialDefaultWidth,
    ganttPxPerMs,
    ganttTurnaroundChildrenBreakpoint,
} from '@components/Schedule/Gantt/gantt.constants';
import React, { memo } from 'react';

const FlightTurnaroundBackgroundBox = memo(
    styled(Box)<{
        isDepartureSlice?: boolean;
        confirmed?: boolean;
        withoutSOBT?: boolean;
        withoutSIBT?: boolean;
        impactStatus?: ImpactStatus | null;
    }>(({ isDepartureSlice, confirmed, withoutSIBT, withoutSOBT, impactStatus, theme }) => [
        {
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.blue.dark,
            background: 'white',
            borderRadius: `${theme.shape.borderRadiusLarge}px`,
            cursor: 'pointer',
            pointerEvents: 'auto',
            boxSizing: 'border-box',
        },
        !confirmed && {
            backgroundImage:
                'repeating-linear-gradient(-45deg,rgba(0,0,0,0),rgba(0,0,0,0) 2.9px,#14518B30 3px,#14518B30 6px)',
        },
        withoutSIBT && {
            maskImage: `linear-gradient(90deg, rgba(0,0,0,0) ${
                isDepartureSlice ? '-10%' : '10%'
            }, rgba(0,0,0,1) 60%, rgba(0,0,0,1))`,
            justifyContent: 'flex-end',
            paddingRight: 10,
        },
        withoutSOBT && {
            maskImage: 'linear-gradient(90deg, rgba(0,0,0,1), rgba(0,0,0,1) 40%, rgba(0,0,0,0) 90%)',
            justifyContent: 'flex-start',
            paddingLeft: 20,
        },
        impactStatus === ImpactStatus.CANCELLED && {
            backgroundColor: theme.palette.blue.darkest,
            color: '#ffffff99',
        },
    ]),
);

const StatusBadgeContainer = styled(Box)({
    position: 'absolute',
    left: 0,
    width: 20,
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const ImpactStatusBadgeBox = styled('div', {
    shouldForwardProp: () => false,
})<{ impactStatus: ImpactStatus }>(({ impactStatus, theme }) => ({
    width: 12,
    height: 12,
    overflow: 'hidden',
    borderRadius: '100%',
    backgroundColor: theme.palette.impactStatus[impactStatus].main,
}));

export interface FlightTurnaroundProps {
    from?: number | null;
    to?: number | null;
    slice?: 'arrival' | 'departure' | null;
    impactStatus?: ImpactStatus | null;
    confirmed?: boolean;
    arrival?: React.ReactNode;
    departure?: React.ReactNode;
    children?: React.ReactNode;
}

export const FlightTurnaround = memo(function FlightTurnaround({
    from,
    to,
    slice, // split flight in half
    impactStatus,
    confirmed,
    arrival,
    departure,
    onClick,
    children,
    ...rest
}: FlightTurnaroundProps & BoxProps) {
    const duration = from && to ? to - from : null;
    const width = duration ? duration * ganttPxPerMs : ganttPartialDefaultWidth;

    const withoutSOBT = !!(from && !to);
    const withoutSIBT = !!(to && !from);

    return (
        <Box width={width} height={ganttFlightRowHeight} position="relative" {...rest}>
            {slice !== 'departure' && arrival}
            <Box
                width="100%"
                height="100%"
                sx={
                    impactStatus !== ImpactStatus.CANCELLED
                        ? {
                              filter: 'drop-shadow(0 3px 6px rgba(0,0,0,0.3))',
                          }
                        : undefined
                }
            >
                <FlightTurnaroundBackgroundBox
                    confirmed={confirmed}
                    withoutSOBT={withoutSOBT}
                    withoutSIBT={withoutSIBT}
                    impactStatus={impactStatus}
                    onClick={onClick}
                    sx={{
                        width: slice && width / 2,
                        borderRadius:
                            slice &&
                            (({ shape: { borderRadiusLarge: b } }) =>
                                slice === 'arrival' ? `${b}px 0 0 ${b}px` : `0 ${b}px ${b}px 0`),
                        marginLeft: slice === 'departure' ? `${width / 2}px` : 0,
                    }}
                    isDepartureSlice={slice === 'departure'}
                >
                    {!confirmed && impactStatus && impactStatus !== ImpactStatus.CANCELLED && (
                        <StatusBadgeContainer
                            sx={{
                                // color badge is centered when the flight is too small to display text content
                                ...((slice ? width / 2 : width) <= ganttTurnaroundChildrenBreakpoint && {
                                    width: '100%',
                                }),

                                // color badge must not be in the transparent area when the flight has no SIBT
                                ...(withoutSIBT && { left: 'auto', right: '40px' }),
                            }}
                        >
                            <ImpactStatusBadgeBox impactStatus={impactStatus} />
                        </StatusBadgeContainer>
                    )}
                    {children}
                </FlightTurnaroundBackgroundBox>
            </Box>
            {slice !== 'arrival' && departure}
        </Box>
    );
});
