import { pickersDayClasses } from '@mui/lab';
import React, { ComponentProps, memo, useCallback } from 'react';
import { Box, styled, ThemeProvider, Typography, useTheme } from '@mui/material';
import { default as MuiDateRangePicker } from '@mui/lab/DateRangePicker';
import { TextField } from '@components/form/TextField/TextField';
import { lightTheme } from '../../../theme';
import { defaultLocale } from '../../../config/constants';
import { useIntlValue } from '@innovatm/js-iam';
import { ReactComponent as IconCalendar } from '../../../assets/icons/Icon-Calendar.svg';
import { localeToDateFormat, localeToDatePlaceholder } from '@components/time/time.utils';

const StyledDateRangePicker = styled(MuiDateRangePicker)`
    & .${pickersDayClasses.root}.Mui-selected:focus, & .${pickersDayClasses.root}.Mui-selected:hover {
        background-color: ${({ theme }) => theme.palette.primary.light};
        color: ${({ theme }) => theme.palette.secondary.light};
    }
`;

const StyledTypography = styled(Typography)`
    margin: ${({ theme }) => theme.spacing(2)};
`;

// TODO doesn't show up !?
const componentsProps = {
    OpenPickerIcon: () => <IconCalendar />,
};

export const DateRangePicker = memo(function DateRangePicker({
    separatorText,
    ...rest
}: { separatorText?: React.ReactNode | string } & Omit<ComponentProps<typeof MuiDateRangePicker>, 'renderInput'>) {
    // @mui DateRangePicker doesn't yet allow customizing the dialog or the calendar
    // Check https://mui.com/api/date-range-picker/
    const originalTheme = useTheme();
    const locale = useIntlValue('locale');

    const renderDateRangeInput = useCallback(
        (startProps, endProps) => (
            <ThemeProvider theme={originalTheme}>
                <Box display={'flex'} alignItems={'center'}>
                    <TextField
                        {...startProps}
                        required
                        inputProps={{
                            ...startProps.inputProps,
                            placeholder: localeToDatePlaceholder[locale] || localeToDatePlaceholder[defaultLocale],
                        }}
                    />
                    {separatorText && <StyledTypography variant={'body1'}>{separatorText}</StyledTypography>}
                    <TextField
                        {...endProps}
                        required
                        inputProps={{
                            ...endProps.inputProps,
                            placeholder: localeToDatePlaceholder[locale] || localeToDatePlaceholder[defaultLocale],
                        }}
                    />
                </Box>
            </ThemeProvider>
        ),
        [locale, originalTheme, separatorText],
    );

    return (
        <ThemeProvider theme={lightTheme}>
            <StyledDateRangePicker
                renderInput={renderDateRangeInput}
                inputFormat={localeToDateFormat[locale] || localeToDateFormat[defaultLocale]}
                components={componentsProps}
                {...rest}
            />
        </ThemeProvider>
    );
});
