/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { Box, styled } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { ResourceDiscrepancyAlertDto, ResourceOrigin, ResourceType, TurnaroundResourcesDto } from '@types';
import { useUiValue } from '../../../contexts/UiContext';
import { resourceFields } from '@components/Schedule/Gantt/gantt.utils';
import { useCurrentUserResources } from '../../../contexts/AuthContext.selectors';
import { ganttFlightRowHeight } from '@components/Schedule/Gantt/gantt.constants';
import { ResourcesProposalImpact } from '../../../backend/types.custom';

export const FlightTurnaroundResourceContainer = styled(Box)`
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
    max-height: ${ganttFlightRowHeight}px;
    height: auto;
    min-width: 20px;
    flex-shrink: 0;
    border-radius: 3px;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    background-color: #006ebb;
    box-sizing: content-box;
    &.updated {
        color: white;
        background-color: #f57a1f;
    }
    &.new {
        color: white;
        background-color: #55af55;
    }
`;

export const FlightTurnaroundResourceBox = styled(Box)<{ inAlert?: boolean }>`
    padding: 2px 4px;
    height: 12px;
    flex-shrink: 0;
    ${({ inAlert, theme }) => (inAlert ? `background-color: ${theme.palette.error.main};` : '')}
`;

export const FlightTurnaroundResources = memo(function FlightTurnaroundResources({
    resources,
    resourcesProposalImpact,
    affectedResourceType,
    origin,
    discrepancyAlerts,
}: {
    resources?: Partial<TurnaroundResourcesDto>;
    resourcesProposalImpact?: ResourcesProposalImpact;
    affectedResourceType?: ResourceType;
    origin: ResourceOrigin.ARR | ResourceOrigin.DEP;
    discrepancyAlerts?: ResourceDiscrepancyAlertDto[];
}) {
    const showTurnaroundResources = useUiValue('showTurnaroundResources');
    const clientResources = useCurrentUserResources();

    const resourcesToDisplay = useMemo(
        () =>
            clientResources?.filter(
                clientResource =>
                    // Resource type is checked in ViewModeForm
                    showTurnaroundResources[clientResource.type] &&
                    // The resource type has a field in TurnaroundResourcesDto at this fieldType side
                    // e.g. ARR_HALL doesn't exist with departure
                    resourceFields[clientResource.type][origin === ResourceOrigin.ARR ? 'arrival' : 'departure'] &&
                    // The resource is configured for this client at this origin
                    // e.g. Some airport has only CHECK_IN in arrivals ?
                    (clientResource.origin === ResourceOrigin.BOTH || clientResource.origin === origin),
            ),
        [clientResources, origin, showTurnaroundResources],
    );

    if (!resources || !clientResources?.length) {
        return null;
    }

    // don't display if there are no resources names (except if resourcesProposalImpact is present)
    if (
        !resourcesToDisplay?.some(
            ({ type }) => resources[resourceFields[type][origin === ResourceOrigin.ARR ? 'arrival' : 'departure']!],
        ) &&
        !resourcesProposalImpact
    ) {
        return null;
    }

    const resourceContainerMargin = !resourcesProposalImpact
        ? origin === ResourceOrigin.ARR
            ? { ml: '3px' }
            : { mr: '3px' }
        : undefined;

    return (
        <FlightTurnaroundResourceContainer className={resourcesProposalImpact} {...resourceContainerMargin}>
            {resourcesToDisplay?.map(({ type }, i) => {
                const name = resources[resourceFields[type][origin === ResourceOrigin.ARR ? 'arrival' : 'departure']!];
                return (
                    <FlightTurnaroundResourceBox
                        key={`${name}${i}`}
                        inAlert={discrepancyAlerts?.some(
                            alert =>
                                alert.resourceName === name &&
                                alert.resourceType === type &&
                                (alert.resourceOrigin === origin || alert.resourceOrigin === ResourceOrigin.BOTH),
                        )}
                    >
                        {(type === affectedResourceType ? <s>{name}</s> : name) || ' '}
                    </FlightTurnaroundResourceBox>
                );
            })}
        </FlightTurnaroundResourceContainer>
    );
});
