import React, { memo, useCallback } from 'react';
import { styled, Typography } from '@mui/material';
import { DaySlotResourcesAllocationResponse } from '@types';
import { ReactComponent as AllocateIcon } from '../../../assets/icons/icon-allocate.svg';
import Box from '@mui/material/Box';
import { FormattedMessage } from 'react-intl';
import { Popin, PopinCloseButton } from '@components/navigation/Popin/Popin';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { KeyGetGetDayGanttTurnarounds, useMutationPostUpdateTurnaroundsResources } from '@models';
import { toast } from 'react-toastify';
import { errorToastConfig, successToastConfig } from '../../../config/constants';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import { ScheduleModes, useScheduleSet } from '../../../views/Schedule/SchedulePage/ScheduleContext';
import { useQueryClient } from 'react-query';

const StyledIcon = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 34px;
    font-size: 15px;
    border-radius: 5px;
`;

export const ResourcesAllocateResults = memo(function ResourcesAllocationResults({
    results,
    onClose,
}: {
    results: DaySlotResourcesAllocationResponse;
    onClose?: () => void;
}) {
    const setState = useScheduleSet();
    const queryClient = useQueryClient();
    const { mutateAsync: validate, isLoading } = useMutationPostUpdateTurnaroundsResources({
        onSuccess: () => {
            queryClient.invalidateQueries(KeyGetGetDayGanttTurnarounds);
            toast(<FormattedMessage id={'schedule.allocate.successToast'} />, successToastConfig);
            onClose?.();
        },
        onError: () => {
            toast(<FormattedMessage id={'schedule.allocate.errorToast'} />, errorToastConfig);
        },
    });

    const onClickValidateProposal = useCallback(
        () =>
            validate({
                turnaroundResourcesUpdateDto: Object.keys(results.allocationProposal).map(id => ({
                    id,
                    propagate: false,
                    ...results.allocationProposal[id],
                })),
            }),
        [results.allocationProposal, validate],
    );

    const onClickSetScheduleModeAllocation = useCallback(
        () => setState(s => ({ ...s, mode: ScheduleModes.ALLOCATION, modeData: results })),
        [results, setState],
    );

    const hasChanges =
        results.report?.newAllocation || results.report?.updatedAllocation || results.report?.nonAllocatedFlights;

    return (
        <Popin
            width={700}
            title={
                <>
                    <AllocateIcon />
                    &ensp;
                    <Box flex={1}>
                        <FormattedMessage id="schedule.allocate.title" />
                    </Box>
                    <PopinCloseButton onClose={onClose} />
                </>
            }
            content={
                <>
                    <Box display={'flex'} alignItems={'center'} mb={2}>
                        <StyledIcon sx={{ background: theme => theme.palette.success.main }}>
                            <Add />
                        </StyledIcon>
                        &ensp;
                        <Typography variant={'body1'} fontSize={'larger'}>
                            <FormattedMessage
                                id={'schedule.allocate.new'}
                                values={{ value: results.report?.newAllocation }}
                            />
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} mb={2}>
                        <StyledIcon sx={{ background: theme => theme.palette.warning.main }}>
                            <Edit fontSize={'small'} />
                        </StyledIcon>
                        &ensp;
                        <Typography variant={'body1'} fontSize={'larger'}>
                            <FormattedMessage
                                id={'schedule.allocate.updated'}
                                values={{ value: results.report?.updatedAllocation }}
                            />
                        </Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'} mb={2}>
                        <StyledIcon sx={{ background: theme => theme.palette.error.main }}>
                            <Close />
                        </StyledIcon>
                        &ensp;
                        <Typography variant={'body1'} fontSize={'larger'}>
                            <FormattedMessage
                                id={'schedule.allocate.nonAllocated'}
                                values={{ value: results.report?.nonAllocatedFlights }}
                            />
                        </Typography>
                    </Box>
                </>
            }
            actions={
                <>
                    <RoundButton color="primary" size="medium" variant="outlined" onClick={onClose}>
                        <FormattedMessage id="schedule.allocate.cancel" />
                    </RoundButton>
                    &emsp; &emsp; &emsp;
                    <RoundButton
                        color="primary"
                        size="medium"
                        variant="outlined"
                        onClick={onClickValidateProposal}
                        loading={isLoading}
                        disabled={isLoading || !hasChanges}
                    >
                        <FormattedMessage id="schedule.allocate.validate" />
                    </RoundButton>
                    &emsp;
                    <RoundButton
                        color="primary"
                        size="medium"
                        variant="contained"
                        onClick={onClickSetScheduleModeAllocation}
                        disabled={isLoading || !hasChanges}
                    >
                        <FormattedMessage id="schedule.allocate.verify" />
                    </RoundButton>
                </>
            }
        />
    );
});
