import { FormikTextField } from '@components/form/TextField/FormikTextField';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { LanguageSetting } from '@models';
import { Box, Divider, FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserFormData } from './Settings';
import { onEnterKeyCb } from '../../utils/dom.utils';
import { TextField } from '@components/form/TextField/TextField';

const StyledFormContainer = styled(Box)`
    display: flex;
    justify-content: center;
`;

const StyledFormSection = styled(Box)`
    width: 40%;
`;

const StyledDividerBox = styled(Box)`
    margin: 0 40px;
`;

const StyledInactiveFormBox = styled(Box)`
    opacity: 0.5;
    font-size: 24px;
    margin-bottom: 15px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
    color: ${({ theme }) => theme.palette.primary.light};
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
    display: flex;
    align-items: flex-start;
    & .MuiRadio-root,
    & .MuiCheckbox-root {
        padding: 0 5px 0 0;
    }
    & .MuiButtonBase-root.Mui-checked {
        color: white;
    }
`;

const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
    font-family: Roboto, serif;
    font-size: 16px;
    width: 50%;
`;

export const SettingsForm = memo(
    ({
        prefilledUserData,
        isLoading,
    }: {
        prefilledUserData: { firstName?: string; lastName?: string; phone?: string; email?: string };
        isLoading?: boolean;
    }) => {
        const { values, handleChange, isValid, submitForm } = useFormikContext<UserFormData>();

        return (
            <>
                <StyledFormContainer>
                    <StyledFormSection>
                        <StyledInactiveFormBox>
                            <FormattedMessage id="settings.information" />
                        </StyledInactiveFormBox>
                        <TextField
                            name={'firstName'}
                            value={prefilledUserData.firstName}
                            label={<FormattedMessage id="settings.firstName" />}
                            disabled
                            required
                        />
                        <TextField
                            name={'lastName'}
                            value={prefilledUserData.lastName}
                            label={<FormattedMessage id="settings.lastName" />}
                            disabled
                            required
                        />
                        <TextField
                            name={'email'}
                            value={prefilledUserData.email}
                            label={<FormattedMessage id="settings.email" />}
                            disabled
                            required
                        />
                        <TextField
                            name={'phone'}
                            value={prefilledUserData.phone}
                            label={<FormattedMessage id="settings.phone" />}
                            disabled
                            required
                        />
                    </StyledFormSection>
                    <StyledDividerBox>
                        <Divider orientation={'vertical'} />
                    </StyledDividerBox>
                    <StyledFormSection>
                        <Box display="flex" flexDirection="column" marginBottom="50px">
                            <Box fontSize="24px">
                                <FormattedMessage id="settings.interface" />
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                marginTop="44px"
                                fontSize="16px"
                            >
                                <Box>
                                    <FormattedMessage id="settings.language" />
                                </Box>
                                <StyledRadioGroup name="language" value={values.language} onChange={handleChange}>
                                    <StyledFormControlLabel
                                        value={LanguageSetting.FRENCH}
                                        control={
                                            <Radio color="secondary" value={LanguageSetting.FRENCH} name="language" />
                                        }
                                        label={<FormattedMessage id="settings.french" />}
                                    />
                                    <StyledFormControlLabel
                                        value={LanguageSetting.ENGLISH}
                                        control={
                                            <Radio color="secondary" value={LanguageSetting.ENGLISH} name="language" />
                                        }
                                        label={<FormattedMessage id="settings.english" />}
                                    />
                                </StyledRadioGroup>
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Box fontSize="24px">
                                <FormattedMessage id="settings.updatePassword" />
                            </Box>
                            <Box marginTop="44px">
                                <FormikTextField
                                    type="password"
                                    label={<FormattedMessage id={'settings.password'} />}
                                    name="password"
                                    required={true}
                                    autoComplete="new-password"
                                    onKeyPress={onEnterKeyCb(submitForm)}
                                />
                            </Box>
                            <Box marginTop="30px">
                                <FormikTextField
                                    type="password"
                                    label={<FormattedMessage id={'settings.repeatPassword'} />}
                                    name="confirmPassword"
                                    required={true}
                                    autoComplete="new-password"
                                    onKeyPress={onEnterKeyCb(submitForm)}
                                />
                            </Box>
                        </Box>
                    </StyledFormSection>
                </StyledFormContainer>
                <Box display="flex" justifyContent="center" height="100px" alignItems="center">
                    <RoundButton
                        disabled={!isValid || isLoading}
                        color="primary"
                        size="medium"
                        variant="contained"
                        loading={isLoading}
                        onClick={submitForm}
                    >
                        <FormattedMessage id="settings.save" />
                    </RoundButton>
                </Box>
            </>
        );
    },
);
