import { AuthState, createAuthentication } from '@innovatm/js-iam';
import { UserLoginDto } from '@types';
import { useContextSelector } from 'use-context-selector';

export const { AuthContext, useAuth, AuthProvider } = createAuthentication<UserLoginDto>();

/**
 * selectors
 */
export const selectCurrentUser = (state: AuthState<UserLoginDto>) => state?.currentUser;
export const selectCurrentUserClient = (state: AuthState<UserLoginDto>) => state?.currentUser?.client;
export const selectCurrentUserClientTrafficTypes = (state: AuthState<UserLoginDto>) =>
    state?.currentUser?.client?.trafficTypes;
export const selectCurrentUserTimezone = (state: AuthState<UserLoginDto>) => state?.currentUser?.client?.timeZoneId;
export const selectCurrentUserResources = (state: AuthState<UserLoginDto>) => state?.currentUser?.client?.resources;

/**
 * selector hooks
 */
export const useCurrentUser = () => useContextSelector(AuthContext, selectCurrentUser);
export const useCurrentUserClient = () => useContextSelector(AuthContext, selectCurrentUserClient);
export const useCurrentUserClientTrafficTypes = () =>
    useContextSelector(AuthContext, selectCurrentUserClientTrafficTypes);
export const useCurrentUserClientTimezone = () => useContextSelector(AuthContext, selectCurrentUserTimezone);
export const useCurrentUserResources = () => useContextSelector(AuthContext, selectCurrentUserResources);
