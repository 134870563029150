/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { styled } from '@mui/material';
import { ganttPxPerMs } from '@components/Schedule/Gantt/gantt.constants';
import React, { memo } from 'react';
import { bounded } from '@components/Schedule/Gantt/gantt.utils';
import { useContext } from 'use-context-selector';
import { GanttContext } from '@components/Schedule/Gantt/GanttContext';

export interface GanttSelectionData {
    from: number;
    to: number;
    flightId?: string;
    slotId?: string;
}

export const StyledGanttSelection = styled('div', {
    shouldForwardProp: () => false,
})<{ from: number; to: number; left: number }>`
    position: absolute;
    z-index: 1;
    display: flex;
    width: ${({ from, to }) => (to - from) * ganttPxPerMs}px;
    height: 100%;
    background: #ffffff30;
    margin-left: ${({ left }) => left}px;
`;

export const GanttSelection = memo(function GanttSelection({ from, to }: { from?: number; to?: number }) {
    const ganttState = useContext(GanttContext);

    if (!from || !to) {
        return null;
    }

    return (
        <StyledGanttSelection
            from={bounded(from, ganttState.from, ganttState.to)}
            to={bounded(to, ganttState.from, ganttState.to)}
            left={(bounded(from, ganttState.from, ganttState.to) - ganttState.from) * ganttPxPerMs}
        />
    );
});
