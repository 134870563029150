// Formik Field Styled checkbox
import * as React from 'react';
import { memo } from 'react';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import { Field, FieldProps } from 'formik';
import { Checkbox } from '@components/form/Checkbox/Checkbox';

export const FormikCheckbox = memo(function FormikCheckbox({
    name,
    ...rest
}: { label: React.ReactElement | string; name: string } & CheckboxProps) {
    return (
        <Field name={name}>
            {({ field }: FieldProps<boolean, any>) => (
                <Checkbox
                    name={name}
                    inputProps={{
                        name,
                        id: rest.id,
                    }}
                    value={field.value || false}
                    checked={field.value || false}
                    onChange={field.onChange}
                    {...rest}
                />
            )}
        </Field>
    );
});
