import * as React from 'react';
import { memo, useCallback, useMemo } from 'react';
import {
    Autocomplete as MuiAutocomplete,
    AutocompleteProps,
    filledInputClasses,
    Paper,
    PaperProps,
    styled,
    TextFieldProps,
    ThemeProvider,
} from '@mui/material';
import { StyledInputLabel, StyledMuiTextField, StyledTextFieldFormControl } from '@components/form/TextField/TextField';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { lightTheme } from '../../../theme';

const StyledMuiAutocomplete = styled(MuiAutocomplete)`
    & .${filledInputClasses.root} {
        padding-top: 0;
    }
`;

const LightPaper = (props: PaperProps) => (
    <ThemeProvider theme={lightTheme}>
        <Paper {...props} />
    </ThemeProvider>
);

export const Autocomplete = memo(function AutocompleteField({
    label,
    name,
    onChange,
    options,
    required,
    error,
    helperText,
    textFieldProps,
    ...autocompleteProps
}: {
    label?: React.ReactNode;
    name: string;
    onChange?: (value: string | null) => void;
    options?: string[];
    required?: boolean;
    error?: boolean;
    helperText?: React.ReactNode;
    textFieldProps?: Partial<TextFieldProps>;
} & Partial<AutocompleteProps<string, false, undefined, undefined>>) {
    const [inputValue, setInputValue] = React.useState('');
    const handleChange = useCallback(
        (event: any, newValue: string | null) => {
            onChange?.(newValue);
        },
        [onChange],
    );
    const handleInputChange = useCallback((event, newInputValue) => {
        setInputValue(newInputValue);
    }, []);
    const handleClose = useCallback(
        (e: any, reason: string) => {
            const value = e.target?.value;
            if (reason === 'blur' && options?.includes(value) && value !== '') {
                onChange?.(value);
            }
        },
        [onChange, options],
    );

    const sortedOptions = useMemo(
        () => options?.slice().sort((a, b) => a.localeCompare(b, undefined, { numeric: true })),
        [options],
    );

    return (
        <StyledTextFieldFormControl
            variant="standard"
            className={classNames({
                disabled: autocompleteProps.disabled,
            })}
            fullWidth={autocompleteProps.fullWidth}
        >
            {label && (
                <StyledInputLabel shrink htmlFor={name}>
                    <span>{label}</span>
                    {!required && (
                        <span>
                            (<FormattedMessage id="form.optional" />)
                        </span>
                    )}
                </StyledInputLabel>
            )}
            <StyledMuiAutocomplete
                id={name}
                options={sortedOptions || []} // ajouter la route
                onChange={handleChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onClose={handleClose}
                renderInput={params => (
                    <StyledMuiTextField
                        variant="filled"
                        required={required}
                        error={error}
                        helperText={helperText}
                        {...params}
                        {...textFieldProps}
                    />
                )}
                PaperComponent={LightPaper}
                selectOnFocus
                clearOnBlur
                openOnFocus={true}
                includeInputInList={true}
                fullWidth={true}
                autoHighlight={true}
                autoComplete={true}
                autoSelect={false}
                popupIcon={null}
                {...autocompleteProps}
            />
        </StyledTextFieldFormControl>
    );
});
