import { FlightTurnaroundDto, ImpactStatus } from '@types';
import { isSearchTextInFlightFields, isSearchTextInFlightResources } from '@components/Schedule/Gantt/gantt.utils';

export const filterScheduleFlight = (
    flight: FlightTurnaroundDto,
    filter: {
        search?: string;
        showCancelledRequests?: boolean;
        selectedMode?: 'projected' | 'current';
    },
) => {
    if (!filter.showCancelledRequests && flight.impactStatus === ImpactStatus.CANCELLED) {
        return false;
    }

    if (filter.selectedMode === 'current' && flight.impactStatus !== ImpactStatus.CONFIRMED) {
        return false;
    }

    if (
        filter.search?.length &&
        !(
            isSearchTextInFlightFields(flight, filter.search || '') ||
            isSearchTextInFlightResources(flight, filter.search || '')
        )
    ) {
        return false;
    }

    return true;
};

export const filterScheduleFlights = (
    flights: FlightTurnaroundDto[] = [],
    filter: {
        search?: string;
        showCancelledRequests?: boolean;
        selectedMode?: 'projected' | 'current';
    },
) => flights.filter(f => filterScheduleFlight(f, filter));
