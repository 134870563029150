import { useSetUi, useUiSet } from '../contexts/UiContext';
import { ImpactStatus, useQueryGetGetSlotStrip } from '@models';
import { useCallback } from 'react';
import { add } from 'date-fns';
import { useGoToSchedule } from './useGoToSchedule';

export const useViewSlotOccurrence = (slotId: string) => {
    const goToSchedule = useGoToSchedule();
    const setUi = useUiSet();
    const setShowCancelledRequests = useSetUi('showCancelledRequests');
    const setSelectedMode = useSetUi('selectedMode');

    const { refetch: getSlotStrip } = useQueryGetGetSlotStrip(
        { slotId: slotId, turnaroundId: undefined },
        { enabled: false },
    );

    return useCallback(() => {
        if (!slotId) {
            return;
        }

        getSlotStrip().then(({ data }) => {
            if (!data) {
                return;
            }

            const firstFlight = [...(data.data.flights || [])].shift();

            if (firstFlight) {
                const selectedDate = firstFlight.day
                    ? new Date(firstFlight.day)
                    : data.data.begin
                    ? new Date(data.data.begin)
                    : add(new Date(), { days: 1 });

                setUi(o => ({
                    ...o,
                    selectedDate,
                    selectedSlotId: slotId || '',
                    selectedFlightId: firstFlight?.id || null,
                }));
                if (data.data.impactStatus === ImpactStatus.CANCELLED) {
                    setShowCancelledRequests(true);
                }
                if (data.data.impactStatus === ImpactStatus.NEW) {
                    setSelectedMode('projected');
                }
                goToSchedule(selectedDate);
            }
        });
    }, [slotId, getSlotStrip, setUi, goToSchedule, setShowCancelledRequests, setSelectedMode]);
};
