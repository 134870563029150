import { FormikSelect } from '@components/form/Select/FormikSelect';
import { Box, Divider, formGroupClasses, MenuItem, styled } from '@mui/material';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconLanding } from '../../../assets/icons/Icon_Landing.svg';
import { ReactComponent as IconTakeOff } from '../../../assets/icons/Icon_TakeOff.svg';
import { ScheduleSlotUpdateDto } from '@types';
import { useCurrentUserClientTrafficTypes } from '../../../contexts/AuthContext.selectors';
import { FormikTimeField } from '@components/form/TimeField/FormikTimeField';
import { FormikTextField } from '@components/form/TextField/FormikTextField';
import { FormikIcaoIataTextField } from '@components/form/IcaoIataTextField/FormikIcaoIataTextField';

const StyledLine = styled(Box)`
    display: flex;
    margin-top: ${({ theme }) => theme.spacing(4)};
    & .${formGroupClasses.root} + .${formGroupClasses.root} {
        margin-left: 20px;
    }
`;

const StyledLabel = styled('span')`
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 28px;
`;

const StyledDivider = styled(Divider)`
    border-color: ${({ theme }) => theme.palette.blue.dark};
`;

const StyledBoxContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-shrink: 0;
    width: 250px;
`;

export const OccurrenceForm = memo(function OccurrenceForm() {
    const trafficTypes = useCurrentUserClientTrafficTypes();

    const { values, initialValues, setValues } = useFormikContext<ScheduleSlotUpdateDto>();

    return (
        <Box width="600px">
            <StyledLine justifyContent="space-between" alignItems="center">
                <StyledBoxContainer>
                    <FormikIcaoIataTextField
                        label={`AC Type`}
                        id="acType"
                        aria-label={`AC Type`}
                        name="acType"
                        value={values.acType}
                        required
                        onChange={e =>
                            setValues(values => ({
                                ...values,
                                acType: { ...values.acType, iata: e.target.value },
                            }))
                        }
                    />
                </StyledBoxContainer>

                <StyledBoxContainer>
                    <FormikTextField
                        label="PAX capacity"
                        id="paxCapacity"
                        aria-label="PAX capacity"
                        name="paxCapacity"
                        required
                        type="number"
                    />
                </StyledBoxContainer>
            </StyledLine>
            <StyledDivider orientation="horizontal" />
            <StyledLine justifyContent="space-between" alignItems="flex-start">
                <StyledBoxContainer>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <IconLanding />
                        <StyledLabel sx={{ marginLeft: '22px' }}>
                            <FormattedMessage id="occurrenceForm.arrival" />
                        </StyledLabel>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        mt={3}
                        width="100%"
                    >
                        <FormikTimeField
                            disabled={!initialValues.sibt}
                            name={'sibt'}
                            date={initialValues.sibt!}
                            required
                            label={'STA'}
                        />
                        <FormikSelect
                            id="arrivalType"
                            name="arrivalType"
                            label={<FormattedMessage id="occurrenceForm.flightType" />}
                            disabled={!initialValues.sibt}
                            FormControlProps={{ fullWidth: true }}
                            fullWidth
                        >
                            <MenuItem value="">-</MenuItem>
                            {trafficTypes?.map(type => (
                                <MenuItem key={type.code} value={type.code || ''}>
                                    {`${type.code} - ${type.descriptionEng}`}
                                </MenuItem>
                            ))}
                        </FormikSelect>
                    </Box>
                </StyledBoxContainer>

                <StyledDivider orientation="vertical" flexItem />

                <StyledBoxContainer>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <IconTakeOff />
                        <StyledLabel sx={{ marginLeft: '22px' }}>
                            <FormattedMessage id="occurrenceForm.departure" />
                        </StyledLabel>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        mt={3}
                        width="100%"
                    >
                        <FormikTimeField
                            disabled={!initialValues.sobt}
                            name={'sobt'}
                            date={initialValues.sobt!}
                            required
                            label={'STD'}
                        />
                        <FormikSelect
                            id="departureType"
                            name="departureType"
                            label={<FormattedMessage id="occurrenceForm.flightType" />}
                            disabled={!initialValues.sobt}
                            FormControlProps={{ fullWidth: true }}
                            fullWidth
                        >
                            <MenuItem value="">-</MenuItem>
                            {trafficTypes?.map(type => (
                                <MenuItem key={type.code} value={type.code || ''}>
                                    {`${type.code} - ${type.descriptionEng}`}
                                </MenuItem>
                            ))}
                        </FormikSelect>
                    </Box>
                </StyledBoxContainer>
            </StyledLine>
        </Box>
    );
});
