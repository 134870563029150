import React, { useCallback, useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash/fp';
import useKeypress from './useKeyPress';
import { getCursorOffsetLeft } from '@components/Schedule/Gantt/gantt.utils';

export const useHorizontalDragging = ({
    onDragEnd,
    from,
    enabled,
    longPressDuration = 1000,
    throttleDuration = 100,
}: {
    onDragEnd: (event: MouseEvent, draggingElement: HTMLDivElement) => Promise<any> | undefined;
    from: number;
    enabled?: boolean;
    longPressDuration?: number;
    throttleDuration?: number;
}): {
    // Relative clientX of the cursor (from the left scrollable container)
    cursorLeft: number | null;
    // True clientX of the cursor
    clientX: number | null;
    isDragging: boolean;
    // Ref of the element that is being dragged
    dragRef: React.MutableRefObject<HTMLDivElement | null>;
    // Props to pass to the element being dragged
    dragProps: {
        onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
        ref: React.MutableRefObject<HTMLDivElement | null>;
    };
} => {
    const draggingElement = useRef<HTMLDivElement | null>(null);
    const longPressTimer = useRef<any>(null);

    const [isDragging, setIsDragging] = useState(false);
    const [clientX, setClientX] = useState<number | null>(null);
    const [cursorLeft, setCursorLeft] = useState<number | null>(null);

    const resetDragging = useCallback(() => {
        clearTimeout(longPressTimer.current);
        setIsDragging(false);
        setCursorLeft(null);
        setClientX(null);
    }, []);

    useKeypress('Escape', isDragging && resetDragging);

    // On long press, start dragging
    const onMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (!enabled || e.button !== 0 || !draggingElement.current) {
                return;
            }

            const event = e.nativeEvent;

            clearTimeout(longPressTimer.current);
            longPressTimer.current = setTimeout(() => {
                setIsDragging(true);
                setCursorLeft(getCursorOffsetLeft(event, draggingElement.current!));
                setClientX(event.clientX);
            }, longPressDuration);
        },
        [enabled, longPressDuration],
    );

    useEffect(() => {
        if (!enabled || !draggingElement.current) {
            return;
        }

        const onMouseUp = (event: MouseEvent) => {
            clearTimeout(longPressTimer.current);

            if (!isDragging || !draggingElement.current) {
                return;
            }

            setIsDragging(false);

            onDragEnd(event, draggingElement.current)
                ?.catch(() => {})
                .finally(() => {
                    // reset element position after updating flight or cancelling update
                    setCursorLeft(null);
                    setClientX(null);
                });
        };

        const onMouseMove = throttle(throttleDuration, (event: MouseEvent) => {
            if (!isDragging || !draggingElement.current) {
                return;
            }

            setCursorLeft(getCursorOffsetLeft(event, draggingElement.current));
            setClientX(event.clientX);

            draggingElement.current.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'nearest' });
        });

        document.addEventListener('mouseup', onMouseUp);
        document.addEventListener('mousemove', onMouseMove);
        return () => {
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('mousemove', onMouseMove);
        };
    }, [enabled, from, isDragging, onDragEnd, throttleDuration]);

    return {
        dragProps: {
            onMouseDown,
            ref: draggingElement,
        },
        dragRef: draggingElement,
        cursorLeft,
        clientX,
        isDragging,
    };
};
