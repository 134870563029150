/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import {
    FlightTurnaroundDto,
    ImpactStatus,
    KeyGetGetAllSlotRequests,
    KeyGetGetDayGanttTurnarounds,
    KeyGetGetSlotStrip,
    OccurrenceDto,
    ScheduleSlotUpdateDto,
    useMutationPostCancelSlotOccurrences,
    useMutationPostConfirmSlotOccurrences,
    useMutationPostUpdateSlotOccurrences,
    WeekDay,
} from '@models';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, styled, Typography } from '@mui/material';
import { errorToastConfig } from 'config/constants';
import { useBoolean } from 'hooks/useBoolean';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { OccurrencePopin } from 'components/Occurences/OccurencePopin/OccurrencePopin';
import { ResourcesSlotOccurenceButton } from '@components/Resources/Update/ResourcesSlotOccurenceButton';
import { useSetUi, useUiValue } from '../../contexts/UiContext';
import { useGoToSchedule } from '../../hooks/useGoToSchedule';
import { format } from 'date-fns';

export const ScheduleControlsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: '100%',
    height: 120,
    background: theme.palette.blue.dark,
}));

const CancelLoadingButton = styled(LoadingButton)`
    width: 130px;
    &.Mui-disabled {
        background-color: #db4343;
        color: #fff;
    }
`;

const ConfirmLoadingButton = styled(LoadingButton)`
    width: 130px;
    &.Mui-disabled {
        background-color: #006ebb;
        color: #fff;
    }
    &.MuiLoadingButton-loading {
        background-color: transparent !important;
        color: #fff;
    }
`;

const ConfirmedLoadingButton = styled(LoadingButton)`
    width: 130px;
    &.Mui-disabled {
        border-color: #fff;
        color: #fff;
    }
`;

export const CancelSlotOccurrenceButton = React.memo(function CancelSlotOccurrenceButton({
    flight,
    selectedDate,
}: {
    flight?: FlightTurnaroundDto;
    selectedDate: Date;
}) {
    const queryClient = useQueryClient();
    const intl = useIntl();
    const setSelectedSlotId = useSetUi('selectedSlotId');

    const { mutateAsync: cancel, isLoading: isLoadingCancel } = useMutationPostCancelSlotOccurrences({
        onSuccess: () => {
            queryClient.invalidateQueries(KeyGetGetDayGanttTurnarounds);
            queryClient.invalidateQueries(KeyGetGetSlotStrip);
            queryClient.removeQueries(KeyGetGetAllSlotRequests);
        },
        onError: ({ response }) => {
            toast(
                intl.formatMessage(
                    {
                        id: `occurenceForm.error.${response?.data.errorKey}`,
                        defaultMessage: intl.formatMessage({ id: 'occurenceForm.error.default' }),
                    },
                    { message: response?.data.message || response?.data.errorKey || '' },
                ),
                errorToastConfig,
            );
        },
    });

    const onCancel = useCallback(() => {
        if (flight) {
            cancel({
                id: flight.id,
                updatePeriodDto: {
                    pattern: [format(selectedDate, 'EEEE').toUpperCase() as WeekDay],
                    begin: flight.sibt,
                    end: flight.sobt,
                    punctual: flight.punctual,
                },
            }).then(r => r.data.updatedSlotId && setSelectedSlotId(r.data.updatedSlotId));
        }
    }, [cancel, flight, selectedDate, setSelectedSlotId]);

    return (
        <CancelLoadingButton
            sx={{ width: '130px' }}
            variant={flight?.impactStatus === ImpactStatus.CANCELLED ? 'contained' : 'outlined'}
            size="large"
            color={flight?.impactStatus === ImpactStatus.CANCELLED ? 'error' : 'secondary'}
            onClick={onCancel}
            loading={isLoadingCancel}
            disabled={isLoadingCancel || flight?.impactStatus === ImpactStatus.CANCELLED}
        >
            {flight?.impactStatus === ImpactStatus.CANCELLED ? (
                <FormattedMessage id="schedule.cancelled" />
            ) : (
                <FormattedMessage id="schedule.cancel" />
            )}
        </CancelLoadingButton>
    );
});

export const ModifySlotOccurrenceButton = React.memo(function ModifySlotOccurrenceButton({
    flight,
    selectedDate,
}: {
    flight?: FlightTurnaroundDto;
    selectedDate: Date;
}) {
    const { value: opened, setTrue: open, setFalse: close } = useBoolean(false);
    const setSelectedSlotId = useSetUi('selectedSlotId');

    const queryClient = useQueryClient();
    const { mutateAsync: updateOccurence, isLoading } = useMutationPostUpdateSlotOccurrences({
        onSuccess: () => {
            close();
            queryClient.invalidateQueries(KeyGetGetDayGanttTurnarounds);
            queryClient.invalidateQueries(KeyGetGetSlotStrip);
        },
    });

    return (
        <>
            <Button variant="outlined" size="large" color="secondary" sx={{ width: '130px' }} onClick={open}>
                <FormattedMessage id="schedule.modify" />
            </Button>
            {opened && flight && (
                <OccurrencePopin
                    flight={flight}
                    onClose={close}
                    isLoading={isLoading}
                    onSubmit={(v: ScheduleSlotUpdateDto) => {
                        updateOccurence({
                            id: flight?.id,
                            confirm: true,
                            scheduleSlotUpdateDto: {
                                ...v,
                                pattern: [format(selectedDate, 'EEEE').toUpperCase() as WeekDay],
                                begin: flight?.sibt,
                                end: flight?.sobt,
                            },
                        }).then(r => {
                            if (r.data.updatedSlotId) {
                                setSelectedSlotId(r.data.updatedSlotId);
                            }
                        });
                    }}
                />
            )}
        </>
    );
});

export const ConfirmSlotOccurrenceButton = React.memo(function ConfirmSlotOccurrenceButton({
    flightId,
    confirmed,
    impactStatus,
}: {
    flightId?: string;
    confirmed?: boolean | null;
    impactStatus?: ImpactStatus | null;
}) {
    const queryClient = useQueryClient();
    const { mutateAsync: confirm, isLoading: isLoadingConfirm } = useMutationPostConfirmSlotOccurrences({
        onSuccess: () => {
            queryClient.invalidateQueries(KeyGetGetDayGanttTurnarounds);
            queryClient.invalidateQueries(KeyGetGetSlotStrip);
            queryClient.removeQueries(KeyGetGetAllSlotRequests);
        },
    });

    const onConfirm = useCallback(() => {
        if (!flightId) {
            return;
        }

        confirm({
            id: flightId,
        });
    }, [confirm, flightId]);

    return impactStatus === ImpactStatus.CANCELLED ? (
        <ConfirmedLoadingButton variant="outlined" size="large" color="secondary" loading={isLoadingConfirm} disabled>
            <FormattedMessage id="schedule.confirm" />
        </ConfirmedLoadingButton>
    ) : (
        <ConfirmLoadingButton
            variant={confirmed ? 'contained' : 'outlined'}
            size="large"
            color={confirmed ? 'primary' : 'secondary'}
            onClick={onConfirm}
            loading={isLoadingConfirm}
            disabled={isLoadingConfirm || !!confirmed}
            loadingPosition="end"
            endIcon={<React.Fragment />}
        >
            {confirmed ? <FormattedMessage id="schedule.confirmed" /> : <FormattedMessage id="schedule.confirm" />}
        </ConfirmLoadingButton>
    );
});

export const ScheduleControls = React.memo(function ScheduleControls({
    flight,
    flights,
    total,
    index,
}: {
    flight?: FlightTurnaroundDto;
    flights?: OccurrenceDto[];
    total?: number | null;
    index?: number;
}) {
    const selectedDate = useUiValue('selectedDate');
    const setSelectedFlightId = useSetUi('selectedFlightId');
    const setSelectedDate = useGoToSchedule();

    const goToIndex = useCallback(
        (index: number) => {
            const nextFlight = flights?.[index] || null;
            setSelectedFlightId(nextFlight?.id || null);
            if (nextFlight?.day) {
                setSelectedDate(new Date(nextFlight.day));
            }
        },
        [setSelectedDate, flights, setSelectedFlightId],
    );

    return (
        <ScheduleControlsBox>
            <Box display="flex" justifyContent="center" alignItems="space-between">
                <ResourcesSlotOccurenceButton flight={flight} />
                &emsp;
                <CancelSlotOccurrenceButton flight={flight} selectedDate={selectedDate} />
                &emsp;
                <ModifySlotOccurrenceButton flight={flight} selectedDate={selectedDate} />
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                height={'50px'}
                width={'200px'}
                mx={10}
            >
                <Button
                    variant="outlined"
                    size="large"
                    color="secondary"
                    disabled={!index || index === -1}
                    onClick={() => goToIndex((index || 0) - 1)}
                >
                    <ChevronLeftIcon />
                </Button>
                <Typography variant="body1" color="white" sx={{ userSelect: 'none' }}>
                    {index !== undefined ? index + 1 : 0} / {total || 0}
                </Typography>
                <Button
                    variant="outlined"
                    size="large"
                    color="secondary"
                    disabled={index === (total || 0) - 1 || index === -1}
                    onClick={() => goToIndex((index || 0) + 1)}
                >
                    <ChevronRightIcon />
                </Button>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="space-between">
                <ConfirmSlotOccurrenceButton
                    flightId={flight?.id}
                    confirmed={flight?.impactStatus === ImpactStatus.CONFIRMED}
                    impactStatus={flight?.impactStatus}
                />
            </Box>
        </ScheduleControlsBox>
    );
});
