import React, { memo } from 'react';
import { Box, formGroupClasses, styled, Typography } from '@mui/material';
import { AirportResourcesDto } from '@types';
import { useFormikContext } from 'formik';
import { ResourceFormValues } from '@components/Resources/Update/ResourcesForm/ResourcesFormContainer';
import { FormattedMessage } from 'react-intl';
import { useCurrentUserClient, useCurrentUserResources } from '../../../../contexts/AuthContext.selectors';
import { ResourcesFormFieldsItem } from '@components/Resources/Update/ResourcesForm/ResourcesFormFieldsItem';

const StyledResources = styled(Box)`
    display: flex;
    margin: ${({ theme }) => theme.spacing(4)};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    & .${formGroupClasses.root} + .${formGroupClasses.root} {
        margin-left: 20px;
    }
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const StyledTypography = styled(Typography)`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const ResourcesFormFields = memo(function ResourcesFields({
    resources,
    side,
    placeholders,
}: {
    resources?: AirportResourcesDto;
    side: 'arrival' | 'departure' | 'both';
    placeholders?: boolean;
}) {
    const userResources = useCurrentUserResources();
    const rms = useCurrentUserClient()?.rms;

    const { initialValues } = useFormikContext<ResourceFormValues>();

    return (
        <StyledResources>
            <StyledTypography variant={'h5'}>
                {side === 'both' ? (
                    <>
                        {initialValues.sibt && <FormattedMessage id={'requestForm.arrival'} />}
                        {initialValues.sibt && initialValues.sobt && ' / '}
                        {initialValues.sobt && <FormattedMessage id={'requestForm.departure'} />}
                    </>
                ) : (
                    <FormattedMessage id={`requestForm.${side}`} />
                )}
            </StyledTypography>

            {userResources?.map(({ origin, type }) => (
                <ResourcesFormFieldsItem
                    origin={origin}
                    type={type}
                    side={side}
                    resources={resources}
                    placeholders={placeholders}
                    rms={rms}
                />
            ))}
        </StyledResources>
    );
});
