import React, { memo } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ViewModeForm } from './ViewModeForm';
import { FormattedMessage } from 'react-intl';
import { PopoverMenu } from '@components/navigation/PopoverMenu/PopoverMenu';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';

const renderButton = ({ onClick }: any) => (
    <RoundButton color="primary" size="medium" variant="outlined" onClick={onClick} endIcon={<KeyboardArrowDownIcon />}>
        <FormattedMessage id="viewMode.button" />
    </RoundButton>
);

export const ViewModeButton = memo(function ViewMode() {
    return <PopoverMenu renderButton={renderButton}>{({ close }) => <ViewModeForm onClose={close} />}</PopoverMenu>;
});
