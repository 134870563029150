import React, { memo, useCallback } from 'react';
import { ListItemText, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { usePopoverValue } from '@components/navigation/PopoverMenu/PopoverMenu';

export const StripMenuItemSeeUpdateHistory = memo(function StripMenuItemSeeUpdateHistory({
    onClick,
}: {
    onClick: () => void;
}) {
    const close = usePopoverValue('close');

    const openHistoryPopin = useCallback(() => {
        close();
        onClick();
    }, [close, onClick]);

    return (
        <MenuItem onClick={openHistoryPopin}>
            <ListItemText>
                <FormattedMessage id="strip.actions.seeUpdateHistory" />
            </ListItemText>
        </MenuItem>
    );
});
