import React, { memo } from 'react';
import {
    FormControlLabel,
    formControlLabelClasses,
    FormControlLabelProps,
    Radio as MuiRadio,
    radioClasses,
    RadioGroup,
    RadioGroupProps,
    styled,
} from '@mui/material';
import { Field, FieldProps } from 'formik';

const StyledFormControlLabel = styled(FormControlLabel)`
    color: ${({ theme }) => theme.palette.primary.contrastText};
    margin: 10px 0;
    display: flex;
    align-items: flex-start;

    & .${radioClasses.root}, & .${radioClasses.root}.${radioClasses.checked} {
        padding: 0 7px;
        color: ${({ theme }) => theme.palette.blue.lighter};
    }

    &.${radioClasses.disabled} {
        opacity: 0.5;
    }

    & .${formControlLabelClasses.label} {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
    }
`;

export const Radio = memo(function Radio({
    value,
    children,
    ...rest
}: {
    value: string | boolean;
    children: React.ReactElement | string;
} & Partial<FormControlLabelProps>) {
    return (
        <StyledFormControlLabel
            value={value}
            control={<MuiRadio color="secondary" value={value} />}
            label={children}
            {...rest}
        />
    );
});

export const RadioGroupField = memo(function RadioGroupField({
    name,
    children,
    ...rest
}: { name: string; children?: React.ReactNode } & RadioGroupProps) {
    return (
        <Field name={name}>
            {({ field }: FieldProps<string>) => (
                <RadioGroup {...field} {...rest}>
                    {children}
                </RadioGroup>
            )}
        </Field>
    );
});
