import { Box, styled } from '@mui/material';

export const StripElement = styled(Box)`
    position: relative;
    flex-shrink: 0;
    border-right: 1px dashed #14517b;
    display: flex;
    align-items: center;
    padding: 20px;
    height: 60px;
    background-color: inherit;
`;
