import { Popin, PopinCloseButton } from '@components/navigation/Popin/Popin';
import { Box } from '@mui/material';
import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { TextField } from '@components/form/TextField/TextField';
import { useMutationPostUploadSsimMessage } from 'backend/gen/routes/airline-flight-schedule-web-services-api';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { errorToastConfig, successToastConfig } from '../../../config/constants';
import { KeyGetGetAllSlotRequests } from '@models';

export const SSIMFormPopin = memo(function SSIMFormPopin({ onClose }: { onClose?: () => void }) {
    const [message, setMessage] = React.useState('');

    const intl = useIntl();

    const queryClient = useQueryClient();

    const { mutateAsync: onSubmitSSIM } = useMutationPostUploadSsimMessage({
        onSuccess: ({ data }) => {
            toast(
                <Box>
                    <FormattedMessage
                        id="addRequest.uploadSuccess.impactNewSlotsCreated"
                        values={{ count: data.impactNewSlotsCreated }}
                    />
                    <br />
                    <FormattedMessage
                        id="addRequest.uploadSuccess.impactUpdateSlotsCreated"
                        values={{ count: (data.impactUpdateSlotsCreated || 0) + (data.impactUpdateSlotsUpdated || 0) }}
                    />
                    <br />
                    <FormattedMessage
                        id="addRequest.uploadSuccess.impactUpdateSlotsUpdated"
                        values={{ count: (data.impactCancelSlotsCreated || 0) + (data.impactCancelSlotsUpdated || 0) }}
                    />
                </Box>,
                successToastConfig,
            );
            queryClient.invalidateQueries(KeyGetGetAllSlotRequests);
            onClose?.();
        },
        onError: () => {
            toast(<FormattedMessage id={'addRequest.ssim.uploadError'} />, errorToastConfig);
        },
    });

    return (
        <Popin
            width={900}
            onClose={onClose}
            title={
                <>
                    <Box display="flex" flex={1}>
                        <FormattedMessage id={'addRequest.importFromSSIM'} />
                    </Box>
                    <PopinCloseButton onClose={onClose} />
                </>
            }
            content={
                <Box display="flex" alignItems="center">
                    <TextField
                        name={'ssim'}
                        label={<FormattedMessage id="addRequest.ssimMessage" />}
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        placeholder={intl.formatMessage({ id: 'addRequest.ssim.placeholder' })}
                        fullWidth
                        multiline
                        required
                        rows={10}
                    />
                </Box>
            }
            actions={
                <>
                    <RoundButton onClick={onClose} variant="outlined" size="medium">
                        <FormattedMessage id="form.cancel" />
                    </RoundButton>
                    <RoundButton
                        onClick={() => onSubmitSSIM({ ssimMessage: { message } })}
                        variant="contained"
                        size="medium"
                        disabled={!message?.trim().length}
                    >
                        <FormattedMessage id="form.submit" />
                    </RoundButton>
                </>
            }
        />
    );
});
