import React, { memo } from 'react';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import { TimeField } from '@components/form/TimeField/TimeField';

export const FormikTimeField = memo(function FormikTimeField({
    name,
    ...props
}: { name: string } & Omit<React.ComponentProps<typeof TimeField>, 'onChange' | 'value'>) {
    return (
        <Field name={name}>
            {({ field: { value }, form: { setFieldValue }, meta: { error } }) => (
                <TimeField
                    {...props}
                    name={name}
                    value={value}
                    error={!!error}
                    helperText={typeof error === 'string' && <FormattedMessage id={error} defaultMessage={error} />}
                    onChange={date => {
                        setFieldValue(name, typeof date === 'number' ? date : date.getTime());
                    }}
                />
            )}
        </Field>
    );
});
