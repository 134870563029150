import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTE_LOGIN } from 'routes';
import { useAuth } from '../../../contexts/AuthContext.selectors';

// @see https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src/App.tsx
export const RequireAuth = function ({ children }: { children: JSX.Element }) {
    const isLoggedIn = useAuth('isLoggedIn');
    const location = useLocation();

    if (!isLoggedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than droping them off on the home page.
        return <Navigate to={ROUTE_LOGIN} state={{ from: location }} />;
    }

    return children;
};
