import Clear from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Input } from '@mui/material';
import { useSetUi, useUiValue } from 'contexts/UiContext';
import React, { createRef, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

export const Search = memo(function Search() {
    const ref = createRef<HTMLInputElement>();
    const search = useUiValue('search');
    const setSearch = useSetUi('search');
    const intl = useIntl();

    const handleKeyUp = useCallback(() => {
        if (ref.current /* && (e.key === 'Enter' || e.keyCode === 13) */) {
            setSearch(ref.current.value);
        }
    }, [ref, setSearch]);

    const handleBlur = useCallback(() => {
        if (ref.current) {
            setSearch(ref.current.value);
        }
    }, [ref, setSearch]);
    const clear = useCallback(() => {
        if (ref.current) {
            ref.current.value = '';
        }
        setSearch('');
    }, [ref, setSearch]);

    return (
        <Box
            sx={{
                height: '50px',
                minWidth: '275px',
                borderRadius: theme => `${theme.shape.borderRadiusLarge}px`,
                backgroundColor: 'rgba(255,255,255,0.1)',
                padding: '13px 10px 13px 20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Input
                sx={{
                    border: 0,
                    color: theme => theme.palette.primary.contrastText,
                    '&:before, &:after': {
                        content: 'initial',
                    },
                    width: '100%',
                }}
                inputProps={{
                    sx: {
                        '&::placeholder': {
                            fontFamily: 'Roboto',
                            fontSize: '18px',
                            fontWeight: '300',
                            letterSpacing: '0',
                            lineHeight: '21px',
                            color: '#FFFFFF',
                        },
                    },
                }}
                defaultValue={search}
                inputRef={ref}
                onKeyUp={handleKeyUp}
                onBlur={handleBlur}
                placeholder={intl.formatMessage({ id: 'search.placeholder' })}
            />
            {!!search.length && (
                <Button
                    size="small"
                    onClick={clear}
                    sx={{ color: 'white', padding: '5px', minWidth: 0, borderRadius: 50 }}
                >
                    <Clear sx={{ width: 15, height: 15 }} />
                </Button>
            )}
            <SearchIcon sx={{ width: 24, height: 24 }} />
        </Box>
    );
});
