import { CodeMode, LanguageSetting, useMutationPatchUpdateUserSettings, UserLoginDto } from '@models';
import { Box, styled } from '@mui/material';
import { errorToastConfig, passwordPolicy, successToastConfig } from 'config/constants';
import { useUiValue } from 'contexts/UiContext';
import { Form, Formik } from 'formik';
import React, { memo, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { SettingsForm } from './SettingsForm';
import { useAuth, useCurrentUser } from '../../contexts/AuthContext.selectors';
import { FormattedMessage, useIntl } from 'react-intl';

const StyledSettingsContainer = styled(Box)`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: scroll;
`;

const StyledSettingsForm = styled(Box)`
    color: white;
    margin-top: 100px;
    width: 950px;
`;

export interface UserFormData {
    language: LanguageSetting;
    codeMode: CodeMode;
    password: string;
    confirmPassword: string;
}

export const Settings = memo(() => {
    const currentUser = useCurrentUser();
    const setCurrentUser = useAuth('setCurrentUser');
    const selectedCodeMode = useUiValue('selectedCodeMode');
    const intl = useIntl();

    const { mutateAsync: patchUser } = useMutationPatchUpdateUserSettings({
        onError: ({ response }) => {
            toast(
                intl.formatMessage(
                    {
                        id: `requestForm.error.${response?.data.errorKey}`,
                        defaultMessage: intl.formatMessage({ id: 'requestForm.error.default' }),
                    },
                    { message: response?.data.message || response?.data.errorKey || '' },
                ),
                errorToastConfig,
            );
        },
    });

    const [initialValues, setInitialValues] = useState(
        (): Partial<UserFormData> => ({
            password: '',
            confirmPassword: '',
            language: currentUser?.settings?.language || LanguageSetting.FRENCH,
            codeMode: currentUser?.settings?.codeMode || selectedCodeMode,
        }),
    );

    const onSubmit = useCallback(
        (values: UserFormData) => {
            patchUser({
                userSettingsUpdateDto: {
                    settings: {
                        language: values.language,
                        codeMode: values.codeMode,
                    },
                    ...(values.password && values.confirmPassword ? { password: values.password } : {}),
                },
            }).then(user => {
                setCurrentUser(user.data as UserLoginDto);
                toast(<FormattedMessage id={'settings.profileUpdated'} />, successToastConfig);
                setInitialValues(v => ({ ...v, user })); // trigger reset of formik values
            });
        },
        [setInitialValues, patchUser, setCurrentUser],
    );

    const { formatMessage } = useIntl();
    const validate = useCallback(
        values => {
            const errors: any = {};
            if (values.password.length && !values.password.match(passwordPolicy)) {
                errors.password = formatMessage({ id: 'settings.passwordPolicy' });
            }
            if (values.password.length && values.password !== values.confirmPassword) {
                errors.confirmPassword = formatMessage({ id: 'settings.passwordAreNotTheSame' });
            }
            return errors;
        },
        [formatMessage],
    );

    return (
        <StyledSettingsContainer>
            <StyledSettingsForm>
                <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                    <Form>
                        <SettingsForm
                            prefilledUserData={{
                                firstName: currentUser?.firstName || '',
                                lastName: currentUser?.lastName || '',
                                email: currentUser?.email || '',
                                phone: currentUser?.phone || '',
                            }}
                        />
                    </Form>
                </Formik>
            </StyledSettingsForm>
        </StyledSettingsContainer>
    );
});
