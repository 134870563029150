import { Nav } from '@components/navigation/Nav/Nav';
import { Search } from '@components/navigation/Search/Search';
import { Clock } from '@components/navigation/ToggleUTCLocal/Clock';
import { RequestAdd } from '@components/Requests/RequestAdd/RequestAdd';
import { ExportRequests } from '@components/Requests/RequestAdd/ExportRequests';
import { FilterRequestsByStatus } from '@components/Requests/RequestAdd/FilterRequestsByStatus';
import { Box } from '@mui/material';
import React, { memo } from 'react';
import { PageTitle } from '@components/navigation/Nav/PageTitle';
import { FormattedMessage } from 'react-intl';
import {
    Header,
    LeftComponentsHeader,
    MiddleComponentsHeader,
    RightComponentsHeader,
} from '@components/navigation/Header/Header';
import { FilterRequestsBySource } from '@components/Requests/RequestAdd/FilterRequestsBySource';

export const RequestsHeader = memo(function RequestsHeader() {
    return (
        <Header>
            <LeftComponentsHeader>
                <Nav>
                    <PageTitle>
                        <FormattedMessage id="nav.title.requests" />
                    </PageTitle>
                </Nav>
                <Box
                    margin="0 30px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="100%"
                    minWidth="95px"
                >
                    <RequestAdd />
                    &emsp;
                    <ExportRequests />
                </Box>
            </LeftComponentsHeader>
            <MiddleComponentsHeader>
                <Search />
            </MiddleComponentsHeader>
            <RightComponentsHeader>
                <Box margin="0 20px 0 0">
                    <FilterRequestsBySource />
                </Box>
                <Box margin="0 20px 0 0">
                    <FilterRequestsByStatus />
                </Box>
                <Clock />
            </RightComponentsHeader>
        </Header>
    );
});
