import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { KeyGetGetAllSlotRequests, useMutationPostUploadFlightScheduleFile, useQueryGetGetSlotFilters } from '@models';
import { Box, RadioGroup, styled, Typography } from '@mui/material';
import { useFileImport } from 'hooks/useFileImport';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { errorToastConfig, successToastConfig } from '../../../config/constants';
import { useQueryClient } from 'react-query';
import { Radio } from '@components/form/RadioGroup/RadioGroup';
import { DatePicker } from '@components/form/DatePicker/DatePicker';
import { ganttDayFormat } from '@components/Schedule/Gantt/gantt.constants';
import { format as originalFormat } from 'date-fns';

const localTimeSettings = 'local';
const utcTimeSettings = 'UTC';
const completeFlightMode = 'complete';
const partialFlightMode = 'partial';
const currentImportSeason = 'current';
const nextImportSeason = 'next';

const StyledRadioGroup = styled(RadioGroup)`
    display: flex;
    flex-direction: row;
`;

const StyledContainer = styled(Box)`
    padding: 40px;
    min-width: 355px;
`;

const StyledButtonContainer = styled(Box)`
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
`;

export const ImportTableForm = memo(function ImportTableForm({ onClose }: { onClose?: () => void }) {
    const [selectedTimeSettings, setSelectedTimeSettings] = useState<string>(localTimeSettings);
    const [selectedFlightMode, setSelectedFlightMode] = useState<string>(completeFlightMode);
    const [selectedImportSeason, setSelectedImportSeason] = useState<string>(currentImportSeason);
    const [selectedSeasonStart, setSelectedSeasonStart] = useState<Date | undefined>(undefined);
    const [selectedSeasonEnd, setSelectedSeasonEnd] = useState<Date | undefined>(undefined);
    const [selectedImportDate, setSelectedImportDate] = useState<Date>(new Date(Date.now() + 3600 * 1000 * 24));

    const queryClient = useQueryClient();

    const { data: filters } = useQueryGetGetSlotFilters();

    const currentSeasonLabel = filters?.currentSeason?.label;
    const nextSeasonLabel = filters?.nextSeason?.label;

    const { mutateAsync: uploadTableFile, isLoading: isLoadingUpload } = useMutationPostUploadFlightScheduleFile({
        onSuccess: ({ data }) => {
            toast(
                <Box>
                    <FormattedMessage
                        id="addRequest.uploadSuccess.impactNewSlotsCreated"
                        values={{ count: data.impactNewSlotsCreated }}
                    />
                    <br />
                    <FormattedMessage
                        id="addRequest.uploadSuccess.impactUpdateSlotsCreated"
                        values={{ count: (data.impactUpdateSlotsCreated || 0) + (data.impactUpdateSlotsUpdated || 0) }}
                    />
                    <br />
                    <FormattedMessage
                        id="addRequest.uploadSuccess.impactUpdateSlotsUpdated"
                        values={{ count: (data.impactCancelSlotsCreated || 0) + (data.impactCancelSlotsUpdated || 0) }}
                    />
                </Box>,
                successToastConfig,
            );
            queryClient.invalidateQueries(KeyGetGetAllSlotRequests);
            onClose?.();
        },
        onError: () => {
            toast(<FormattedMessage id={'addRequest.importTable.uploadError'} />, errorToastConfig);
        },
    });

    const { openImportPrompt } = useFileImport({
        onSelectFile: (files: File[]) => {
            uploadTableFile({
                file: files[0],
                complete: selectedFlightMode === completeFlightMode,
                localTime: selectedTimeSettings === localTimeSettings,
                season: (selectedImportSeason === currentImportSeason ? currentSeasonLabel : nextSeasonLabel) || '',
                from: originalFormat(selectedImportDate, ganttDayFormat),
            });
        },
    });

    const handleChangeTimeSettings = useCallback(e => {
        setSelectedTimeSettings(e.target.value);
    }, []);

    const handleChangeFlightMode = useCallback(e => {
        setSelectedFlightMode(e.target.value);
    }, []);

    const handleChangeImportSeason = useCallback(e => {
        setSelectedImportSeason(e.target.value);
    }, []);

    useEffect(() => {
        if (filters) {
            if (selectedImportSeason === currentImportSeason) {
                setSelectedImportDate(new Date(Date.now() + 3600 * 1000 * 24));
                setSelectedSeasonStart(new Date(filters.currentSeason.start));
                setSelectedSeasonEnd(new Date(filters.currentSeason.end));
            } else if (selectedImportSeason === nextImportSeason) {
                setSelectedImportDate(new Date(filters.nextSeason.start));
                setSelectedSeasonStart(new Date(filters.nextSeason.start));
                setSelectedSeasonEnd(new Date(filters.nextSeason.end));
            }
        }
    }, [filters, selectedImportSeason]);

    return (
        <StyledContainer>
            <StyledRadioGroup onChange={handleChangeTimeSettings} value={selectedTimeSettings}>
                <Radio value={utcTimeSettings} color="secondary" name="radio-buttons">
                    <FormattedMessage id="import.popin.UTC" />
                </Radio>
                <Radio value={localTimeSettings} color="secondary" name="radio-buttons">
                    <FormattedMessage id="import.popin.local" />
                </Radio>
            </StyledRadioGroup>
            <StyledRadioGroup onChange={handleChangeFlightMode} value={selectedFlightMode}>
                <Radio color="secondary" name="radio-buttons" value={completeFlightMode}>
                    <FormattedMessage id="import.popin.completed" />
                </Radio>
                <Radio value={partialFlightMode} color="secondary" name="radio-buttons">
                    <FormattedMessage id="import.popin.partial" />
                </Radio>
            </StyledRadioGroup>
            {filters && (
                <StyledRadioGroup onChange={handleChangeImportSeason} value={selectedImportSeason}>
                    <Radio color="secondary" name="radio-buttons" value={currentImportSeason}>
                        <FormattedMessage id="import.popin.currentSeason" values={{ season: currentSeasonLabel }} />
                    </Radio>
                    <Radio color="secondary" name="radio-buttons" value={nextImportSeason}>
                        <FormattedMessage id="import.popin.nextSeason" values={{ season: nextSeasonLabel }} />
                    </Radio>
                </StyledRadioGroup>
            )}
            <StyledDateContainer>
                <Typography variant="body1">
                    <FormattedMessage id="import.popin.importDate" />
                </Typography>
                <DatePicker
                    onChange={date => setSelectedImportDate(date || new Date())}
                    value={selectedImportDate}
                    minDate={selectedSeasonStart}
                    maxDate={selectedSeasonEnd}
                    InputProps={{
                        sx: {
                            border: '1px solid black',
                        },
                    }}
                />
            </StyledDateContainer>
            {/*<StyledLinkContainer>*/}
            {/*    <StyledLink href={'/exportFormats/template_schedule.xlsx'} variant={'body1'}>*/}
            {/*        <FormattedMessage id="import.popin.download-generic-format" />*/}
            {/*    </StyledLink>*/}
            {/*</StyledLinkContainer>*/}
            <StyledButtonContainer>
                <Box marginRight="5px">
                    <RoundButton color="primary" size="medium" variant="outlined" onClick={onClose}>
                        <FormattedMessage id="import.popin.cancel" />
                    </RoundButton>
                </Box>
                <Box marginLeft="5px">
                    <RoundButton
                        color="primary"
                        size="medium"
                        variant="contained"
                        onClick={openImportPrompt}
                        loading={isLoadingUpload}
                    >
                        <FormattedMessage id="import.popin.import" />
                    </RoundButton>
                </Box>
            </StyledButtonContainer>
        </StyledContainer>
    );
});

const StyledDateContainer = styled('div')`
    max-height: 60px;
`;
