import { DaySlotResourcesAllocationResponse } from '@types';
import * as React from 'react';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { createContext, useContext, useContextSelector } from 'use-context-selector';

export enum ScheduleModes {
    NOMINAL,
    ASSOCIATION,
    ALLOCATION,
}
export type ScheduleModeState =
    | { mode: ScheduleModes.NOMINAL }
    | { mode: ScheduleModes.ASSOCIATION; dayOnly: boolean }
    | { mode: ScheduleModes.ALLOCATION; modeData: DaySlotResourcesAllocationResponse };

export type ScheduleState = { scroll: { x: number; y: number } | null } & ScheduleModeState;

export type ScheduleModeContextState = {
    state: ScheduleState;
    setState: Dispatch<SetStateAction<ScheduleState>>;
};

export const scheduleInitialState: ScheduleState = {
    scroll: null,
    mode: ScheduleModes.NOMINAL,
};

export const ScheduleContext = createContext<ScheduleModeContextState>({
    state: scheduleInitialState,
    setState: () => {},
});

export const useSchedule = () => useContext(ScheduleContext);

export const useScheduleSet = () => useContextSelector(ScheduleContext, state => state.setState);

export const useScheduleValue = <Key extends keyof ScheduleState>(key: Key): ScheduleState[Key] =>
    useContextSelector(ScheduleContext, state => state.state[key]);

export const useSetSchedule = <Key extends keyof ScheduleState>(key: Key) => {
    const set = useScheduleSet();

    return useCallback(
        (value: React.SetStateAction<ScheduleState[Key]>) =>
            set(oldValue => {
                const newValue = typeof value === 'function' ? (value as any)(oldValue[key]) : value;

                return {
                    ...oldValue,
                    [key]: newValue,
                };
            }),
        [key, set],
    );
};

export const useSetScheduleValue = <Key extends keyof ScheduleState>(
    key: Key,
    value: React.SetStateAction<ScheduleState[Key]>,
) => {
    const set = useSetSchedule(key);

    return useCallback(() => set(value), [set, value]);
};

export const useScheduleState = <Key extends keyof ScheduleState>(
    key: Key,
): [ScheduleState[Key], React.Dispatch<React.SetStateAction<ScheduleState[Key]>>] => [
    useScheduleValue(key),
    useSetSchedule(key),
];
