import { ResourceAllocationReportDto } from '@types';
import { ResourcesProposalImpact } from '../../backend/types.custom';

const updateReportWithRejectedProposal = (
    report: ResourceAllocationReportDto,
    proposalImpact?: ResourcesProposalImpact,
) => {
    if (proposalImpact === ResourcesProposalImpact.NEW && report.newAllocation) {
        return {
            ...report,
            newAllocation: report.newAllocation - 1,
        };
    }

    if (proposalImpact === ResourcesProposalImpact.UPDATED && report.updatedAllocation) {
        return {
            ...report,
            updatedAllocation: report.updatedAllocation - 1,
        };
    }

    return report;
};

export const updateReportWithRejectedProposals = (
    report: ResourceAllocationReportDto,
    proposalImpactArrival?: ResourcesProposalImpact,
    proposalImpactDeparture?: ResourcesProposalImpact,
) => {
    report = updateReportWithRejectedProposal(report, proposalImpactArrival);
    report = updateReportWithRejectedProposal(report, proposalImpactDeparture);

    if (proposalImpactArrival && proposalImpactDeparture) {
        report = { ...report, nonAllocatedFlights: (report.nonAllocatedFlights || 0) + 1 };
    }

    return report;
};
