import React, { memo } from 'react';
import { ListItemText, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { usePopoverValue } from '@components/navigation/PopoverMenu/PopoverMenu';

export const StripMenuItemCancelOccurrences = memo(function StripMenuItemCancelOccurrences({
    onClick,
}: {
    onClick: () => void;
}) {
    const close = usePopoverValue('close');

    return (
        <MenuItem
            onClick={() => {
                close();
                onClick();
            }}
        >
            <ListItemText>
                <FormattedMessage id="strip.actions.cancelAllOccurrences" />
            </ListItemText>
            {/*{(isLoading || isLoadingStrip) && (*/}
            {/*    <ListItemIcon sx={{ marginLeft: 1 }}>*/}
            {/*        <CircularProgress size={25} color={'secondary'} />*/}
            {/*    </ListItemIcon>*/}
            {/*)}*/}
        </MenuItem>
    );
});
