import { useConfirmDialog } from '@components/confirm/useConfirmDialog';
import React, { useCallback, useEffect } from 'react';
import { styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FormattedTime } from '@components/time/FormattedTime';
import { bounded, getCursorOffsetLeft, snapped } from '@components/Schedule/Gantt/gantt.utils';
import { ganttPartialDefaultDuration, ganttPxPerMs } from '@components/Schedule/Gantt/gantt.constants';
import { useHorizontalDragging } from '../../../hooks/useHorizontalDragging';
import { FlightTurnaroundDto } from '@types';
import { Instance } from '@popperjs/core';
import { ArrowRight } from '@components/icons/ArrowRight';

const dragDurationInterval = 5 * 60 * 1000; // 5 minutes

export const useFlightDrag = ({
    flight,
    enabled,
    onUpdateFlight,
    from,
    to,
}: {
    flight: FlightTurnaroundDto;
    enabled?: boolean;
    onUpdateFlight?: (flight: FlightTurnaroundDto) => any;
    from: number;
    to: number;
}): {
    dragProps: {
        onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
        ref: React.MutableRefObject<HTMLDivElement | null>;
    };
    tooltipProps: Omit<TooltipProps, 'children'>;
    dragLeft: number | null;
    isDragging: boolean;
} => {
    const flightDuration = flight.sibt && flight.sobt ? flight.sobt - flight.sibt : ganttPartialDefaultDuration;

    const renderDragDescription = useCallback(
        (updatedStartTime: number) => (
            <>
                {flight.sibt && (
                    <Typography variant={'body1'}>
                        <FormattedMessage id={'requestForm.arrival'} /> : <FormattedTime>{flight.sibt}</FormattedTime>
                        &nbsp;
                        <ArrowRight />
                        &nbsp;<FormattedTime>{updatedStartTime}</FormattedTime>
                    </Typography>
                )}
                {flight.sobt && (
                    <Typography variant={'body1'}>
                        <FormattedMessage id={'requestForm.departure'} /> : <FormattedTime>{flight.sobt}</FormattedTime>
                        &nbsp;
                        <ArrowRight />
                        &nbsp;<FormattedTime>{updatedStartTime + flightDuration}</FormattedTime>
                    </Typography>
                )}
            </>
        ),
        [flight.sibt, flight.sobt, flightDuration],
    );

    const confirm = useConfirmDialog();
    const onDragEnd = useCallback(
        (event: MouseEvent, draggingElement: HTMLDivElement) => {
            // Length between the gantt start and the cursor
            const cursorLeft = getCursorOffsetLeft(event, draggingElement);

            const updatedStartTime = getUpdatedStartTime(cursorLeft, flight, from, to, flightDuration);
            const isSameStartTime =
                updatedStartTime &&
                (flight.sibt ? flight.sibt === updatedStartTime : flight.sobt! === updatedStartTime + flightDuration);

            if (!updatedStartTime || isSameStartTime) {
                return;
            }

            return confirm({
                title: <FormattedMessage id={'schedule.dragFlightConfirm'} />,
                description: renderDragDescription(updatedStartTime),
            }).then(() => {
                onUpdateFlight?.({
                    ...flight,
                    sibt: flight.sibt && updatedStartTime,
                    sobt: flight.sobt && updatedStartTime + flightDuration,
                });
            });
        },
        [confirm, flight, flightDuration, from, onUpdateFlight, renderDragDescription, to],
    );

    const { isDragging, dragProps, dragRef, clientX, cursorLeft } = useHorizontalDragging({
        onDragEnd,
        from,
        enabled: !!(enabled && onUpdateFlight),
        throttleDuration: 40,
    });

    const updatedStartTime = getUpdatedStartTime(cursorLeft, flight, from, to, flightDuration);
    const dragLeft = updatedStartTime && (updatedStartTime - from) * ganttPxPerMs;

    const tooltipTitle = updatedStartTime ? renderDragDescription(updatedStartTime) : '';

    // @workaround: Tooltip has a delay between updates. Solution : manually trigger a tooltip position update using virtual element
    // see https://mui.com/material-ui/react-tooltip/#virtual-element
    const popperRef = React.useRef<Instance>(null);
    useEffect(() => {
        popperRef.current?.update();
    }, [clientX]);

    return {
        isDragging,
        dragLeft,
        dragProps,
        tooltipProps: {
            disableInteractive: true,
            open: isDragging,
            title: tooltipTitle,
            PopperProps: {
                popperRef,
                anchorEl: {
                    getBoundingClientRect: () => {
                        const dragRect = dragRef.current?.getBoundingClientRect();
                        return new DOMRect(clientX || 0, dragRect?.y, dragRect?.width, dragRect?.height);
                    },
                },
            },
        },
    };
};

export const DragTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
        {props.children}
    </Tooltip>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[24],
        fontSize: 11,
    },
}));

const getUpdatedStartTime = (
    cursorLeft: number | null,
    flight: FlightTurnaroundDto,
    from: number,
    to: number,
    flightDuration: number,
): null | number => {
    if (cursorLeft == null) {
        return null;
    }

    const updatedStartTime =
        snapped(cursorLeft / ganttPxPerMs + from, dragDurationInterval) - (!flight.sibt ? flightDuration : 0);

    return (
        updatedStartTime &&
        bounded(updatedStartTime, from - (!flight.sibt ? flightDuration : 0), to - (!flight.sibt ? flightDuration : 0))
    );
};
