import { Box, styled } from '@mui/material';
import { ganttFlightRowHeight, ganttFlightRowMargin } from '@components/Schedule/Gantt/gantt.constants';

export const collapsedGanttGroupHeight = 50;
export const groupNameWidth = 65;

export const getGroupHeight = (flightsLength?: number) =>
    (ganttFlightRowHeight + ganttFlightRowMargin) * (flightsLength || 0) + ganttFlightRowMargin;

export const GanttGroup = styled(Box)<{ flightsLength?: number }>`
    position: relative;
    z-index: 1;
    width: calc(100% + 10px);
    min-height: ${collapsedGanttGroupHeight}px;
    height: ${({ flightsLength }) => getGroupHeight(flightsLength)}px;
    box-sizing: content-box;
    pointer-events: none; // allow cursor hover to pass through to OverCapacity alerts
`;
export const GanttGroupName = styled(Box)`
    position: sticky;
    top: 20px;
    bottom: 0;
`;
