import React, { memo, useCallback, useMemo } from 'react';
import { Form, useFormikContext } from 'formik';
import { ResourceOrigin, TurnaroundResourcesUpdateDto } from '@types';
import { Box, Divider, formGroupClasses, styled } from '@mui/material';
import { useBoolean } from '../../../../hooks/useBoolean';
import { useCurrentUserResources } from '../../../../contexts/AuthContext.selectors';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconInformation } from '../../../../assets/icons/Icon-Information.svg';
import { ResourcesFormPropagateCheckbox } from './ResourcesFormPropagateCheckbox';
import { resourceFields } from '@components/Schedule/Gantt/gantt.utils';
import { ResourcesFormFields } from '@components/Resources/Update/ResourcesForm/ResourcesFormFields';
import { useQueryGetGetAirportResources } from '@models';

const StyledLine = styled(Box)`
    display: flex;
    justify-content: space-between;
    & .${formGroupClasses.root} + .${formGroupClasses.root} {
        margin-left: 20px;
    }
`;

const StyledBox = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const StyledSplitInfoBox = styled(Box)`
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: white;
`;

const StyledSplitInfoMessageBox = styled(Box)`
    margin-left: 10px;
    color: white;
`;

const StyledDivider = styled(Divider)`
    border-color: ${({ theme }) => theme.palette.blue.dark};
    margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export interface ResourceFormValues extends TurnaroundResourcesUpdateDto {
    sibt?: number | null;
    sobt?: number | null;
    propagate: boolean;
}

export const ResourcesFormContainer = memo(function ResourcesFormContainer() {
    const { data: resources } = useQueryGetGetAirportResources();
    const userResources = useCurrentUserResources();
    const userResourcesOfOriginBoth = useMemo(() => {
        return userResources?.filter(resource => resource.origin === ResourceOrigin.BOTH) || [];
    }, [userResources]);

    const { values, setFieldValue, initialValues } = useFormikContext<ResourceFormValues>();

    // Used to set initial isSplit state when mounting component
    const isResourcesDifferent = useMemo(
        () =>
            // Check equality of arrival/departure fields for all resources of type BOTH
            !!(initialValues.sobt && initialValues.sibt) &&
            !userResourcesOfOriginBoth.every(
                resource =>
                    initialValues[resourceFields[resource.type].arrival!] ===
                    initialValues[resourceFields[resource.type].departure!],
            ),
        [initialValues, userResourcesOfOriginBoth],
    );

    const { value: isSplit, toggle: toggleIsSplit } = useBoolean(isResourcesDifferent);

    // Toggle split and merge arrival/departure field values if unsplitting
    const toggleSplitAndCopyArrivalValues = useCallback(() => {
        toggleIsSplit();
        // Set departure values in Formik context to equivalent arrival values
        // for resources of type BOTH when form gets merged (unsplit)
        if (isSplit && userResourcesOfOriginBoth.length) {
            userResourcesOfOriginBoth.forEach(resource => {
                const resourceField = resourceFields[resource.type];
                if (resourceField?.departure && resourceField?.arrival) {
                    setFieldValue(resourceField?.departure, values[resourceField?.arrival]);
                }
            });
        }
    }, [isSplit, setFieldValue, toggleIsSplit, userResourcesOfOriginBoth, values]);

    return (
        <Form>
            <Box width={'600px'}>
                <StyledLine flexDirection="column" alignItems="center">
                    {values.sibt && values.sobt && (
                        <StyledBox>
                            <RoundButton
                                color="secondary"
                                size="medium"
                                variant="contained"
                                onClick={toggleSplitAndCopyArrivalValues}
                            >
                                {isSplit ? (
                                    <FormattedMessage id="resourcesForm.unsplit" />
                                ) : (
                                    <FormattedMessage id="resourcesForm.splitArrivalDeparture" />
                                )}
                            </RoundButton>
                            {isSplit && (
                                <StyledSplitInfoBox>
                                    <IconInformation width="38" height="38" color="white" />
                                    <StyledSplitInfoMessageBox>
                                        <FormattedMessage id="resourcesForm.splitInformation" />
                                    </StyledSplitInfoMessageBox>
                                </StyledSplitInfoBox>
                            )}
                        </StyledBox>
                    )}
                    <StyledLine flexDirection="row" alignItems="flex-start">
                        {values.sibt && values.sobt && isSplit ? (
                            <>
                                <ResourcesFormFields side={'arrival'} resources={resources} placeholders={true} />
                                <StyledDivider orientation="vertical" flexItem />
                                <ResourcesFormFields side={'departure'} resources={resources} placeholders={true} />
                            </>
                        ) : values.sibt && values.sobt ? (
                            <ResourcesFormFields side={'both'} resources={resources} />
                        ) : values.sibt ? (
                            <ResourcesFormFields side={'arrival'} resources={resources} />
                        ) : (
                            <ResourcesFormFields side={'departure'} resources={resources} />
                        )}
                    </StyledLine>
                    <ResourcesFormPropagateCheckbox />
                </StyledLine>
            </Box>
        </Form>
    );
});
