import { useUiValue } from '../../contexts/UiContext';
import { useQueryGetGetDayGanttTurnarounds, useQueryGetGetSlotStrip } from '@models';
import { format as originalFormat } from 'date-fns';
import { ganttDayFormat } from '@components/Schedule/Gantt/gantt.constants';
import { useMemo } from 'react';
import { GanttSelectionData } from '@components/Schedule/Gantt/GanttSelection';
import { getFlightTimes } from '@components/Schedule/Gantt/gantt.utils';

export const ganttPollingInterval = 30 * 1000;

export const useSchedulePageData = (selectedDate: Date, polling: boolean) => {
    const selectedFlightId = useUiValue('selectedFlightId');
    const selectedSlotId = useUiValue('selectedSlotId');

    const { data: ganttResponse } = useQueryGetGetDayGanttTurnarounds(
        { day: originalFormat(selectedDate, ganttDayFormat) },
        {
            keepPreviousData: true,
            cacheTime: 0,
            refetchInterval: polling ? ganttPollingInterval : false,
        },
    );

    const { data: slotResponse } = useQueryGetGetSlotStrip(
        { slotId: selectedSlotId || undefined, turnaroundId: selectedFlightId || undefined },
        {
            enabled: !!(selectedSlotId || selectedFlightId),
            keepPreviousData: !!(selectedSlotId || selectedFlightId),
            cacheTime: 0,
            refetchInterval: polling ? ganttPollingInterval : false,
        },
    );

    const selectedFlights = useMemo(() => {
        if (selectedSlotId) {
            return ganttResponse?.flights.filter(ganntFlight =>
                slotResponse?.flights.find(slotFlight => slotFlight.id === ganntFlight.id),
            );
        } else if (selectedFlightId) {
            return ganttResponse?.flights.filter(f => f.id === selectedFlightId);
        } else {
            return null;
        }
    }, [ganttResponse?.flights, selectedFlightId, selectedSlotId, slotResponse?.flights]);

    const selection = useMemo(
        () =>
            selectedFlights
                ? ({
                      flightId: selectedFlights[0]?.id,
                      slotId: slotResponse?.id,
                      from: Math.min(...selectedFlights.map(flight => getFlightTimes(flight).start)),
                      to: Math.max(...selectedFlights.map(flight => getFlightTimes(flight).end)),
                  } as GanttSelectionData)
                : undefined,
        [selectedFlights, slotResponse?.id],
    );

    return { ganttResponse, slotResponse, selectedFlights, selection };
};
