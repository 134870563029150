import { memo } from 'react';
import { useFormattedTime } from '@components/time/useFormattedTime';

export const FormattedTime = memo(function FormattedTime({
    children,
    format = 'HH:mm',
}: {
    children: number | Date;
    format?: string;
}) {
    const formatTime = useFormattedTime();

    return <>{formatTime(children, format)}</>;
});
