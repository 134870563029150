import React, { memo } from 'react';
import { FormHelperText } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';

export const FormikErrorMessage = memo(function FormikErrorMessage({ name }: { name: string }) {
    const { errors } = useFormikContext();

    if (!(name in errors)) {
        return null;
    }

    return (
        <FormHelperText error={true}>
            <FormattedMessage id={errors[name]} />
        </FormHelperText>
    );
});
