import * as React from 'react';
import { ComponentProps, memo } from 'react';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Autocomplete } from '@components/form/Autocomplete/Autocomplete';

export const FormikAutocomplete = memo(function FormikAutocomplete({
    name,
    onChange,
    ...autocompleteProps
}: {
    name: string;
    onChange?: (value: string | null) => void;
} & Omit<Partial<ComponentProps<typeof Autocomplete>>, 'onChange'>) {
    return (
        <Field name={name}>
            {({ field: { value }, form: { setFieldValue }, meta: { error } }) => (
                <Autocomplete
                    name={name}
                    value={value}
                    onChange={v => {
                        setFieldValue(name, v);
                        onChange?.(v);
                    }}
                    error={!!error}
                    helperText={typeof error === 'string' && <FormattedMessage id={error} defaultMessage={error} />}
                    {...autocompleteProps}
                />
            )}
        </Field>
    );
});
