/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';
import {
    ganttFlightRowHeight,
    ganttPartialDefaultWidth,
    ganttPxPerMs,
} from '@components/Schedule/Gantt/gantt.constants';

const FlightTurnaroundInitialBox = styled(Box)<{
    withoutSIBT?: boolean;
    withoutSOBT?: boolean;
}>(({ withoutSIBT, withoutSOBT, theme }) => [
    {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: ganttPartialDefaultWidth,
        height: ganttFlightRowHeight,
        overflow: 'visible',
        border: '2px dashed #FFFFFF',
        background: theme.palette.background.default,
        opacity: 0.3,
        borderRadius: `${theme.shape.borderRadiusLarge}px`,
        pointerEvents: 'auto',
        cursor: 'pointer',
    },
    withoutSIBT && {
        maskImage: `linear-gradient(90deg, rgba(0,0,0,0) 10%, rgba(0,0,0,1) 60%,  rgba(0,0,0,1))`,
    },
    withoutSOBT && {
        maskImage: 'linear-gradient(90deg, rgba(0,0,0,1), rgba(0,0,0,1) 40%, rgba(0,0,0,0) 90%)',
    },
]);
export const FlightTurnaroundInitial = React.memo(function FlightTurnaroundInitial({
    from,
    to,
    children,
    ...props
}: {
    from?: number | null;
    to?: number | null;
    children?: React.ReactNode;
} & BoxProps) {
    const duration = from && to ? to - from : null;

    const width = duration ? duration * ganttPxPerMs : ganttPartialDefaultWidth;

    return (
        <FlightTurnaroundInitialBox
            sx={{ width: width }}
            withoutSOBT={!!(from && !to)}
            withoutSIBT={!!(to && !from)}
            {...props}
        >
            {children}
        </FlightTurnaroundInitialBox>
    );
});
