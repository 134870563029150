import { IcaoIata } from '@components/IcaoIata';
import { ReactComponent as ControlledSvgIcon } from '../../assets/icons/icon-controlled.svg';
import { GanttStripDto, IcaoIataDto, ModificationDemandDto, SlotUpdateDto, SlotVersionDto } from '@models';
import { Box, styled, Typography } from '@mui/material';
import { getDayOfYear } from 'date-fns';
import React, { memo } from 'react';
import { FormattedTime } from '@components/time/FormattedTime';
import { StripElement } from '@components/Strip/StripElement';
import { ArrowRight } from '@components/icons/ArrowRight';

const StyledTurnaroundBox = styled(StripElement)`
    min-width: 250px;
    padding-right: 10px;
    padding-left: 10px;
    justify-content: center;
`;

const StyledDayDiff = styled('div')`
    font-size: 10px;
    position: absolute;
    bottom: -8px;
    left: 13px;
    opacity: 0.8;
    font-weight: 400;
`;

const StyledTurnaroundUpdateBox = styled(StyledTurnaroundBox)`
    min-width: 251px;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.primary.light};
    top: 61px;
    margin-left: -1px;
    border-left: 1px dashed ${({ theme }) => theme.palette.blue.lightBg};
`;

const StripTurnaroundInfosLabel = memo(
    ({
        arrivalControlled,
        departureControlled,
        sobt,
        sibt,
        adep,
        ades,
    }: {
        arrivalControlled?: boolean | null;
        departureControlled?: boolean | null;
        sibt?: number | null;
        sobt?: number | null;
        adep?: IcaoIataDto | null;
        ades?: IcaoIataDto | null;
    }) => {
        const dayDiff = (sobt && sibt && Math.abs(getDayOfYear(sobt) - getDayOfYear(sibt))) || null;

        return (
            <>
                <Typography
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                    fontFamily="Roboto"
                    fontWeight="bold"
                    fontSize="16px"
                    width={110}
                >
                    {arrivalControlled && <ControlledSvgIcon style={{ opacity: 0.9 }} width={14} height={14} />}
                    &ensp;
                    <IcaoIata {...adep} /> {sibt && <FormattedTime>{sibt}</FormattedTime>}
                </Typography>
                <Typography fontFamily="Roboto" fontWeight="bold">
                    <ArrowRight />
                </Typography>
                <Typography
                    display={'flex'}
                    alignItems={'center'}
                    fontFamily="Roboto"
                    fontWeight="bold"
                    fontSize="16px"
                    width={110}
                    position="relative"
                >
                    {sobt && <FormattedTime>{sobt}</FormattedTime>} <IcaoIata {...ades} />
                    {dayDiff && <StyledDayDiff>J+{dayDiff}</StyledDayDiff>}
                    &ensp;
                    {departureControlled && <ControlledSvgIcon style={{ opacity: 0.9 }} width={14} height={14} />}
                </Typography>
            </>
        );
    },
);

export const StripTurnaroundInfos = memo(
    ({
        slot,
        slotUpdate,
    }: {
        slot?: GanttStripDto | ModificationDemandDto | SlotVersionDto | null;
        slotUpdate?: SlotUpdateDto | null;
    }) => {
        return (
            <Box position="relative">
                <StyledTurnaroundBox>
                    <StripTurnaroundInfosLabel
                        arrivalControlled={slot?.arrivalControlled}
                        departureControlled={slot?.departureControlled}
                        sibt={slot?.sibt}
                        sobt={slot?.sobt}
                        adep={slot?.adep}
                        ades={slot?.ades}
                    />
                </StyledTurnaroundBox>
                {slotUpdate?.turnaround && (
                    <StyledTurnaroundUpdateBox>
                        <StripTurnaroundInfosLabel
                            arrivalControlled={slot?.arrivalControlled}
                            departureControlled={slot?.departureControlled}
                            sibt={slotUpdate.turnaround.sibt}
                            sobt={slotUpdate.turnaround.sobt}
                            adep={slotUpdate.turnaround.adep}
                            ades={slotUpdate.turnaround.ades}
                        />
                    </StyledTurnaroundUpdateBox>
                )}
            </Box>
        );
    },
);
