/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { PopoverMenuIconArrowDown } from '@components/navigation/PopoverMenu/PopoverMenu';
import React, { ComponentProps } from 'react';

export const FilterRequestsButton = React.memo(function FilterRequestsButton({
    opened,
    children,
    ...rest
}: {
    opened: boolean;
    children?: React.ReactNode;
} & ComponentProps<typeof RoundButton>) {
    return (
        <RoundButton
            {...rest}
            size="large"
            sx={{
                textTransform: 'none',
                height: '50px',
                minWidth: '255px',
                border: '1px solid #245F88',
                borderRadius: '25px',
                color: theme => theme.palette.text.secondary,
                fontSize: '18px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 20px 0 30px',
            }}
        >
            {children}
            &emsp;
            <PopoverMenuIconArrowDown reversed={opened} />
        </RoundButton>
    );
});
