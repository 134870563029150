import { DemandStatus, GanttStripDto, ImpactStatus, ModificationDemandDto } from '@models';
import { Box, styled } from '@mui/material';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { STRIP_HEIGHT, STRIP_TITLE_HEIGHT, STRIP_WITH_UPDATE_HEIGHT } from './strip.constant';
import { StripAircraftInfos } from './StripAircraftInfos';
import { StripDatesAndPattern } from './StripDatesAndPattern';
import { StripImpactStatusBadge } from './StripImpactStatusBadge';
import { StripTrafficTypesInfos } from './StripTrafficTypesInfos';
import { StripTurnaroundInfos } from './StripTurnaroundInfos';
import { StripFlightNumbers } from '@components/Strip/StripFlightNumbers';
import { StripName } from '@components/Strip/StripName';

const StyledStrip = styled(Box)<{ highlighted?: boolean }>(({ highlighted, theme }) => ({
    '@keyframes highlight': {
        from: { backgroundColor: theme.palette.warning.light },
        to: { backgroundColor: theme.palette.primary.light },
    },
    animation: highlighted ? `highlight 5s normal forwards ease-in` : 'none',
    backgroundColor: theme.palette.primary.light,
    height: STRIP_HEIGHT,
    borderRadius: `${theme.shape.borderRadiusLarge}px`,
    filter: 'drop-shadow(0 3px 6px rgba(0,0,0,0.3))',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: 16,
}));

const StripAndTitleBox = styled(Box)<{ forceDisplayStatus?: boolean; updates?: boolean; dim?: boolean }>(
    ({ forceDisplayStatus, updates, dim }) => ({
        display: 'flex',
        justifyContent: forceDisplayStatus ? 'center' : 'flex-start',
        alignItems: forceDisplayStatus ? 'flex-start' : 'center',
        flexDirection: forceDisplayStatus ? 'column' : 'row',
        marginBottom: updates ? `${STRIP_HEIGHT}px` : 0, // Takes the place of slot updates bellow the strip
        opacity: dim ? 0.5 : 1,
    }),
);

const StyledPending = styled(Box)(({ theme }) => ({
    height: STRIP_HEIGHT,
    width: 140,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.light,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '16px',
}));

export const Strip = memo(function Strip({
    slotStripDto,
    forceDisplayStatus,
    dimCompleted,
    highlighted,
    demandStatus,
    renderStripMenu,
}: {
    slotStripDto: GanttStripDto | ModificationDemandDto;
    forceDisplayStatus?: boolean;
    // reduce opacity if slot status is complete
    dimCompleted?: boolean;
    highlighted?: boolean;
    demandStatus?: DemandStatus;
    renderStripMenu?: (props: {
        slot: GanttStripDto | ModificationDemandDto;
        disabled?: boolean;
    }) => React.ReactElement | null;
}) {
    const { slotUpdate, nbPending, nbTotal, impactStatus } = slotStripDto;

    const hasSlotUpdate = slotUpdate?.aircraft || slotUpdate?.turnaround || slotUpdate?.types;

    if (!slotStripDto.id) {
        return null;
    }

    return (
        <Box
            height={hasSlotUpdate ? STRIP_WITH_UPDATE_HEIGHT : STRIP_HEIGHT}
            display="flex"
            position="relative"
            flexShrink={0}
        >
            <StripAndTitleBox
                forceDisplayStatus={forceDisplayStatus}
                updates={!!hasSlotUpdate}
                dim={!!dimCompleted && demandStatus === DemandStatus.COMPLETED}
            >
                {(forceDisplayStatus || impactStatus) && (
                    <StripName
                        impactStatus={impactStatus}
                        // cancels the space taken by the title
                        marginTop={forceDisplayStatus ? `-${STRIP_TITLE_HEIGHT}px` : 0}
                        // align vertically with the start of the strip main body
                        marginLeft={forceDisplayStatus ? '45px' : 0}
                        width={forceDisplayStatus ? undefined : 120}
                        justifyContent={forceDisplayStatus ? undefined : 'flex-end'}
                    />
                )}
                <Box display={'flex'} alignItems={'center'} flexShrink={0}>
                    &emsp;
                    {impactStatus && <StripImpactStatusBadge impactStatus={impactStatus} />}
                    &emsp;
                    <StyledStrip highlighted={highlighted}>
                        <StripFlightNumbers
                            arrival={slotStripDto.arrivalFlightNumberFull}
                            departure={slotStripDto.departureFlightNumberFull}
                        />
                        <StripDatesAndPattern slot={slotStripDto} />
                        <StripAircraftInfos slot={slotStripDto} slotUpdate={slotUpdate} />
                        <StripTurnaroundInfos slot={slotStripDto} slotUpdate={slotUpdate} />
                        <StripTrafficTypesInfos slot={slotStripDto} slotUpdate={slotUpdate} />
                    </StyledStrip>
                </Box>
            </StripAndTitleBox>

            <Box height={STRIP_HEIGHT} minWidth={20} display="flex" justifyContent="center" alignItems="center">
                {renderStripMenu?.({ slot: slotStripDto })}
            </Box>

            {(forceDisplayStatus || (impactStatus && impactStatus !== ImpactStatus.CONFIRMED)) && (
                <StyledPending>
                    {slotStripDto.nbPending ? (
                        <FormattedMessage
                            id="strip.pending"
                            values={{
                                nbPending: nbPending,
                                nbTotal: nbTotal,
                            }}
                        />
                    ) : (
                        <FormattedMessage id="strip.nopending" />
                    )}
                </StyledPending>
            )}
        </Box>
    );
});
