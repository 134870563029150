import React, { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../config/constants';
import { ROUTE_LOGIN } from '../../routes';
import { ForgotPasswordPage, LoginPageLink } from '@innovatm/js-iam';
import { AuthContext } from '../../contexts/AuthContext.selectors';
import { Box, styled } from '@mui/material';

const logoImg = require('../../assets/images/InnovATM_AirportKeeper-Web-dark.png');

export const StyledRoot = styled(Box)`
    display: flex;
    overflow: hidden;
    height: 100%;
`;
export const StyledLeftSide = styled(Box)`
    width: 50%;
    background: #106ebb;
`;
export const StyledRightSide = styled(Box)`
    display: flex;
    justify-content: left;
    align-items: center;
    width: 50%;
    padding-left: 4%;
    background: #0c456c;
`;

const StyledLogo = styled(Box)`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    img {
        margin: auto 12% auto auto;
        width: 370px;
    }
`;

export const ForgottenPasswordRoute = memo(function ForgottenPasswordRoute() {
    const intl = useIntl();

    const handleForgotPasswordErrors = useCallback(error => {
        const message = error?.response?.status === 404 ? 'pages.forgot.404' : 'pages.forgot.4xx';
        toast.error(<FormattedMessage id={message} />, {
            ...errorToastConfig,
            toastId: 'authError',
        });
    }, []);

    return (
        <StyledRoot>
            <StyledLeftSide>
                <StyledLogo>
                    <img alt={'Logo'} src={logoImg} />
                </StyledLogo>
            </StyledLeftSide>
            <StyledRightSide>
                <ForgotPasswordPage
                    authContext={AuthContext as any}
                    onError={handleForgotPasswordErrors}
                    links={
                        <LoginPageLink to={ROUTE_LOGIN}>
                            {intl.formatMessage({ id: 'pages.forgot.back-to-login' })}
                        </LoginPageLink>
                    }
                />
            </StyledRightSide>
        </StyledRoot>
    );
});
