import { GanttStripDto, ScheduleSlotUpdateDto, WeekDay } from '@models';
import { defaultIcaoIata } from '../../config/constants';
import { Formik } from 'formik';
import React, { memo, useCallback, useState } from 'react';
import { array, boolean, number, object, ref, string } from 'yup';
import { RequestPopinFormValues } from './ModifySlotForm';
import { eachDayOfInterval } from 'date-fns';
import { getISODay } from 'date-fns/fp';

export const icaoIataShape = object().shape({
    iata: string().test({
        test: v => !v?.length || v.length === 2 || v.length === 3,
        message: 'requestForm.iataLength23',
    }),
    icao: string().test({
        test: v => !v?.length || v.length === 3 || v.length === 4,
        message: 'requestForm.icaoLength34',
    }),
});

const icaoIataRequiredShape = icaoIataShape.test({
    name: 'airline',
    exclusive: true,
    test: (value, { parent: { icaoIata } }) => !!value[icaoIata],
    message: 'form.required',
});

export const ValidationSchema = object().shape({
    punctual: boolean(),
    icaoIata: string().oneOf(['iata', 'icao']),
    begin: number().when('punctual', {
        is: true,
        then: number().when('arrivalFlight', { is: true, then: number().required('form.required') }),
        otherwise: number().required('form.required'),
    }),
    end: number().when('punctual', {
        is: true,
        then: number().when('departureFlight', { is: true, then: number().required('form.required') }),
        otherwise: number().required('form.required'),
    }),
    pattern: array().when(['begin', 'end', 'punctual'], {
        is: (begin, end, punctual) => begin && end && !punctual,
        then: array().test(
            'pattern',
            'requestForm.error.PERIOD_INCOHERENT',
            (pattern: WeekDay[] | undefined, { resolve }) => {
                // Selected pattern days must be among the date range days
                // i.e. cannot select Monday for example if the date range is from Tuesday to Friday
                const start = resolve(ref<number | undefined>('begin'));
                const end = resolve(ref<number | undefined>('end'));

                if (!start || !end) {
                    return false;
                }

                const daysInDateRange = eachDayOfInterval({ start, end });
                if (daysInDateRange.length >= 7 && pattern?.length) {
                    // 7 days = a week = all 7 weekdays can be selected in pattern
                    return true;
                }

                if (!pattern?.length || !daysInDateRange.length) {
                    // No pattern selected or no date range
                    return false;
                }

                // ISO days start at 1=monday
                const weekdaysInRange = daysInDateRange.map(day => getISODay(day) - 1);
                // pattern days enum keys starts at 0=monday
                const weekdaysInPattern = pattern.map(day => Object.keys(WeekDay).indexOf(day));

                return weekdaysInPattern.every(day => weekdaysInRange.includes(day));
            },
        ),
    }),
    airline: icaoIataShape.when('punctual', {
        is: true,
        then: icaoIataShape,
        otherwise: icaoIataRequiredShape,
    }),
    registrationNumber: string()
        .nullable()
        .when('punctual', {
            is: true,
            then: string().required('form.required'),
        }),
    acType: icaoIataShape.when('punctual', {
        is: true,
        then: icaoIataShape,
        otherwise: icaoIataRequiredShape,
    }),
    paxCapacity: number(),
    adep: icaoIataShape.when('arrivalFlight', { is: true, then: icaoIataRequiredShape }),
    sibt: string().when('arrivalFlight', { is: true, then: string().nullable().required('form.required') }),
    arrivalFlightNumber: string().when('arrivalFlight', { is: true, then: string().required('form.required') }),
    ades: icaoIataShape.when('departureFlight', { is: true, then: icaoIataRequiredShape }),
    sobt: string().when('departureFlight', { is: true, then: string().nullable().required('form.required') }),
    departureFlightNumber: string().when('departureFlight', { is: true, then: string().required('form.required') }),
});

export const ModifySlotFormContainer = memo(function RequestPopinFormContainer({
    slot,
    onSubmit,
    children,
}: {
    slot?: GanttStripDto;
    onSubmit?: (values: ScheduleSlotUpdateDto) => void;
    children?: React.ReactNode;
}) {
    const handleSubmit = useCallback(
        (values: RequestPopinFormValues) => {
            onSubmit?.({
                airline: values.airline,
                acType: values.acType,
                paxCapacity: values.paxCapacity,
                begin: values.begin,
                end: values.end,
                pattern: values.pattern,
                punctual: values.punctual,
                ...(values.punctual
                    ? {
                          registrationNumber: values.registrationNumber,
                      }
                    : null),
                ...(values.arrivalFlight
                    ? {
                          arrivalFlightNumber: values.arrivalFlightNumber,
                          adep: values.adep,
                          sibt: values.sibt,
                          arrivalType: values.arrivalType,
                      }
                    : null),
                ...(values.departureFlight
                    ? {
                          departureFlightNumber: values.departureFlightNumber,
                          ades: values.ades,
                          sobt: values.sobt,
                          departureType: values.departureType,
                      }
                    : null),
            });
        },
        [onSubmit],
    );

    const [initialValues] = useState(() => ({
        ...slot,
        punctual: !!slot?.punctual,
        arrivalFlight: !slot || !!Object.keys(slot.adep || {}).length,
        departureFlight: !slot || !!Object.keys(slot.ades || {}).length,
        icaoIata: defaultIcaoIata,
    }));

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
            validateOnBlur={true}
            validateOnChange={true}
            validateOnMount={true}
        >
            {children}
        </Formik>
    );
});
