import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    paperClasses,
    styled,
    svgIconClasses,
} from '@mui/material';
import React, { ComponentProps, memo, ReactNode } from 'react';

const StyledContainer = styled(Dialog)<{ width?: number }>`
    & .${paperClasses.root} {
        width: ${({ width }) => width}px;
        min-width: initial;
        max-width: initial;
        border: 1px solid #b6d2e5;
        border-radius: ${({ theme }) => theme.shape.borderRadiusExtraLarge}px;
        background-color: ${({ theme }) => theme.palette.blue.darkest};
        box-shadow: ${({ theme }) => theme.shadows[24]};
        background-image: none;
    }
`;

const StyledTitle = styled(DialogTitle)`
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.palette.primary.contrastText};
    font-size: 32px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 38px;

    & > .${svgIconClasses.root}:first-of-type {
        width: 30px;
        height: 30px;
        margin: 0 20px;
    }
`;

const StyledDialogContent = styled(DialogContent)`
    overflow-y: hidden;
    padding: 0 100px;
    color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const StyledDialogActions = styled(DialogActions)`
    margin: 50px 100px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.palette.primary.contrastText};
`;
export const PopinCloseButton = memo(({ onClose }: { onClose?: () => void }) => (
    <IconButton onClick={onClose} sx={{ flexShrink: 0 }}>
        <CloseIcon sx={{ width: 30, height: 30 }} />
    </IconButton>
));

export const Popin = memo(function Popin({
    title,
    content,
    actions,
    ...rest
}: Omit<Partial<ComponentProps<typeof StyledContainer>>, 'title'> & {
    title?: ReactNode;
    content?: ReactNode;
    actions?: ReactNode;
}) {
    return (
        <StyledContainer open={true} scroll="body" {...rest}>
            {title && <StyledTitle>{title}</StyledTitle>}
            {content && <StyledDialogContent>{content}</StyledDialogContent>}
            {actions && <StyledDialogActions>{actions}</StyledDialogActions>}
        </StyledContainer>
    );
});
