/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { useFormikContext } from 'formik';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { RequestPopinFormValues } from './ModifySlotForm';

export const ModifySlotFormActions = React.memo(function RequestPopinActions({
    onSubmit,
    confirmText,
    onCancel,
}: {
    onSubmit?: (values: RequestPopinFormValues, isConfirm?: boolean) => void;
    confirmText?: ReactNode;
    onCancel?: () => void;
}) {
    const { isValid, submitForm, values } = useFormikContext<RequestPopinFormValues>();

    return (
        <>
            {onCancel === undefined ? (
                <RoundButton
                    onClick={() => onSubmit?.(values, true)} // bypass formik submit handler to send additional params
                    variant="outlined"
                    size="medium"
                    disabled={!isValid}
                >
                    <FormattedMessage id="requestForm.createAndConfirm" />
                </RoundButton>
            ) : (
                <RoundButton onClick={onCancel} variant="outlined" size="medium">
                    <FormattedMessage id="requestForm.cancel" />
                </RoundButton>
            )}
            <RoundButton type={'submit'} variant="contained" size="medium" disabled={!isValid} onClick={submitForm}>
                {confirmText ? confirmText : <FormattedMessage id="requestForm.create" />}
            </RoundButton>
        </>
    );
});
