import React, { memo } from 'react';
import { HistoryPageDto } from '@types';
import { SlotHistoryContentSlotVersion } from '@components/SlotHistory/SlotHistoryContentSlotVersion';
import { Box } from '@mui/material';

export const SlotHistoryContent = memo(function SlotHistoryContent({ slotHistory }: { slotHistory: HistoryPageDto }) {
    // Display versions from newest -> oldest
    const versionsReversed = [...slotHistory.versions].reverse();

    return (
        <Box paddingBottom={'20px'}>
            {versionsReversed.map((version, index) => (
                <SlotHistoryContentSlotVersion slotVersion={version} key={index} />
            ))}
        </Box>
    );
});
