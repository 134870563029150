import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CalendarPicker } from '@mui/lab';
import { Box, IconButton, styled } from '@mui/material';
import add from 'date-fns/add';
import React, { memo, useCallback } from 'react';
import { PopoverMenu } from '../PopoverMenu/PopoverMenu';
import { useDateFnsFormat } from '../../../hooks/useDateFnsFormat';

// @see https://date-fns.org/v2.26.0/docs/format
const DATE_FORMAT = 'dd LLL y';

const iconStyle = {
    color: 'inherit',
    width: '36px',
    height: '36px',
};

const dayBoxHeight = 19;
const DayBox = styled('div')`
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    height: ${dayBoxHeight}px;
    line-height: ${dayBoxHeight}px;
    text-align: center;
    text-transform: uppercase;
`;

const DateBox = styled('div')`
    color: #ffffff;
    font-family: Roboto, serif;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 33px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    flex-shrink: 0;
`;
const NavBox = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${dayBoxHeight}px; // ensures symmetry with DayBox
    & .Mui-selected {
        color: white !important;
    }
`;

const HandleIconButton = styled(IconButton)(({ theme }) => ({
    padding: 0,
    width: 36,
    height: 36,
    color: theme.palette.blue.lightest,
}));

export const SelectedDate = memo(function SelectedDate({
    disabled,
    value,
    onChange,
}: {
    disabled?: boolean;
    value: Date;
    onChange: (v: Date) => void;
}) {
    const format = useDateFnsFormat();

    const handleNext = useCallback(() => onChange(add(value, { days: 1 })), [value, onChange]);

    const handlePrevious = useCallback(() => onChange(add(value, { days: -1 })), [value, onChange]);

    const handleNextWeek = useCallback(() => onChange(add(value, { days: 7 })), [value, onChange]);

    const handlePreviousWeek = useCallback(() => onChange(add(value, { days: -7 })), [value, onChange]);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
            <DayBox>{format(value, 'EEEE')}</DayBox>
            <NavBox>
                <HandleIconButton onClick={handlePreviousWeek} disabled={disabled}>
                    <ChevronLeftIcon
                        sx={{
                            ...iconStyle,
                            marginRight: '-13px',
                        }}
                    />
                    <ChevronLeftIcon
                        sx={{
                            ...iconStyle,
                            marginLeft: '-13px',
                        }}
                    />
                </HandleIconButton>
                <HandleIconButton onClick={handlePrevious} disabled={disabled}>
                    <ChevronLeftIcon sx={iconStyle} />
                </HandleIconButton>
                &emsp;
                <PopoverMenu
                    renderButton={({ onClick }) => (
                        <DateBox onClick={onClick}>{format(value, DATE_FORMAT).replace('.', '')}</DateBox>
                    )}
                >
                    {({ close }) => (
                        <CalendarPicker
                            disabled={disabled}
                            date={value}
                            onChange={date => {
                                close();
                                if (date) {
                                    onChange(date);
                                }
                            }}
                        />
                    )}
                </PopoverMenu>
                &emsp;
                <HandleIconButton sx={{ padding: 0 }} onClick={handleNext} disabled={disabled}>
                    <ChevronRightIcon sx={iconStyle} />
                </HandleIconButton>
                <HandleIconButton onClick={handleNextWeek} disabled={disabled}>
                    <ChevronRightIcon
                        sx={{
                            ...iconStyle,
                            marginRight: '-13px',
                        }}
                    />
                    <ChevronRightIcon
                        sx={{
                            ...iconStyle,
                            marginLeft: '-13px',
                        }}
                    />
                </HandleIconButton>
            </NavBox>
        </Box>
    );
});
