/**
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { FlightTurnaroundDto, ResourceType, TurnaroundResourcesDto } from '@models';
import { ganttPartialDefaultDuration } from './gantt.constants';
import { ONE_HOUR } from '../../../config/constants';
import { ResourcesProposalImpact } from '../../../backend/types.custom';

export const bounded = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);
export const snapped = (value: number, step: number) => Math.round(value / step) * step;

// Get the cursor left offset from the container of the dragged element, not the viewport
// Should increase as the mouse moves to the right and as we scroll to the right
export const getCursorOffsetLeft = (event: MouseEvent, draggedElement: HTMLElement) => {
    const cursorOffsetFromViewport = event.pageX;

    // To get the scrollLeft of the scrollable container
    // we use getBoundingClientRect on  the dragged element instead of searching for the container
    const elementOffsetFromContainer = draggedElement.offsetLeft;
    const elementOffsetFromViewport = draggedElement.getBoundingClientRect().left;
    const scrollLeft = elementOffsetFromContainer - elementOffsetFromViewport;

    return cursorOffsetFromViewport + scrollLeft;
};

export interface ResourceTypeFields {
    arrival?: keyof TurnaroundResourcesDto;
    departure?: keyof TurnaroundResourcesDto;
}

export const resourceFields: Record<ResourceType, ResourceTypeFields> = {
    [ResourceType.TERMINAL]: { arrival: 'arrivalTerminal', departure: 'departureTerminal' },
    [ResourceType.PARKING]: { arrival: 'arrivalParking', departure: 'departureParking' },
    [ResourceType.ARR_HALL]: { arrival: 'arrivalHall' },
    [ResourceType.CHECK_IN]: { arrival: 'checkIn', departure: 'checkIn' },
    [ResourceType.BOARDING_GATE]: { departure: 'boardingGate' },
};

export const isSearchTextInFlightFields = (flight: FlightTurnaroundDto, search: string) => {
    return (
        flight.airline?.iata?.toLowerCase().includes(search.toLowerCase()) ||
        flight.airline?.icao?.toLowerCase().includes(search.toLowerCase()) ||
        flight.airport?.iata?.toLowerCase().includes(search.toLowerCase()) ||
        flight.airport?.icao?.toLowerCase().includes(search.toLowerCase()) ||
        flight.adep?.iata?.toLowerCase().includes(search.toLowerCase()) ||
        flight.adep?.icao?.toLowerCase().includes(search.toLowerCase()) ||
        flight.ades?.iata?.toLowerCase().includes(search.toLowerCase()) ||
        flight.ades?.icao?.toLowerCase().includes(search.toLowerCase()) ||
        flight.acType?.iata?.toLowerCase().includes(search.toLowerCase()) ||
        flight.acType?.icao?.toLowerCase().includes(search.toLowerCase()) ||
        flight.arrivalFlightNumber?.toLowerCase().includes(search.toLowerCase()) ||
        flight.arrivalFlightNumberFull?.toLowerCase().includes(search.toLowerCase()) ||
        flight.departureFlightNumber?.toLowerCase().includes(search.toLowerCase()) ||
        flight.departureFlightNumberFull?.toLowerCase().includes(search.toLowerCase())
    );
};

export const isSearchTextInFlightResources = (flight: FlightTurnaroundDto, search: string) => {
    return Object.values(flight.resources || {}).some((res: TurnaroundResourcesDto[keyof TurnaroundResourcesDto]) =>
        res?.toLowerCase().includes(search.toLowerCase()),
    );
};

export const getSliceOrigin = (
    flight: FlightTurnaroundDto,
    resourceType?: ResourceType,
    resourceName?: string,
    proposedResources?: TurnaroundResourcesDto,
) => {
    if (!resourceType) {
        return undefined;
    }

    const resourceFilter = resourceFields[resourceType];
    if (!resourceFilter) {
        return undefined;
    }

    const arrivalResource =
        resourceFilter.arrival &&
        (proposedResources?.[resourceFilter.arrival]
            ? proposedResources[resourceFilter.arrival]
            : flight.resources[resourceFilter.arrival]);
    const departureResource =
        resourceFilter.departure &&
        (proposedResources?.[resourceFilter.departure]
            ? proposedResources[resourceFilter.departure]
            : flight.resources[resourceFilter.departure]);

    if (!arrivalResource && !departureResource) {
        return undefined;
    }

    return flight.sibt && (arrivalResource || '') !== (resourceName || '')
        ? 'departure'
        : flight.sobt && (departureResource || '') !== (resourceName || '')
        ? 'arrival'
        : undefined;
};

export const groupFlightsByResourceType = (
    flights: FlightTurnaroundDto[],
    sortByResource: ResourceType,
    allocationProposal?: { [key: string]: TurnaroundResourcesDto },
): Record<string, FlightTurnaroundDto[]> => {
    const groupedFlights = flights.reduce((acc, flight) => {
        const arrivalField = resourceFields[sortByResource].arrival;
        const departureField = resourceFields[sortByResource].departure;
        const arrivalResourceName =
            (arrivalField &&
                (allocationProposal?.[flight.id]?.[arrivalField]
                    ? allocationProposal[flight.id][arrivalField]
                    : flight.resources[arrivalField])) ||
            '';
        const departureResourceName =
            (departureField &&
                (allocationProposal?.[flight.id]?.[departureField]
                    ? allocationProposal[flight.id][departureField]
                    : flight.resources[departureField])) ||
            '';

        return {
            ...acc,
            [arrivalResourceName]: (acc[arrivalResourceName] || []).concat([flight]),
            [departureResourceName]: (acc[departureResourceName] || []).concat([flight]),
        };
    }, {} as Record<string, FlightTurnaroundDto[]>);

    return Object.keys(groupedFlights).reduce((acc, resource) => {
        return {
            ...acc,
            [resource]: groupedFlights[resource],
        };
    }, {} as Record<string, FlightTurnaroundDto[]>);
};

export const getFlightTimes = (flight: FlightTurnaroundDto) => {
    return {
        start: flight.sibt || flight.sobt! - ganttPartialDefaultDuration,
        end: flight.sobt || flight.sibt! + ganttPartialDefaultDuration,
    };
};

export const getDepartureTime = (flight: FlightTurnaroundDto) => getFlightTimes(flight).end;

export const getLastFlightTime = (flights?: FlightTurnaroundDto[]) =>
    flights && Math.max(...flights.map(getDepartureTime)) + ONE_HOUR;

export const getResourceUpdateImpact = (
    resources: TurnaroundResourcesDto,
    resourcesProposal?: { resourceType: ResourceType; resources: TurnaroundResourcesDto },
) => {
    const arrivalField = resourcesProposal && resourceFields[resourcesProposal.resourceType].arrival;
    const departureField = resourcesProposal && resourceFields[resourcesProposal.resourceType].departure;

    const initialArrival = arrivalField && resources?.[arrivalField];
    const proposalArrival = arrivalField && resourcesProposal.resources?.[arrivalField];
    const initialDeparture = departureField && resources?.[departureField];
    const proposalDeparture = departureField && resourcesProposal.resources?.[departureField];
    const diffArrival = proposalArrival !== undefined && initialArrival !== proposalArrival;
    const diffDeparture = proposalDeparture !== undefined && initialDeparture !== proposalDeparture;

    return {
        impactArrival: diffArrival
            ? initialArrival === null
                ? ResourcesProposalImpact.NEW
                : ResourcesProposalImpact.UPDATED
            : undefined,
        impactDeparture: diffDeparture
            ? initialDeparture === null
                ? ResourcesProposalImpact.NEW
                : ResourcesProposalImpact.UPDATED
            : undefined,
    };
};
