import React, { memo, useCallback } from 'react';
import { ActiveVersionDto, ModificationDemandDto } from '@types';
import { ListItemText, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { usePopoverValue } from '@components/navigation/PopoverMenu/PopoverMenu';
import { useViewSlotOccurrence } from '../../../hooks/useViewSlotOccurrence';

export const StripMenuItemCheckOnGanttView = memo(function StripMenuItemCheckOnGanttView({
    slot,
    children,
}: {
    slot: ModificationDemandDto | ActiveVersionDto;
    children?: React.ReactNode;
}) {
    const close = usePopoverValue('close');
    const viewInGantt = useViewSlotOccurrence(slot.id!);

    const onClick = useCallback(() => {
        close();
        viewInGantt();
    }, [close, viewInGantt]);

    return (
        <MenuItem onClick={onClick}>
            {children || (
                <ListItemText>
                    <FormattedMessage id="strip.actions.checkOnGanttView" />
                </ListItemText>
            )}
        </MenuItem>
    );
});
