import React, { memo, useMemo } from 'react';
import { getSliceOrigin, groupFlightsByResourceType } from '@components/Schedule/Gantt/gantt.utils';
import { FlightTurnaroundDto, ResourceType, TurnaroundResourcesDto } from '@types';
import { CollapsibleGanttGroup } from '@components/Schedule/Gantt/Groups/CollapsibleGanttGroup';
import { GanttGroupName } from '@components/Schedule/Gantt/Groups/GanttGroup';
import { useIntlValue } from '@innovatm/js-iam';

export const GanttResourceGroups = memo(function GanttResourceGroups({
    flights = [],
    allocationProposal,
    children,
    resourceType,
}: {
    flights?: FlightTurnaroundDto[];
    allocationProposal?: { [key: string]: TurnaroundResourcesDto };
    children?: (flight: FlightTurnaroundDto[], resourceName?: string) => React.ReactNode;
    resourceType: ResourceType;
}) {
    const locale = useIntlValue('locale');

    const flightsByResources = useMemo(
        () => groupFlightsByResourceType(flights, resourceType, allocationProposal),
        [allocationProposal, flights, locale, resourceType],
    );
    const orderedKeys = Object.keys(flightsByResources).sort((a, b) => {
        return a.localeCompare(b, locale, { numeric: true });
    });

    return (
        <>
            {orderedKeys.map((resourceName, index) => {
                // Remove empty slices (arrival slice of a turnaround without sibt for example)
                const flightsInResourceGroup = flightsByResources[resourceName].filter(flight => {
                    const origin = getSliceOrigin(flight, resourceType, resourceName, allocationProposal?.[flight.id]);
                    return !origin || (origin === 'arrival' && flight.sibt) || (origin === 'departure' && flight.sobt);
                });

                return (
                    <CollapsibleGanttGroup
                        index={index}
                        key={resourceName || '-'}
                        groupNamelabel={<GanttGroupName>{resourceName || '-'}</GanttGroupName>}
                        flightsLength={flightsInResourceGroup.length}
                    >
                        {children?.(flightsInResourceGroup, resourceName)}
                    </CollapsibleGanttGroup>
                );
            })}
        </>
    );
});
