import React, { memo, useCallback } from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import {
    collapsedGanttGroupHeight,
    GanttGroup,
    getGroupHeight,
    groupNameWidth,
} from '@components/Schedule/Gantt/Groups/GanttGroup';

const transitionDuration = '300ms';
const transitionEasing = 'cubic-bezier(0.4, 0, 0.2, 1) 0ms';

const StyledCountBox = styled(Box)<
    {
        index: number;
    } & Partial<BoxProps>
>`
    color: rgba(255, 255, 255, 0.7);
    position: sticky;
    top: 0;
    width: 100vw;
    height: ${collapsedGanttGroupHeight}px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // counteract own height
    margin-bottom: -${collapsedGanttGroupHeight}px;
    pointer-events: none;
    user-select: none;
    transition: opacity ${transitionDuration} ${transitionEasing};
`;

const StyledCollapseChildren = styled(Box)<
    {
        open: boolean;
        flightsLength: number;
    } & Partial<BoxProps>
>`
    overflow: hidden;
    transition: opacity ${transitionDuration}, height ${transitionDuration} ${transitionEasing};
    height: ${({ open, flightsLength }) => (open ? getGroupHeight(flightsLength) : collapsedGanttGroupHeight)}px;
    opacity: ${({ open }) => (open ? 1 : 0)};
    ${({ open }) => (!open ? '& * { pointer-events: none; }' : '')}
`;

export const GanttGroupNameBar = styled(Box)<
    {
        open: boolean;
        flightsLength: number;
        index: number;
    } & Partial<BoxProps>
>`
    user-select: none;
    position: sticky;
    z-index: 10;
    cursor: pointer;
    width: ${groupNameWidth}px;
    // @workaround : "height: 100%" don't work with sticky. "100%" returns the height of the GanttGroupNameBar, not the height of the parent GanttGroup.
    // We must compute the height of the group, by multiplying the number of flights by the row height.
    height: ${({ open, flightsLength }) => (open ? getGroupHeight(flightsLength) : collapsedGanttGroupHeight)}px;
    min-height: ${collapsedGanttGroupHeight}px;
    // stick to the bottom of the GanttGroup
    bottom: 0;
    // counteract component's own width
    left: -${groupNameWidth}px;
    // counteract component's own height
    margin-bottom: -${({ open, flightsLength }) => (open ? getGroupHeight(flightsLength) : collapsedGanttGroupHeight)}px;
    // counteract Gantt padding-left
    margin-left: -${groupNameWidth}px;
    color: white;
    font-size: 13px;
    font-family: Roboto, serif;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ index, theme }) => (index % 2 === 0 ? theme.palette.blue.darkest : theme.palette.primary.main)};
    pointer-events: auto;
`;
export const CollapsibleGanttGroup = memo(
    ({
        index,
        groupNamelabel,
        flightsLength,
        children,
    }: {
        index: number;
        groupNamelabel: React.ReactNode;
        flightsLength: number;
        children?: React.ReactNode;
    }) => {
        const [open, setOpen] = React.useState(true);

        const handleClick = useCallback(() => setOpen(!open), [open]);

        return (
            <GanttGroup
                onClick={!open ? handleClick : undefined}
                flightsLength={flightsLength}
                sx={[
                    {
                        borderBottom: '2px solid #082940',
                        backdropFilter: index % 2 === 0 ? undefined : 'brightness(1.15)',
                        backgroundColor: open
                            ? undefined
                            : theme =>
                                  index % 2 === 0 ? theme.palette.background.default : theme.palette.secondary.main,
                        transition: `background ${transitionDuration}, height ${transitionDuration} ${transitionEasing}`,
                    },
                    !open && { height: collapsedGanttGroupHeight, cursor: 'pointer' },
                ]}
            >
                {/* sticky */}
                <GanttGroupNameBar
                    index={index}
                    open={open}
                    flightsLength={flightsLength}
                    onClick={open ? handleClick : undefined}
                >
                    {groupNamelabel}
                    {open ? <KeyboardArrowUp width={10} height={10} /> : <KeyboardArrowDown width={10} height={10} />}
                </GanttGroupNameBar>

                {/* sticky */}
                <StyledCountBox index={index} sx={{ opacity: open ? 0 : 1 }}>
                    <Typography component={'span'} variant={'h5'}>
                        <FormattedMessage id={'schedule.collapsedFlights'} values={{ flights: flightsLength }} />
                    </Typography>
                </StyledCountBox>

                <StyledCollapseChildren open={open} flightsLength={flightsLength}>
                    {children}
                </StyledCollapseChildren>
            </GanttGroup>
        );
    },
);
