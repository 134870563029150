import { PopoverMenu } from '@components/navigation/PopoverMenu/PopoverMenu';
import { LoadingButton } from '@mui/lab';
import React, { memo, useCallback } from 'react';
import { ReactComponent as MenuIcon } from '../../../assets/icons/icon-menu-light-blue.svg';

export const StripMenu = memo(({ disabled, children }: { disabled?: boolean; children?: React.ReactNode }) => {
    const renderButton = useCallback(
        ({ onClick }) => (
            <LoadingButton onClick={onClick} disabled={disabled}>
                <MenuIcon width={35} height={35} />
            </LoadingButton>
        ),
        [disabled],
    );

    return <PopoverMenu renderButton={renderButton}>{children}</PopoverMenu>;
});
