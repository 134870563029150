import { ReactComponent as ControlledSvgIcon } from '../../../assets/icons/icon-controlled.svg';
import React, { memo } from 'react';
import { styled } from '@mui/material';

const StyledStarIcon = styled('div')`
    height: 100%;
    padding-top: 9px;
    opacity: 0.8;
    box-sizing: border-box;
`;

export const FlightTurnaroundControlledIcon = memo(() => (
    <StyledStarIcon>
        <ControlledSvgIcon width={12} height={12} />
    </StyledStarIcon>
));
