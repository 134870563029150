import { Box, formGroupClasses, styled } from '@mui/material';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { ChangeEvent, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { UpdatePeriodDto, WeekDay } from '@types';
import { FormikDatePicker } from '@components/form/DatePicker/FormikDatePicker';
import { Checkbox } from '@components/form/Checkbox/Checkbox';
import { EMPTY_ARRAY } from '../../../config/constants';
import { FormattedTime } from '@components/time/FormattedTime';
import { FormikErrorMessage } from '@components/form/FormikErrorMessage/FormikErrorMessage';
import { setDay } from 'date-fns';

const StyledLine = styled(Box)`
    display: flex;
    margin-top: ${({ theme }) => theme.spacing(4)};
    & .${formGroupClasses.root} + .${formGroupClasses.root} {
        margin-left: 20px;
    }
`;

const StyledTitle = styled(Box)`
    color: white;
    font-size: 26px;
`;

export const CancelForm = memo(function CancelForm() {
    const { values, initialValues, setValues } = useFormikContext<UpdatePeriodDto>();

    const getDay = (dayNumber: number) => setDay(new Date(), dayNumber);

    const handleChangePattern = useCallback(
        (e: ChangeEvent<HTMLInputElement>, day) => {
            const checked = e.target.checked;
            setValues(o => ({
                ...o,
                pattern: checked
                    ? (o.pattern || EMPTY_ARRAY).concat(day)
                    : o.pattern
                    ? o.pattern.filter(p => p !== day)
                    : EMPTY_ARRAY,
            }));
        },
        [setValues],
    );

    return (
        <Box width="800px">
            <StyledLine justifyContent="space-between" alignItems="center">
                <StyledTitle>
                    <FormattedMessage id={'cancelForm.selectFlights'} />
                </StyledTitle>
            </StyledLine>
            <StyledLine justifyContent="center" alignItems="center">
                <Box maxWidth={250}>
                    <FormikDatePicker
                        label={<FormattedMessage id={'requestForm.from'} />}
                        name="begin"
                        minDate={new Date(initialValues.begin!)}
                        maxDate={new Date(initialValues.end!)}
                        required={true}
                        disabled={!!initialValues.punctual}
                        disableOpenPicker={!!initialValues.punctual}
                    />
                </Box>
                <Box ml={10} maxWidth={250}>
                    <FormikDatePicker
                        label={<FormattedMessage id={'requestForm.to'} />}
                        name="end"
                        minDate={new Date(values.begin!)}
                        maxDate={new Date(initialValues.end!)}
                        required={true}
                        disabled={!!initialValues.punctual}
                        disableOpenPicker={!!initialValues.punctual}
                    />
                </Box>
            </StyledLine>
            <StyledLine justifyContent="center" alignItems="center">
                {Object.keys(WeekDay).map((day, i) => (
                    <Checkbox
                        key={day}
                        name="pattern"
                        checked={(values.pattern || EMPTY_ARRAY).includes(day)}
                        disabled={!(initialValues.pattern || EMPTY_ARRAY).includes(day)}
                        onChange={e => handleChangePattern(e, day)}
                        label={<FormattedTime format={'iiii'}>{getDay(i + 1)}</FormattedTime>}
                        sx={{ textTransform: 'capitalize' }}
                    />
                ))}
            </StyledLine>
            <Box display={'flex'} justifyContent={'center'}>
                <FormikErrorMessage name={'pattern'} />
            </Box>
        </Box>
    );
});
