import React, { memo, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useCurrentUserClientTimezone } from '../../../contexts/AuthContext.selectors';
import { zonedTimeToUtc } from 'date-fns-tz';
import { endOfDay, startOfDay } from 'date-fns';
import { GanttContext } from './GanttContext';
import { Box, BoxProps, styled } from '@mui/material';
import { ganttDefaultFromHour, ganttPxPerMs } from '@components/Schedule/Gantt/gantt.constants';
import { ONE_HOUR } from '../../../config/constants';
import { GanttTimeline } from '@components/Schedule/Gantt/GanttTimeline';
import { GanttGrid } from '@components/Schedule/Gantt/GanttGrid';
import { useScheduleState } from '../../../views/Schedule/SchedulePage/ScheduleContext';

export const GanttBox = styled(Box)`
    display: flex;
    z-index: 1;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-left: 60px;
`;
export const GanttContentBox = styled(Box)`
    position: relative;
    // Allows the grid to take all the available space OR exaclty the gantt content :
    max-height: fit-content;
    height: 100000px;
    pointer-events: none; // allow cursor hover to pass through to OverCapacity alerts
`;
export const GanttFlightsBox = styled(Box)`
    top: 0;
    display: flex;
    flex-direction: column;
    width: fit-content; // allow dragged flights to overflow
    min-width: 100%;
    overflow: hidden;
    pointer-events: none; // allow cursor hover to pass through to OverCapacity alerts
`;

export const Gantt = memo(function Gantt({
    day,
    lastFlightTime,
    children,
    ...layoutProps
}: {
    day: Date;
    lastFlightTime?: number;
    children?: React.ReactNode;
} & Partial<BoxProps>) {
    const timezone = useCurrentUserClientTimezone();
    const from = zonedTimeToUtc(startOfDay(day), timezone || '').getTime();
    const to = useMemo(
        () =>
            Math.max(
                // at least until midnight
                zonedTimeToUtc(endOfDay(day), timezone || '').getTime(),
                // or at the end of the last flight
                lastFlightTime || 0,
            ),
        [day, lastFlightTime, timezone],
    );

    const state = useMemo(() => ({ from, to }), [from, to]);

    const ref = useRef<HTMLDivElement>(null);

    const [scroll, setScroll] = useScheduleState('scroll');
    // scroll to last saved scroll position or to default
    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }
        if (scroll) {
            ref.current.scrollLeft = scroll.x;
            ref.current.scrollTop = scroll.y;
        } else {
            ref.current.scrollLeft = ganttDefaultFromHour * ONE_HOUR * ganttPxPerMs;
        }
    }, [scroll]);
    // save scroll before unmount
    useEffect(() => {
        let scroll: any = null;
        // Get scroll before cleanup function because the ref would be null in the cleanup
        ref.current?.addEventListener('scroll', () => {
            scroll = { x: ref.current?.scrollLeft, y: ref.current?.scrollTop };
        });
        return () => setScroll(scroll);
    }, [setScroll]);

    return (
        <GanttContext.Provider value={state}>
            <GanttBox ref={ref} {...layoutProps}>
                <GanttTimeline from={from} to={to} />
                <GanttContentBox
                    sx={{
                        width: Math.max(24 * ONE_HOUR, to - from) * ganttPxPerMs,
                    }}
                >
                    <GanttGrid from={from} to={to} />
                    {children}
                </GanttContentBox>
            </GanttBox>
        </GanttContext.Provider>
    );
});
