import React, { memo } from 'react';
import { DaySlotResourcesAllocationResponse, FlightTurnaroundDto, ResourceType } from '@types';
import { GanttResourceGroups } from '@components/Schedule/Gantt/Groups/GanttResourceGroups';
import { GanttGroup } from '@components/Schedule/Gantt/Groups/GanttGroup';

export const GanttFlights = memo(function GanttFlights({
    flights,
    allocationUpdateData,
    sortByResource,
    children,
}: {
    flights?: FlightTurnaroundDto[];
    allocationUpdateData?: DaySlotResourcesAllocationResponse;
    sortByResource?: ResourceType;
    children?: (flights: FlightTurnaroundDto[], resourceName?: string) => React.ReactNode;
}) {
    if (!flights?.length) {
        return null;
    }

    return sortByResource ? (
        <GanttResourceGroups
            flights={flights}
            allocationProposal={allocationUpdateData?.allocationProposal}
            resourceType={sortByResource}
        >
            {children}
        </GanttResourceGroups>
    ) : (
        <GanttGroup flightsLength={flights.length}>{children?.(flights)}</GanttGroup>
    );
});
