/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DemandStatus } from '../models';
import { FiltersDto } from '../models';
import { HistoryPageDto } from '../models';
import { SlotRequestPageDto } from '../models';
import { Configuration, ConfigurationParameters } from '../configuration';
import { BASE_PATH, BaseAPI, RequestArgs } from '../base';
import { AxiosInstance, AxiosPromise, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, UseMutationOptions, useMutation, UseQueryOptions } from 'react-query';
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setSearchParams,
    toPathString,
    globalAxios,
    setBearerAuthToObject,
} from '../common';
import { useAppAuthValue } from '../../utils';
export const RequestWebServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        completeRequest: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('completeRequest', 'id', id);
            const localVarPath = `/request/complete/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getAllSlotRequests: async (
            season?: string,
            slotId?: string,
            page?: number,
            status?: DemandStatus,
            pattern?: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (season !== undefined) {
                localVarQueryParameter['season'] = season;
            }
            if (slotId !== undefined) {
                localVarQueryParameter['slotId'] = slotId;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }
            if (pattern !== undefined) {
                localVarQueryParameter['pattern'] = pattern;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getSlotFilters: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/filters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getSlotHistory: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSlotHistory', 'id', id);
            const localVarPath = `/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        undoRequest: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('undoRequest', 'id', id);
            const localVarPath = `/request/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
export const RequestWebServicesApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestWebServicesApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeRequest(
            id: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [season]
         * @param {string} [slotId]
         * @param {number} [page]
         * @param {DemandStatus} [status]
         * @param {string} [pattern]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSlotRequests(
            season?: string,
            slotId?: string,
            page?: number,
            status?: DemandStatus,
            pattern?: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlotRequestPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSlotRequests(
                season,
                slotId,
                page,
                status,
                pattern,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlotFilters(
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiltersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlotFilters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlotHistory(
            id: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlotHistory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async undoRequest(
            id: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.undoRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

export const RequestWebServicesApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = RequestWebServicesApiFp(configuration);
    return {
        completeRequest(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.completeRequest(id, options).then(request => request(axios, basePath));
        },
        getAllSlotRequests(
            season?: string,
            slotId?: string,
            page?: number,
            status?: DemandStatus,
            pattern?: string,
            options?: any,
        ): AxiosPromise<SlotRequestPageDto> {
            return localVarFp
                .getAllSlotRequests(season, slotId, page, status, pattern, options)
                .then(request => request(axios, basePath));
        },
        getSlotFilters(options?: any): AxiosPromise<FiltersDto> {
            return localVarFp.getSlotFilters(options).then(request => request(axios, basePath));
        },
        getSlotHistory(id: string, options?: any): AxiosPromise<HistoryPageDto> {
            return localVarFp.getSlotHistory(id, options).then(request => request(axios, basePath));
        },
        undoRequest(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.undoRequest(id, options).then(request => request(axios, basePath));
        },
    };
};
export interface RequestWebServicesApiCompleteRequestRequest {
    readonly id: string;
}

export interface RequestWebServicesApiGetAllSlotRequestsRequest {
    readonly season?: string;

    readonly slotId?: string;

    readonly page?: number;

    readonly status?: DemandStatus;

    readonly pattern?: string;
}

export interface RequestWebServicesApiGetSlotHistoryRequest {
    readonly id: string;
}

export interface RequestWebServicesApiUndoRequestRequest {
    readonly id: string;
}

export class RequestWebServicesApi extends BaseAPI {
    public completeRequest(requestParameters: RequestWebServicesApiCompleteRequestRequest, options?: any) {
        return RequestWebServicesApiFp(this.configuration)
            .completeRequest(requestParameters.id, options)
            .then(request => request(this.axios, this.basePath));
    }
    public getAllSlotRequests(requestParameters: RequestWebServicesApiGetAllSlotRequestsRequest = {}, options?: any) {
        return RequestWebServicesApiFp(this.configuration)
            .getAllSlotRequests(
                requestParameters.season,
                requestParameters.slotId,
                requestParameters.page,
                requestParameters.status,
                requestParameters.pattern,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }
    public getSlotFilters(options?: any) {
        return RequestWebServicesApiFp(this.configuration)
            .getSlotFilters(options)
            .then(request => request(this.axios, this.basePath));
    }
    public getSlotHistory(requestParameters: RequestWebServicesApiGetSlotHistoryRequest, options?: any) {
        return RequestWebServicesApiFp(this.configuration)
            .getSlotHistory(requestParameters.id, options)
            .then(request => request(this.axios, this.basePath));
    }
    public undoRequest(requestParameters: RequestWebServicesApiUndoRequestRequest, options?: any) {
        return RequestWebServicesApiFp(this.configuration)
            .undoRequest(requestParameters.id, options)
            .then(request => request(this.axios, this.basePath));
    }
}

// custom hook generation here
export type PostCompleteRequestResponse = AxiosResponse<void>;
export const KeyPostCompleteRequest = 'PostCompleteRequest';
export const useMutationPostCompleteRequest = (
    options?: UseMutationOptions<PostCompleteRequestResponse, AxiosError, RequestWebServicesApiCompleteRequestRequest>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<PostCompleteRequestResponse, AxiosError, RequestWebServicesApiCompleteRequestRequest>(
        (args: RequestWebServicesApiCompleteRequestRequest) =>
            new RequestWebServicesApi(new Configuration({ accessToken })).completeRequest(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostCompleteRequest = (
    args: RequestWebServicesApiCompleteRequestRequest,
    options?: UseQueryOptions<PostCompleteRequestResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostCompleteRequestResponse, AxiosError>(
        [KeyPostCompleteRequest, args, options && options.queryKey],
        () => new RequestWebServicesApi(new Configuration({ accessToken, ...conf })).completeRequest(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetAllSlotRequestsResponse = AxiosResponse<SlotRequestPageDto>;
export const KeyGetGetAllSlotRequests = 'GetGetAllSlotRequests';
export const useMutationGetGetAllSlotRequests = (
    options?: UseMutationOptions<
        GetGetAllSlotRequestsResponse,
        AxiosError,
        RequestWebServicesApiGetAllSlotRequestsRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetGetAllSlotRequestsResponse, AxiosError, RequestWebServicesApiGetAllSlotRequestsRequest>(
        (args: RequestWebServicesApiGetAllSlotRequestsRequest) =>
            new RequestWebServicesApi(new Configuration({ accessToken })).getAllSlotRequests(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetAllSlotRequests = (
    args: RequestWebServicesApiGetAllSlotRequestsRequest,
    options?: UseQueryOptions<GetGetAllSlotRequestsResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetAllSlotRequestsResponse, AxiosError>(
        [KeyGetGetAllSlotRequests, args, options && options.queryKey],
        () =>
            new RequestWebServicesApi(new Configuration({ accessToken, ...conf })).getAllSlotRequests(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetSlotFiltersResponse = AxiosResponse<FiltersDto>;
export const KeyGetGetSlotFilters = 'GetGetSlotFilters';
export const useMutationGetGetSlotFilters = (
    options?: UseMutationOptions<GetGetSlotFiltersResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetGetSlotFiltersResponse, AxiosError>(
        () => new RequestWebServicesApi(new Configuration({ accessToken })).getSlotFilters(axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetSlotFilters = (
    options?: UseQueryOptions<GetGetSlotFiltersResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetSlotFiltersResponse, AxiosError>(
        [KeyGetGetSlotFilters, options && options.queryKey],
        () => new RequestWebServicesApi(new Configuration({ accessToken, ...conf })).getSlotFilters(axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetSlotHistoryResponse = AxiosResponse<HistoryPageDto>;
export const KeyGetGetSlotHistory = 'GetGetSlotHistory';
export const useMutationGetGetSlotHistory = (
    options?: UseMutationOptions<GetGetSlotHistoryResponse, AxiosError, RequestWebServicesApiGetSlotHistoryRequest>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetGetSlotHistoryResponse, AxiosError, RequestWebServicesApiGetSlotHistoryRequest>(
        (args: RequestWebServicesApiGetSlotHistoryRequest) =>
            new RequestWebServicesApi(new Configuration({ accessToken })).getSlotHistory(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetSlotHistory = (
    args: RequestWebServicesApiGetSlotHistoryRequest,
    options?: UseQueryOptions<GetGetSlotHistoryResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetSlotHistoryResponse, AxiosError>(
        [KeyGetGetSlotHistory, args, options && options.queryKey],
        () => new RequestWebServicesApi(new Configuration({ accessToken, ...conf })).getSlotHistory(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type DeleteUndoRequestResponse = AxiosResponse<void>;
export const KeyDeleteUndoRequest = 'DeleteUndoRequest';
export const useMutationDeleteUndoRequest = (
    options?: UseMutationOptions<DeleteUndoRequestResponse, AxiosError, RequestWebServicesApiUndoRequestRequest>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<DeleteUndoRequestResponse, AxiosError, RequestWebServicesApiUndoRequestRequest>(
        (args: RequestWebServicesApiUndoRequestRequest) =>
            new RequestWebServicesApi(new Configuration({ accessToken })).undoRequest(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryDeleteUndoRequest = (
    args: RequestWebServicesApiUndoRequestRequest,
    options?: UseQueryOptions<DeleteUndoRequestResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<DeleteUndoRequestResponse, AxiosError>(
        [KeyDeleteUndoRequest, args, options && options.queryKey],
        () => new RequestWebServicesApi(new Configuration({ accessToken, ...conf })).undoRequest(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
