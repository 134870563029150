import { GanttStripDto, ModificationDemandDto, SlotVersionDto } from '@models';
import { Box, styled, Typography } from '@mui/material';
import { format as dateFnsFormat, setDay } from 'date-fns';
import React, { memo } from 'react';
import { STRIP_PUNCTUAL_DATE_FORMAT, STRIP_RECURRING_DATE_FORMAT, StripDateStyle } from './strip.constant';
import { useDateFnsFormat } from '../../hooks/useDateFnsFormat';
import { StripElement } from '@components/Strip/StripElement';
import { ArrowRight } from '@components/icons/ArrowRight';

const days = Array(7).fill(undefined);
const getDay = (dayNumber: number) => setDay(new Date(), dayNumber);

const StyledDatesAndPatterns = styled(StripElement)`
    min-width: 463px;
`;

const WeekDaysPattern = memo(({ pattern }: { pattern?: string[] | null }) => {
    const format = useDateFnsFormat();
    return (
        <>
            {days.map((a, i) => (
                <Typography
                    key={i}
                    fontSize="16px"
                    sx={{
                        fontFamily: 'Roboto',
                        fontWeight: 'bold',
                        textTransform: 'lowercase',
                        color: pattern?.includes(dateFnsFormat(getDay(i + 1), 'iiii').toUpperCase())
                            ? theme => theme.palette.blue.dark
                            : '#B6D2E5',
                    }}
                >
                    {format(getDay(i + 1), 'iii').replace('.', '')}
                </Typography>
            ))}
        </>
    );
});

const StripDatesAndPatternLabel = memo(
    ({ slot }: { slot?: GanttStripDto | ModificationDemandDto | SlotVersionDto | null }) => {
        const format = useDateFnsFormat();
        return (
            <>
                <Box minWidth="155px" display="flex">
                    <Typography sx={{ ...StripDateStyle, fontSize: 15 }} minWidth="155px">
                        {slot?.begin && format(slot.begin, STRIP_RECURRING_DATE_FORMAT).replace('.', '')}
                        <ArrowRight />
                        {slot?.end && format(slot.end, STRIP_RECURRING_DATE_FORMAT).replace('.', '')}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-evenly" width="100%">
                    <WeekDaysPattern pattern={slot?.pattern} />
                </Box>
            </>
        );
    },
);

export const StripDatesAndPattern = memo(function StripDatesAndPattern({
    slot,
}: {
    slot?: GanttStripDto | ModificationDemandDto | SlotVersionDto | null;
}) {
    const format = useDateFnsFormat();

    return (
        <Box position="relative">
            <StyledDatesAndPatterns zIndex="10">
                {slot?.punctual ? (
                    <Box marginRight="20px" display="flex">
                        <Typography sx={StripDateStyle}>
                            {slot.begin && format(slot.begin, STRIP_PUNCTUAL_DATE_FORMAT)}
                        </Typography>
                    </Box>
                ) : (
                    <StripDatesAndPatternLabel slot={slot} />
                )}
            </StyledDatesAndPatterns>
        </Box>
    );
});
