import React, { memo, useCallback, useMemo, useState } from 'react';
import { ScheduleHeader } from '@components/Schedule/Header/ScheduleHeader';
import { Box, styled } from '@mui/material';
import { Strip } from '@components/Strip/Strip';
import { StripMenuSchedulePage } from '@components/Schedule/StripMenuSchedulePage';
import { ScheduleCloseStrip } from '@components/Schedule/ScheduleCloseStrip';
import { DaySlotResourcesAllocationResponse, FlightTurnaroundDto } from '@types';
import { useSetUi, useUiState, useUiValue } from '../../../contexts/UiContext';
import { useSchedulePageData } from '@components/Schedule/useSchedulePageData';
import { GanttFlightRow } from '@components/Schedule/Gantt/GanttFlightRow';
import { Gantt, GanttFlightsBox } from '@components/Schedule/Gantt/Gantt';
import { getLastFlightTime, getSliceOrigin } from '@components/Schedule/Gantt/gantt.utils';
import { GanttSelection } from '@components/Schedule/Gantt/GanttSelection';
import { GanttFlights } from '@components/Schedule/Gantt/GanttFlights';
import { ScheduleAllocationControls } from '@components/Schedule/ScheduleAllocationControls';
import omit from 'lodash/omit';
import { filterScheduleFlights } from '../../../utils/search.utils';
import { ResourcesProposalImpact } from '../../../backend/types.custom';
import { updateReportWithRejectedProposals } from '@components/Schedule/schedule.utils';

const StyledGanttContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const SchedulePageAllocation = memo(function SchedulePageAllocation({
    selectedDate,
    allocationUpdateData,
}: {
    selectedDate: Date;
    allocationUpdateData: DaySlotResourcesAllocationResponse;
}) {
    const [updatedAllocations, setUpdatedAllocations] =
        useState<DaySlotResourcesAllocationResponse>(allocationUpdateData);

    const [selectedSlotId, setSelectedSlotId] = useUiState('selectedSlotId');
    const setSelectedFlightId = useSetUi('selectedFlightId');
    const sortByResource = useUiValue('sortByResource');
    const search = useUiValue('search');
    const selectedMode = useUiValue('selectedMode');
    const showCancelledRequests = useUiValue('showCancelledRequests');

    const filteredFlights: FlightTurnaroundDto[] = useMemo(
        () =>
            filterScheduleFlights(allocationUpdateData?.flightTurnarounds, {
                search,
                showCancelledRequests,
                selectedMode,
            }),
        [allocationUpdateData?.flightTurnarounds, search, selectedMode, showCancelledRequests],
    );

    const { slotResponse, selection } = useSchedulePageData(selectedDate, false);

    const onClickSelectFlight = useCallback(
        (flight: FlightTurnaroundDto) => {
            setSelectedSlotId(null);
            setSelectedFlightId(flight?.id || null);
        },
        [setSelectedFlightId, setSelectedSlotId],
    );

    const onRejectUpdate = useCallback(
        (
            id: string,
            proposalImpactArrival?: ResourcesProposalImpact,
            proposalImpactDeparture?: ResourcesProposalImpact,
        ) => {
            setUpdatedAllocations(
                updatedAllocations && {
                    ...updatedAllocations,
                    allocationProposal: omit(updatedAllocations.allocationProposal, id),
                    flightTurnarounds: updatedAllocations.flightTurnarounds.filter(f => f.id !== id),
                    report: updateReportWithRejectedProposals(
                        updatedAllocations.report,
                        proposalImpactArrival,
                        proposalImpactDeparture,
                    ),
                },
            );
        },
        [updatedAllocations],
    );

    return (
        <>
            <ScheduleHeader disabled={true} />

            <StyledGanttContainer>
                {selectedSlotId && slotResponse && (
                    <Box display="flex" justifyContent="center" alignItems="center" my={2} flexShrink={0}>
                        <Strip
                            slotStripDto={slotResponse}
                            renderStripMenu={props => (
                                <StripMenuSchedulePage
                                    {...props}
                                    slot={slotResponse}
                                    hasPending={slotResponse.nbPending > 0}
                                    disabled={true}
                                />
                            )}
                        />
                        <ScheduleCloseStrip />
                    </Box>
                )}

                <Gantt day={selectedDate} lastFlightTime={getLastFlightTime(filteredFlights)}>
                    <GanttSelection {...selection} />
                    <GanttFlights
                        sortByResource={sortByResource}
                        flights={filteredFlights}
                        allocationUpdateData={updatedAllocations}
                    >
                        {(flights, resourceName) => (
                            <GanttFlightsBox>
                                {flights?.map(flight => {
                                    const resourcesProposal = updatedAllocations
                                        ? {
                                              resources: updatedAllocations.allocationProposal[flight.id],
                                              resourceType: updatedAllocations.resourceType,
                                          }
                                        : undefined;

                                    return (
                                        <GanttFlightRow
                                            key={flight.id}
                                            flight={flight}
                                            resourcesProposal={resourcesProposal}
                                            onRejectResourceProposal={onRejectUpdate}
                                            // TODO Make sure flight selection still works
                                            // isFlightSelected={
                                            //     selection?.flightId === flight.id || selection?.slotId === flight.slotId
                                            // }
                                            isFlightSelected={selection?.flightId === flight.id}
                                            sliceOrigin={getSliceOrigin(
                                                flight,
                                                sortByResource,
                                                resourceName,
                                                updatedAllocations?.allocationProposal?.[flight.id],
                                            )}
                                            onClick={onClickSelectFlight}
                                        />
                                    );
                                })}
                            </GanttFlightsBox>
                        )}
                    </GanttFlights>
                </Gantt>

                <ScheduleAllocationControls allocationUpdateData={updatedAllocations} />
            </StyledGanttContainer>
        </>
    );
});
