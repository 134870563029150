import React, { memo, useCallback, useMemo } from 'react';
import { ScheduleHeader } from '@components/Schedule/Header/ScheduleHeader';
import { Box, styled } from '@mui/material';
import { Strip } from '@components/Strip/Strip';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { FormattedMessage } from 'react-intl';
import { ScheduleControls } from '@components/Schedule/ScheduleControls';
import { FlightTurnaroundDto } from '@types';
import { format as originalFormat } from 'date-fns';
import { ganttDayFormat } from '@components/Schedule/Gantt/gantt.constants';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../config/constants';
import { useConfirmDialog } from '@components/confirm/useConfirmDialog';
import { useFormattedTime } from '@components/time/useFormattedTime';
import { useFormattedDate } from '@components/time/useFormattedDate';
import {
    KeyGetGetDayGanttTurnarounds,
    useMutationPostAssociateSlotOccurrences,
    useQueryGetGetGanttTurnaroundsToAssociate,
} from '@models';
import { useQueryClient } from 'react-query';
import { useUiState, useUiValue } from '../../../contexts/UiContext';
import { ScheduleCloseStrip } from '@components/Schedule/ScheduleCloseStrip';
import { filterScheduleFlights } from '../../../utils/search.utils';
import { useSchedulePageData } from '@components/Schedule/useSchedulePageData';
import { GanttFlightRow } from '@components/Schedule/Gantt/GanttFlightRow';
import { Gantt, GanttFlightsBox } from '@components/Schedule/Gantt/Gantt';
import { getLastFlightTime, getSliceOrigin } from '@components/Schedule/Gantt/gantt.utils';
import { GanttSelection } from '@components/Schedule/Gantt/GanttSelection';
import { GanttFlights } from '@components/Schedule/Gantt/GanttFlights';
import { useGoToSchedule } from '../../../hooks/useGoToSchedule';

const StyledGanttContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const SchedulePageAssociation = memo(function SchedulePageAssociation({
    selectedDate,
    onClickStopAssociating,
    dayOnly,
}: {
    selectedDate: Date;
    onClickStopAssociating: () => void;
    dayOnly: boolean;
}) {
    const queryClient = useQueryClient();

    const { ganttResponse, slotResponse, selectedFlights, selection } = useSchedulePageData(selectedDate, false);

    const [selectedFlightId, setSelectedFlightId] = useUiState('selectedFlightId');
    const [, setSelectedSlotId] = useUiState('selectedSlotId');
    const sortByResource = useUiValue('sortByResource');
    const search = useUiValue('search');
    const showCancelledRequests = useUiValue('showCancelledRequests');
    const goToSchedule = useGoToSchedule();

    const { data: turnaroundsToAssociate } = useQueryGetGetGanttTurnaroundsToAssociate(
        {
            id: selectedFlightId || '',
            day: originalFormat(selectedDate, ganttDayFormat),
        },
        {
            enabled: !!selectedFlightId,
            retry: false,
        },
    );

    const filteredFlights = useMemo(
        () =>
            ganttResponse?.flights.filter(
                // in association mode, show only selected flight and turnarounds to associate
                flight =>
                    flight.id === selectedFlightId ||
                    filterScheduleFlights(turnaroundsToAssociate?.flights, {
                        search,
                        showCancelledRequests,
                    }).some(f => f.id === flight.id),
            ),
        [ganttResponse?.flights, search, selectedFlightId, showCancelledRequests, turnaroundsToAssociate?.flights],
    );

    const { mutateAsync: postAssociateTurnarounds, isLoading: isAssociatingTurnarounds } =
        useMutationPostAssociateSlotOccurrences({
            onSuccess: ({ data }) => {
                onClickStopAssociating();
                setSelectedSlotId(data.updatedSlotId || null);
                setSelectedFlightId(null);
                queryClient.invalidateQueries(KeyGetGetDayGanttTurnarounds);
            },
            onError: () => {
                toast(<FormattedMessage id="strip.actions.slotAssociationError" />, errorToastConfig);
            },
        });

    const isSelectedFlightArrivalOnly =
        selectedFlights?.[0]?.sibt && selectedFlights?.[0]?.sibt && !selectedFlights[0].sobt;
    const confirm = useConfirmDialog({ disableReject: true });
    const formatTime = useFormattedTime();
    const formatDate = useFormattedDate();

    const associateTurnarounds = useCallback(
        (arrivalFlight: FlightTurnaroundDto, departureFlight: FlightTurnaroundDto, dayOnly: boolean) => {
            postAssociateTurnarounds({
                day: originalFormat(selectedDate, ganttDayFormat),
                arr: arrivalFlight.id,
                dep: departureFlight.id,
                onCurrentDayOnly: dayOnly,
            }).catch(({ response }: AxiosError) => {
                if (response?.data?.errorKey !== 'TURNAROUND_ASSOCIATION_NOT_POSSIBLE' || !response.data.date) {
                    return;
                }

                toast(
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Box width={'100%'}>
                            <FormattedMessage
                                id={'strip.actions.associateArrDep.errors.TURNAROUND_ASSOCIATION_NOT_POSSIBLE'}
                                values={{ date: formatDate(response.data.date) }}
                            />
                        </Box>
                        <RoundButton
                            color="secondary"
                            size="small"
                            variant="contained"
                            onClick={() => goToSchedule(new Date(response.data.date))}
                        >
                            <FormattedMessage id={'strip.actions.associateArrDep.selectOccurrence'} />
                        </RoundButton>
                    </Box>,
                    errorToastConfig,
                );
            });
        },
        [postAssociateTurnarounds, formatDate, selectedDate, goToSchedule],
    );

    const onClickToAssociate = useCallback(
        (flight: FlightTurnaroundDto) => {
            if (!selectedFlightId || flight.id === selectedFlightId) {
                return;
            }

            const arrivalFlight = isSelectedFlightArrivalOnly ? selectedFlights?.[0] : flight;
            const departureFlight = isSelectedFlightArrivalOnly ? flight : selectedFlights?.[0];

            if (!arrivalFlight || !departureFlight) {
                return;
            }

            confirm({
                content: dayOnly ? (
                    <FormattedMessage
                        id="strip.actions.associateArrDep.confirmLabelDayOnly"
                        values={{
                            arrFln: arrivalFlight.arrivalFlightNumberFull,
                            depFln: departureFlight.departureFlightNumberFull,
                        }}
                    />
                ) : (
                    <FormattedMessage
                        id="strip.actions.associateArrDep.confirmLabel"
                        values={{
                            arrFln: arrivalFlight.arrivalFlightNumberFull,
                            depFln: departureFlight.departureFlightNumberFull,
                            day: formatTime(selectedDate, 'EEEE'),
                        }}
                    />
                ),
                confirmationText: <FormattedMessage id="strip.actions.associateArrDep.confirmButton" />,
            }).then(() => {
                associateTurnarounds(arrivalFlight, departureFlight, dayOnly);
            });
        },
        [
            selectedFlightId,
            isSelectedFlightArrivalOnly,
            selectedFlights,
            confirm,
            formatTime,
            selectedDate,
            associateTurnarounds,
            dayOnly,
        ],
    );

    return (
        <>
            <ScheduleHeader />
            <StyledGanttContainer>
                {selectedFlightId && slotResponse && (
                    <Box display="flex" justifyContent="center" alignItems="center" my={2} flexShrink={0}>
                        <Strip slotStripDto={slotResponse} />
                        <RoundButton
                            color="primary"
                            size="medium"
                            variant="contained"
                            onClick={onClickStopAssociating}
                            loading={isAssociatingTurnarounds}
                            sx={{ marginX: 1 }}
                        >
                            <FormattedMessage id="strip.actions.cancelAssociation" />
                        </RoundButton>
                        <ScheduleCloseStrip onClick={onClickStopAssociating} />
                    </Box>
                )}

                <Gantt day={selectedDate} lastFlightTime={getLastFlightTime(filteredFlights)}>
                    <GanttSelection {...selection} />
                    <GanttFlights sortByResource={sortByResource} flights={filteredFlights}>
                        {(flights, resourceName) => (
                            <GanttFlightsBox>
                                {flights?.map(flight => (
                                    <GanttFlightRow
                                        key={flight.id}
                                        flight={flight}
                                        resourceDiscrepancyAlerts={ganttResponse?.discrepancyAlerts}
                                        // TODO Make sure flight selection still works
                                        // isFlightSelected={
                                        //     selection?.flightId === flight.id || selection?.slotId === flight.slotId
                                        // }
                                        isFlightSelected={selection?.flightId === flight.id}
                                        sliceOrigin={getSliceOrigin(flight, sortByResource, resourceName)}
                                        onClick={onClickToAssociate}
                                    />
                                ))}
                            </GanttFlightsBox>
                        )}
                    </GanttFlights>
                </Gantt>

                {selectedFlightId && (
                    <ScheduleControls
                        flight={selectedFlights?.[0]}
                        flights={slotResponse?.flights}
                        total={slotResponse?.nbTotal}
                        index={selectedFlightId ? slotResponse?.flights?.findIndex(f => f.id === selectedFlightId) : 0}
                    />
                )}
            </StyledGanttContainer>
        </>
    );
});
