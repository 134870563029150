import React, { memo } from 'react';
import { useViewSlotOccurrence } from '../../hooks/useViewSlotOccurrence';
import { FormattedMessage } from 'react-intl';

export const ModifySlotOverlapToast = memo(function ModifySlotOverlapToast({
    overlappedSlotId,
}: {
    overlappedSlotId: string;
}) {
    const viewInGantt = useViewSlotOccurrence(overlappedSlotId);
    return (
        <div onClick={viewInGantt}>
            <FormattedMessage id={'requestForm.overlapToast'} />
        </div>
    );
});
