import { buttonClasses, dialogActionsClasses, dialogContentClasses } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { dialogClasses, DialogProps } from '@mui/material/Dialog';
import { ConfirmOptions, useConfirm } from 'material-ui-confirm';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

const DialogStyle: Omit<DialogProps, 'open'> = {
    sx: {
        [`& .${dialogClasses.paper}`]: {
            background: 'white',
            padding: '50px',
            height: '274px',
            width: '460px',
            borderRadius: '20px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 3px 75px 0 rgba(0,0,0,0.5)',
            color: '#082940',
            fontFamily: 'Roboto',
            fontSize: '18px',
        },
        [`& .${dialogContentClasses.root}`]: {
            padding: '0px',
            textAlign: 'center',
            whiteSpace: 'pre-line',
        },
        [`& .${dialogActionsClasses.root}`]: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
};

const buttonStyle: ButtonProps = {
    size: 'medium',
    sx: {
        borderRadius: '25px',
        padding: '16px 30px',
        letterSpacing: '0',
        textTransform: 'none',
        height: '50px',
        fontSize: '15px',
        lineHeight: '18px',
        whiteSpace: 'nowrap',
        [`&.${buttonClasses.text}`]: {
            border: '1px solid rgba(12, 69, 108, 0.5)',
        },
        [`&.${buttonClasses.outlined}`]: {
            color: theme => theme.palette.blue.lightest,
            borderColor: '#245f88',
        },
    },
};

export const useConfirmDialog = (
    defaultOptions?: {
        // Use when not catching cancellations
        disableReject?: boolean;
    } & ConfirmOptions,
) => {
    const confirm = useConfirm();

    // @workaround: return a promise to allow onBackdropClick to trigger a rejection too
    // see https://github.com/jonatanklosko/material-ui-confirm/issues/25#issuecomment-891902579
    return useCallback(
        (options?: ConfirmOptions) =>
            new Promise<void>((resolve, reject) =>
                confirm({
                    title: '',
                    titleProps: {
                        color: '#082940',
                        sx: { textAlign: 'center', fontFamily: 'Roboto', fontSize: '18px', lineHeight: '28px' },
                    },
                    dialogProps: {
                        ...DialogStyle,
                        disableEscapeKeyDown: true,
                        onBackdropClick: () => {
                            if (!defaultOptions?.disableReject) {
                                reject();
                            }
                        },
                    },
                    cancellationButtonProps: {
                        ...buttonStyle,
                        sx: { ...buttonStyle.sx, color: '#082940' },
                        variant: 'text',
                    },
                    confirmationButtonProps: {
                        ...buttonStyle,
                        sx: { ...buttonStyle.sx, marginLeft: '15px !important' },
                        variant: 'contained',
                        autoFocus: true,
                    },
                    cancellationText: <FormattedMessage id="confirm.default.cancel" />,
                    confirmationText: <FormattedMessage id="confirm.default.confirm" />,
                    ...defaultOptions,
                    ...options,
                })
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        if (!defaultOptions?.disableReject) {
                            reject();
                        }
                    }),
            ),
        [confirm, defaultOptions],
    );
};
