/**
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

export { useAuth as useAppAuthValue } from '../contexts/AuthContext.selectors';

export function getDevRootUrl() {
    return (
        (localStorage && localStorage.getItem && localStorage.getItem('DEV_ROOT_API_URL')) ||
        process.env.REACT_APP_DEFAULT_ROOT_API_URL ||
        window.location.origin + '/schedule'
    );
}
