/**
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import { BoxProps } from '@mui/material';
import { useMirroredRef } from 'hooks/useMirroredRef';
import React, { useLayoutEffect, useRef, useState } from 'react';

export const containerSizeHoc =
    <P extends BoxProps>(
        Component: React.ForwardRefRenderFunction<any, P>,
        options: { minWidth: number | ((props: P) => number) },
    ) =>
    (props: P) => {
        const ref = useRef<HTMLDivElement>(null);
        const [visible, setVisible] = useState(false);
        const visibleRef = useMirroredRef(visible);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useLayoutEffect(() => {
            const targetVisibility =
                !!ref.current?.parentElement?.offsetWidth &&
                ref.current?.parentElement?.offsetWidth >
                    (typeof options.minWidth === 'function' ? options.minWidth(props) : options.minWidth);

            if (visibleRef.current !== targetVisibility) {
                setVisible(targetVisibility);
            }
        });

        return <Component ref={ref} visibility={visible ? 'visible' : 'hidden'} {...props} />;
    };
