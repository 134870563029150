import React, { memo } from 'react';
import { GanttStripDto } from '@types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_REQUESTS } from '../../../routes';
import { ListItemText, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useSetUi } from '../../../contexts/UiContext';

export const StripMenuItemSeeRequestView = memo(function StripMenuItemSeeRequestView({
    slot,
}: {
    slot: GanttStripDto;
}) {
    const setHighlightedSlotId = useSetUi('highlightedSlotId');
    const navigate = useNavigate();
    const handleClick = () => {
        setHighlightedSlotId(slot.id || null);
        // TODO: Better solution?
        // Timeout because RequestPage depends on highlightedSlotId being non-null for initial render
        setTimeout(() => {
            navigate(ROUTE_REQUESTS);
        }, 50);
    };
    return (
        <MenuItem onClick={handleClick}>
            <ListItemText>
                <FormattedMessage id="strip.actions.seeRequestView" />
            </ListItemText>
        </MenuItem>
    );
});
