import { FormikCheckbox } from '@components/form/Checkbox/FormikCheckbox';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { FormikSelect } from '@components/form/Select/FormikSelect';
import { Box, MenuItem, styled, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { ResourceType } from '@types';
import { useUiSet, useUiValue } from 'contexts/UiContext';
import React, { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { stopPropagation } from '../../../../utils/dom.utils';
import { Radio, RadioGroupField } from '@components/form/RadioGroup/RadioGroup';
import { useCurrentUserResources } from '../../../../contexts/AuthContext.selectors';

export interface ViewModeFormValues {
    sortByResource: ResourceType | undefined;
    selectedMode: 'projected' | 'current';
    showCancelledRequests: boolean;
    showTurnaroundResources: Partial<Record<ResourceType, boolean>>;
}

const StyledContainer = styled(Box)`
    padding: 40px;
    min-width: 460px;
`;

const StyledButtonContainer = styled(Box)`
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
`;

export const ViewModeForm = memo(function ViewModeForm({ onClose }: { onClose?: () => void }) {
    const sortByResource = useUiValue('sortByResource');
    const selectedMode = useUiValue('selectedMode');
    const showCancelledRequests = useUiValue('showCancelledRequests');
    const showTurnaroundResources = useUiValue('showTurnaroundResources');
    const setUi = useUiSet();

    const onSubmit = useCallback(
        (values: ViewModeFormValues) => {
            setUi(o => ({
                ...o,
                sortByResource: values.sortByResource || undefined,
                showCancelledRequests: values.showCancelledRequests,
                showTurnaroundResources: values.showTurnaroundResources,
                selectedMode: values.selectedMode,
            }));
            onClose?.();
        },
        [onClose, setUi],
    );

    const clientResources = useCurrentUserResources();

    return (
        <Formik
            initialValues={{
                sortByResource,
                showCancelledRequests,
                showTurnaroundResources,
                selectedMode: selectedMode || 'projected',
            }}
            onSubmit={onSubmit}
        >
            <Form>
                <StyledContainer onClick={stopPropagation}>
                    <RadioGroupField name={'selectedMode'}>
                        <Radio value="projected">
                            <div>
                                <FormattedMessage id="viewMode.popin.projectedMode" />
                                <div>
                                    <Typography variant="caption">
                                        <FormattedMessage id="viewMode.popin.projectedMode.description" />
                                    </Typography>
                                </div>
                            </div>
                        </Radio>
                        <Radio value="current">
                            <div>
                                <FormattedMessage id="viewMode.popin.currentMode" />
                                <div>
                                    <Typography variant="caption">
                                        <FormattedMessage id="viewMode.popin.currentMode.description" />
                                    </Typography>
                                </div>
                            </div>
                        </Radio>
                    </RadioGroupField>
                    <FormikCheckbox
                        name={'showCancelledRequests'}
                        label={<FormattedMessage id="viewMode.popin.showCancelledRequests" />}
                    />

                    {clientResources?.map(({ type }) => (
                        <FormikCheckbox
                            key={type}
                            name={`showTurnaroundResources.${type}`}
                            label={<FormattedMessage id={`viewMode.popin.showTurnaround.${type}`} />}
                        />
                    ))}

                    <FormikSelect
                        name="sortByResource"
                        label={<FormattedMessage id="viewMode.popin.sortByResource" />}
                        fullWidth
                        FormControlProps={{ sx: { marginTop: 2 } }}
                        disabled={!clientResources?.length}
                    >
                        <MenuItem value="">
                            <em>-</em>
                        </MenuItem>
                        {clientResources?.map(({ type }) => (
                            <MenuItem key={type} value={type}>
                                <FormattedMessage id={`ResourceType.${type}`} />
                            </MenuItem>
                        ))}
                    </FormikSelect>

                    <StyledButtonContainer>
                        <Box marginRight="5px">
                            <RoundButton size="medium" variant="outlined" onClick={onClose}>
                                <FormattedMessage id="viewMode.popin.cancel" />
                            </RoundButton>
                        </Box>
                        <Box marginLeft="5px">
                            <RoundButton size="medium" variant="contained" type={'submit'}>
                                <FormattedMessage id="viewMode.popin.applyFilters" />
                            </RoundButton>
                        </Box>
                    </StyledButtonContainer>
                </StyledContainer>
            </Form>
        </Formik>
    );
});
