import * as React from 'react';
import { memo } from 'react';
import { Field, FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import { DatePicker } from '@components/form/DatePicker/DatePicker';

export const FormikDatePicker = memo(function FormikDatePicker({
    name,
    ...props
}: { name: string } & Partial<Omit<React.ComponentProps<typeof DatePicker>, 'onChange' | 'value'>>) {
    return (
        <Field name={name}>
            {({ field: { value }, form: { errors, setFieldValue, values } }: FieldProps<Date>) => (
                <DatePicker
                    {...props}
                    value={value}
                    error={!!errors[name]}
                    helperText={
                        typeof errors[name] === 'string' && (
                            <FormattedMessage id={errors[name] as string} defaultMessage={errors[name] as string} />
                        )
                    }
                    onChange={date => {
                        const newDate = new Date(
                            `${format(date?.getTime() || new Date(), 'MM/dd/yyyy')} ${format(
                                name === 'begin' ? values.sibt || new Date() : values.sobt || new Date(),
                                'HH:mm',
                            )}:00`,
                        );
                        setFieldValue(name, newDate.getTime());
                        if (name === 'begin') {
                            if (values.sibt) {
                                setFieldValue('sibt', newDate.getTime());
                            }
                            if (!values.punctual && values.sobt) {
                                const newSobt = new Date(
                                    `${format(date?.getTime() || new Date(), 'MM/dd/yyyy')} ${format(
                                        values.sobt,
                                        'HH:mm',
                                    )}:00`,
                                );
                                setFieldValue('sobt', newSobt.getTime());
                            }
                        }
                        if (name === 'end' && values.punctual && values.sobt) {
                            setFieldValue('sobt', newDate.getTime());
                        }
                    }}
                />
            )}
        </Field>
    );
});
