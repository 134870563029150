import { useNavigate } from 'react-router-dom';
import { ROUTE_SCHEDULE } from '../routes';
import { format as originalFormat } from 'date-fns';
import { ganttDayPathFormat } from '@components/Schedule/Gantt/gantt.constants';
import { useCallback } from 'react';

export const useGoToSchedule = () => {
    const navigate = useNavigate();
    return useCallback(
        (date: Date) => {
            navigate(ROUTE_SCHEDULE.replace(':date', originalFormat(date, ganttDayPathFormat)));
        },
        [navigate],
    );
};
