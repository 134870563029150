import React, { memo, useCallback, useMemo, useState } from 'react';
import {
    FlightTurnaroundDto,
    ImpactStatus,
    ResourceDiscrepancyAlertDto,
    ResourceOrigin,
    ResourceType,
    TurnaroundResourcesDto,
} from '@types';
import { ONE_HOUR } from '../../../config/constants';
import { ganttFlightRowHeight, ganttFlightRowMargin, ganttPxPerMs } from './gantt.constants';
import { Box, styled } from '@mui/material';
import { FlightTurnaroundInitial } from '@components/Schedule/FlightTurnaround/FlightTurnaroundInitial';
import { FlightTurnaround } from '@components/Schedule/FlightTurnaround/FlightTurnaround';
import { FlightTurnaroundAirport } from '@components/Schedule/FlightTurnaround/FlightTurnaroundAirport';
import { FlightTurnaroundInnerContent } from '@components/Schedule/FlightTurnaround/FlightTurnaroundInnerContent';
import {
    FlightTurnaroundOuterContentArrival,
    FlightTurnaroundOuterContentDeparture,
} from '@components/Schedule/FlightTurnaround/FlightTurnaroundOuterContent';
import { FlightTurnaroundResources } from '@components/Schedule/FlightTurnaround/FlightTurnaroundResources';
import { bounded, getFlightTimes, getResourceUpdateImpact } from '@components/Schedule/Gantt/gantt.utils';
import { DragTooltip, useFlightDrag } from '@components/Schedule/Gantt/useFlightDrag';
import { FlightTurnaroundControlledIcon } from '@components/Schedule/FlightTurnaround/FlightTurnaroundControlledIcon';
import { useContext } from 'use-context-selector';
import { GanttContext } from '@components/Schedule/Gantt/GanttContext';
import { ResourcesProposalImpact } from '../../../backend/types.custom';
import { FlightTurnaroundRejectResourceProposal } from '@components/Schedule/FlightTurnaround/FlightTurnaroundRejectResourceProposal';

const transitionDuration = '300ms';

export const GanttFlightBox = styled(Box)<{ isDraggable: boolean; isDragging: boolean }>`
    position: relative;
    align-items: center;
    height: ${ganttFlightRowHeight}px;
    flex-shrink: 0;
    margin-top: ${ganttFlightRowMargin}px;
    user-select: none;
    transition: filter ${transitionDuration} ease-out, transform ${transitionDuration} ease-out;
    ${({ isDragging }) => (isDragging ? 'pointer-events: none;' : '')}
    filter: ${({ isDragging }) =>
        isDragging ? 'drop-shadow(0px 8px 5px #00000060)' : 'drop-shadow(0px 0px 0px #00000000)'};
    transform: ${({ isDragging }) => (isDragging ? 'translateY(-3px)' : 'translateY(0)')};

    ${({ isDraggable, isDragging }) =>
        isDraggable && !isDragging
            ? `
    &:hover {
        filter: drop-shadow(0px 2px 3px #00000040);
    }`
            : ''}

    &:last-of-type {
        margin-bottom: ${ganttFlightRowMargin}px;
    }
`;

const StyledResourceProposalBox = styled(Box)<{ isHovering: boolean }>`
    opacity: ${({ isHovering }) => (isHovering ? '50%' : '100%')};
`;

export const GanttFlightRow = memo(function GanttFlightRow({
    flight,
    resourcesProposal,
    resourceDiscrepancyAlerts,
    sliceOrigin,
    isFlightSelected,
    onClick,
    onDragEnd,
    onRejectResourceProposal,
}: {
    flight: FlightTurnaroundDto;
    resourcesProposal?: {
        resourceType: ResourceType;
        resources: TurnaroundResourcesDto;
    };
    resourceDiscrepancyAlerts?: ResourceDiscrepancyAlertDto[];
    // show only one half of the flights when filtering by resource and the arrival resource is not the same as the departure
    sliceOrigin?: 'arrival' | 'departure' | null;
    isFlightSelected?: boolean;
    onClick?: (flight: FlightTurnaroundDto | null) => void;
    onDragEnd?: (flight: FlightTurnaroundDto | null) => void;
    onRejectResourceProposal?: (
        id: string,
        proposalImpactArrival?: ResourcesProposalImpact,
        proposalImpactDeparture?: ResourcesProposalImpact,
    ) => void;
}) {
    const { from, to } = useContext(GanttContext);

    const { start, end } = getFlightTimes(flight);

    // Used to show reject button if !== undefined
    const [rejectProposalOrigin, setRejectProposalOrigin] = useState<ResourceOrigin | undefined>(undefined);

    // Bound element to gantt size, even if the flight lasts longer the than gantt viewport duration
    const boundedStartTime = useMemo(() => bounded(start, from - ONE_HOUR, to + ONE_HOUR), [from, start, to]);
    const boundedEndTime = useMemo(() => bounded(end, from - ONE_HOUR, to + ONE_HOUR), [end, from, to]);

    // margin-left from start of gantt (usually midnight) to the start of the flight
    const left = (boundedStartTime - from) * ganttPxPerMs;
    // element width in pixels
    const width = (boundedEndTime - boundedStartTime) * ganttPxPerMs;

    const isDraggable = useMemo(() => !!(isFlightSelected && onDragEnd), [isFlightSelected, onDragEnd]);
    const { isDragging, dragLeft, dragProps, tooltipProps } = useFlightDrag({
        enabled: isDraggable,
        onUpdateFlight: onDragEnd,
        flight,
        from,
        to,
    });

    const turnaroundDiscrepancyAlerts = useMemo(
        () => resourceDiscrepancyAlerts?.filter(alert => alert.turnaroundId === flight.id),
        [resourceDiscrepancyAlerts, flight.id],
    );

    const handleClick = useCallback(
        e => {
            if (isDragging || !onClick) {
                return;
            }
            e.stopPropagation();
            e.preventDefault();
            onClick(flight);
        },
        [flight, isDragging, onClick],
    );

    const { impactArrival, impactDeparture } = getResourceUpdateImpact(flight.resources, resourcesProposal);

    return (
        <GanttFlightBox
            isDraggable={isDraggable}
            isDragging={isDragging}
            marginLeft={`${dragLeft ?? left}px`}
            width={width}
            {...dragProps}
        >
            {(flight.initialSibt || flight.initialSobt) && (
                <Box
                    sx={{
                        position: 'absolute',
                        marginLeft: `${
                            (flight.sibt && flight.initialSibt ? flight.initialSibt - flight.sibt : 0) * ganttPxPerMs
                        }px`,
                        zIndex: 2,
                    }}
                >
                    <FlightTurnaroundInitial from={flight.initialSibt} to={flight.initialSobt} onClick={handleClick} />
                </Box>
            )}
            <DragTooltip {...tooltipProps}>
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: 3,
                    }}
                >
                    <FlightTurnaround
                        from={flight.sibt && Math.max(from - ONE_HOUR, flight.sibt)}
                        to={flight.sobt && Math.min(to + ONE_HOUR, flight.sobt)}
                        slice={sliceOrigin}
                        confirmed={flight.impactStatus === ImpactStatus.CONFIRMED}
                        impactStatus={flight.impactStatus}
                        onClick={handleClick}
                        arrival={
                            <FlightTurnaroundOuterContentArrival
                                selected={isFlightSelected}
                                onClick={handleClick}
                                onMouseLeave={() => setRejectProposalOrigin(undefined)}
                            >
                                {rejectProposalOrigin === ResourceOrigin.ARR ? (
                                    <FlightTurnaroundRejectResourceProposal
                                        onClick={() =>
                                            onRejectResourceProposal?.(flight.id, impactArrival, impactDeparture)
                                        }
                                        pr={'5px'}
                                    />
                                ) : (
                                    <>
                                        {flight.adep && flight.arrivalControlled && <FlightTurnaroundControlledIcon />}
                                        <FlightTurnaroundAirport
                                            airport={flight.adep}
                                            flightNumber={flight.arrivalFlightNumberFull}
                                        />
                                    </>
                                )}

                                {flight.sibt && (
                                    <>
                                        {resourcesProposal && impactArrival && (
                                            <StyledResourceProposalBox
                                                onMouseEnter={() => setRejectProposalOrigin(ResourceOrigin.ARR)}
                                                isHovering={!!rejectProposalOrigin}
                                            >
                                                <FlightTurnaroundResources
                                                    resources={resourcesProposal.resources}
                                                    resourcesProposalImpact={impactArrival}
                                                    origin={ResourceOrigin.ARR}
                                                />
                                            </StyledResourceProposalBox>
                                        )}
                                        <FlightTurnaroundResources
                                            resources={flight.resources}
                                            origin={ResourceOrigin.ARR}
                                            affectedResourceType={
                                                impactArrival ? resourcesProposal?.resourceType : undefined
                                            }
                                            discrepancyAlerts={turnaroundDiscrepancyAlerts}
                                        />
                                    </>
                                )}
                            </FlightTurnaroundOuterContentArrival>
                        }
                        departure={
                            <FlightTurnaroundOuterContentDeparture
                                selected={isFlightSelected}
                                onClick={handleClick}
                                onMouseLeave={() => setRejectProposalOrigin(undefined)}
                            >
                                {flight.sobt && (
                                    <>
                                        <FlightTurnaroundResources
                                            resources={flight.resources}
                                            origin={ResourceOrigin.DEP}
                                            affectedResourceType={
                                                impactDeparture ? resourcesProposal?.resourceType : undefined
                                            }
                                            discrepancyAlerts={turnaroundDiscrepancyAlerts}
                                        />
                                        {resourcesProposal && impactDeparture && (
                                            <StyledResourceProposalBox
                                                onMouseEnter={() => setRejectProposalOrigin(ResourceOrigin.DEP)}
                                                isHovering={!!rejectProposalOrigin}
                                            >
                                                <FlightTurnaroundResources
                                                    resources={resourcesProposal.resources}
                                                    resourcesProposalImpact={impactDeparture}
                                                    origin={ResourceOrigin.DEP}
                                                />
                                            </StyledResourceProposalBox>
                                        )}
                                    </>
                                )}

                                {rejectProposalOrigin === ResourceOrigin.DEP ? (
                                    <FlightTurnaroundRejectResourceProposal
                                        onClick={() =>
                                            onRejectResourceProposal?.(flight.id, impactArrival, impactDeparture)
                                        }
                                        pl={'5px'}
                                    />
                                ) : (
                                    <>
                                        <FlightTurnaroundAirport
                                            airport={flight.ades}
                                            flightNumber={flight.departureFlightNumberFull}
                                        />
                                        {flight.ades && flight.departureControlled && (
                                            <FlightTurnaroundControlledIcon />
                                        )}
                                    </>
                                )}
                            </FlightTurnaroundOuterContentDeparture>
                        }
                    >
                        <FlightTurnaroundInnerContent
                            acType={flight.acType}
                            airline={flight.airline}
                            hasImpact={
                                flight.impactStatus !== ImpactStatus.CONFIRMED &&
                                (flight.impactStatus === ImpactStatus.NEW ||
                                    flight.impactStatus === ImpactStatus.UPDATE)
                            }
                        />
                    </FlightTurnaround>
                </Box>
            </DragTooltip>
        </GanttFlightBox>
    );
});
