import React, { memo, useCallback } from 'react';
import { GanttStripDto, ModificationDemandDto } from '@types';
import { CircularProgress, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { QueryClient, useQueryClient } from 'react-query';
import {
    KeyGetGetAllSlotRequests,
    KeyGetGetDayGanttTurnarounds,
    KeyGetGetSlotStrip,
    PostConfirmSlotOccurrencesResponse,
    useMutationPostConfirmSlotOccurrences,
} from '@models';
import { usePopoverValue } from '@components/navigation/PopoverMenu/PopoverMenu';

const onMutateRequests =
    (queryClient: QueryClient) =>
    ({ data }: PostConfirmSlotOccurrencesResponse) => {
        if (!data || !data.updatedSlotId) {
            return;
        }

        queryClient.invalidateQueries(KeyGetGetDayGanttTurnarounds);
        queryClient.invalidateQueries(KeyGetGetSlotStrip);
        queryClient.invalidateQueries(KeyGetGetAllSlotRequests);
    };

export const StripMenuItemConfirmAllOccurrences = memo(function StripMenuItemConfirmAllOccurrences({
    slot,
}: {
    slot: GanttStripDto | ModificationDemandDto;
}) {
    const close = usePopoverValue('close');
    const queryClient = useQueryClient();

    // confirm all slots
    const { mutateAsync: confirmAllSlots, isLoading } = useMutationPostConfirmSlotOccurrences({
        onSuccess: onMutateRequests(queryClient),
    });
    const onClick = useCallback(() => {
        if (!slot?.id) {
            return;
        }

        confirmAllSlots({
            id: slot.id,
        }).then(() => {
            close();
        });
    }, [close, confirmAllSlots, slot.id]);

    return (
        <MenuItem onClick={onClick}>
            <ListItemText>
                <FormattedMessage
                    id="strip.actions.confirmAllOccurrences"
                    values={{
                        nbPending: slot.nbPending,
                    }}
                />
            </ListItemText>
            {isLoading && (
                <ListItemIcon sx={{ marginLeft: 1 }}>
                    <CircularProgress size={25} color={'secondary'} />
                </ListItemIcon>
            )}
        </MenuItem>
    );
});
