import { SlotRequestDto } from '@models';
import { Box, styled, Typography } from '@mui/material';
import React, { memo, useEffect, useRef } from 'react';
import { Request } from '@components/Requests/Request';
import { FormattedMessage } from 'react-intl';
import { useUiValue } from '../../contexts/UiContext';

export const Requests = memo(function Requests({
    requests,
    scrollToSlotId,
    page,
}: {
    requests?: SlotRequestDto[];
    scrollToSlotId?: string | null;
    page: number;
}) {
    const searchValueInUiContext = useUiValue('search');
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [page, scrollRef.current, searchValueInUiContext]);

    return (
        <RequestsContainer>
            <div ref={scrollRef}></div>
            {!requests || requests.length === 0 ? (
                <StyledTypography variant={'h5'}>
                    <FormattedMessage id={'requests.body.noRequests'} />
                </StyledTypography>
            ) : (
                requests.map(request =>
                    request.demands.length ? (
                        <Request key={request.id} request={request} scrollToSlotId={scrollToSlotId} />
                    ) : null,
                )
            )}
        </RequestsContainer>
    );
});

const RequestsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 0 16px 50px 16px;
`;

const StyledTypography = styled(Typography)`
    color: white;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
`;
